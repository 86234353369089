import {AssetsSummary} from "../../models/Assets";
import {hasSomeInEstateOwnership, hasSomeOutOfEstateOwnership} from "../../Ownership/helpers";
import {formatCurrency} from "../../../utils/format";
import {getCurrentOwnerByOwnerMemberId, getOwnerName} from "./ownershipUtils";
import {getBeneficiariesList} from "./lifeInsuranceUtils";
import {StandaloneAccountOrLegalAgreement} from "../InEstate/AccountTableSection";
import {StandaloneAccount} from "../../models/StandaloneAccount";
import {HeldAwayAccount, HeldAwayAccountSummary, LegalAgreement} from "../../models/InvestmentProgram";
import {PersonalAsset} from "../../models/PersonalAsset";
import {GeneralInflow} from "../../models/GeneralInflow";
import {SocialSecurity} from "../../models/SocialSecurity";
import {LifeInsurance} from "../../models/LifeInsurance";
import {PersonalLiabilitySummary} from "../../models/PersonalLiability";
import {calculateInEstateFormValueForLifeInsurance} from "./AssetSummaryCalculator";
import {EquityCompensationFormData} from "../../models/EquityCompensation";
import {PartiallyOwnedLegalAgreement} from "../../models/PartiallyOwnedInvestmentAccount";

export function hasAssets(clientAssets: AssetsSummary) {
    return clientAssets.accounts.data.length > 0 ||
        (clientAssets.investmentProgram && (clientAssets.investmentProgram.legalAgreements.length > 0 ||
            clientAssets.investmentProgram.heldAwayAccounts.length > 0)) ||
        clientAssets.partiallyOwnedLegalAgreements.length > 0 ||
        clientAssets.personalAssets.data.length > 0 ||
        clientAssets.generalInflows.data.length > 0 ||
        clientAssets.socialSecurities.data.length > 0 ||
        clientAssets.lifeInsurances.data.length > 0 ||
        clientAssets.equityCompensations.data.length > 0;
}

export function hasInEstateAssets(clientAssets: AssetsSummary) {

    const inEstateStandaloneAccounts = clientAssets.accounts.data.filter(asset => hasSomeInEstateOwnership(asset.memberOwnerships));
    const inEstatePartiallyOwnedInvestmentAccounts = clientAssets.partiallyOwnedLegalAgreements
        && clientAssets.partiallyOwnedLegalAgreements.filter(asset => hasSomeInEstateOwnership(asset.memberOwnerships));
    const inEstatePersonalAssets = clientAssets.personalAssets.data.filter(asset => hasSomeInEstateOwnership(asset.memberOwnerships));
    const inEstateLifeInsurances = clientAssets.lifeInsurances.data.filter(asset => hasSomeInEstateOwnership(asset.memberOwnerships));
    const hasAnyinvestmentProgram = (clientAssets.investmentProgram && (clientAssets.investmentProgram.legalAgreements.length > 0 ||
        clientAssets.investmentProgram.heldAwayAccounts.length > 0));

    return inEstateStandaloneAccounts.length > 0 ||
        inEstatePartiallyOwnedInvestmentAccounts.length > 0 ||
        inEstatePersonalAssets.length > 0 ||
        inEstateLifeInsurances.length > 0 ||
        hasAnyinvestmentProgram ||
        clientAssets.generalInflows.data.length > 0 ||
        clientAssets.socialSecurities.data.length > 0;
}

export function hasOutOfEstateAssets(clientAssets: AssetsSummary) {
    const outOfEstateStandaloneAccounts = clientAssets.accounts.data
        .filter(asset => hasSomeOutOfEstateOwnership(asset.memberOwnerships));

    const outOfEstateLegalAgreements = clientAssets.investmentProgram
        ? clientAssets.investmentProgram.legalAgreements
            .filter(asset => hasSomeOutOfEstateOwnership(asset.memberOwnerships)) : [];

    const outOfEstateHeldAwayAccounts = clientAssets.investmentProgram
        ? clientAssets.investmentProgram.heldAwayAccounts
            .filter(asset => hasSomeOutOfEstateOwnership(asset.memberOwnerships)) : [];

    const outOfEstatePartiallyOwnedInvestmentAccounts = clientAssets.partiallyOwnedLegalAgreements
        && clientAssets.partiallyOwnedLegalAgreements.filter(
            asset => hasSomeOutOfEstateOwnership(asset.memberOwnerships));

    const outOfEstatePersonalAssets = clientAssets.personalAssets.data
        .filter(asset => hasSomeOutOfEstateOwnership(asset.memberOwnerships));

    const outOfEstateLifeInsurances = clientAssets.lifeInsurances.data
        .filter(asset => hasSomeOutOfEstateOwnership(asset.memberOwnerships));

    return outOfEstateStandaloneAccounts.length > 0 ||
        outOfEstatePartiallyOwnedInvestmentAccounts.length > 0 ||
        outOfEstateLegalAgreements.length > 0 ||
        outOfEstateHeldAwayAccounts.length > 0 ||
        outOfEstatePersonalAssets.length > 0 ||
        outOfEstateLifeInsurances.length > 0;
}

export function hasLiabilities(clientAssets: AssetsSummary) {
    return clientAssets.personalLiabilities.length > 0;
}

export function hasInEstateLiabilities(clientAssets: AssetsSummary) {
    const inEstatePersonalLiabilities = clientAssets.personalLiabilities
        .filter(liability => hasSomeInEstateOwnership(liability.memberOwnerships));

    return inEstatePersonalLiabilities.length > 0;
}

export function hasOutOfEstateLiabilities(clientAssets: AssetsSummary) {
    const outOfEstatePersonalLiabilities = clientAssets.personalLiabilities
        .filter(liability => hasSomeOutOfEstateOwnership(liability.memberOwnerships));

    return outOfEstatePersonalLiabilities.length > 0;
}

export function assetListData(assetsData: AssetsSummary) {

    function getFormattedTotalPresentValue(totalPresentValue: number) {
        return totalPresentValue !== undefined ? formatCurrency(totalPresentValue) : '';
    }

    function getFormattedPresentValueForCurrentNetWorth(presentValue: number) {
        return presentValue !== 0 ? formatCurrency(presentValue) : '--';
    }

    function calculateTotalPresentValueForFutureInflows(allAssets?: AssetsSummary) {
        if (typeof allAssets !== 'undefined') {
            return allAssets.generalInflows.totalPresentValue + allAssets.socialSecurities.totalPresentValue;
        }
        return assetsData.generalInflows.totalPresentValue + assetsData.socialSecurities.totalPresentValue;
    }

    const hasFutureInflowAssets = assetsData.generalInflows?.data?.length > 0 || assetsData.socialSecurities?.data?.length > 0;

    const inEstateStandaloneAccounts = assetsData.accounts.data.filter(asset => hasSomeInEstateOwnership(asset.memberOwnerships));

    const inEstatePartiallyOwnedInvestmentAccounts = assetsData.partiallyOwnedLegalAgreements.filter(
        asset => hasSomeInEstateOwnership(asset.memberOwnerships));

    const hasInEstatePartiallyOwnedInvestmentAccounts = inEstatePartiallyOwnedInvestmentAccounts.length > 0;

    const hasInEstateAccount = inEstateStandaloneAccounts.length > 0;

    const inEstatePersonalAssets = assetsData.personalAssets.data.filter(asset => hasSomeInEstateOwnership(asset.memberOwnerships));

    const inEstateLifeInsurances = assetsData.lifeInsurances.data.filter(asset => hasSomeInEstateOwnership(asset.memberOwnerships));

    const inEstateLegalAgreements = assetsData.investmentProgram?.legalAgreements.filter(asset =>
        hasSomeInEstateOwnership(asset.memberOwnerships)) ?? [];

    const hasInEstateInvestmentProgram = inEstateLegalAgreements.length > 0;

    const inEstateHeldAwayAccounts = assetsData.investmentProgram?.heldAwayAccounts.filter(asset =>
        hasSomeInEstateOwnership(asset?.memberOwnerships)) ?? [];

    const hasInEstateInvestmentProgramHeldAA = inEstateHeldAwayAccounts.length > 0;

    const hasInEstateEquityCompensations = assetsData.equityCompensations.data.length > 0;
    const inEstateEquityCompensations = assetsData.equityCompensations.data;

    const inEstatePersonalLiabilities = assetsData.personalLiabilities
        .filter(liability => hasSomeInEstateOwnership(liability.memberOwnerships));
    const hasInEstatePersonalLiability = inEstatePersonalLiabilities.length > 0;

    return {
        getFormattedTotalPresentValue,
        getFormattedPresentValueForCurrentNetWorth,
        getCurrentOwnerByOwnerMemberId,
        getOwnerName,
        calculateTotalPresentValueForFutureInflows,
        getBeneficiariesList,
        hasFutureInflowAssets,
        hasInEstateAccount,
        inEstatePartiallyOwnedInvestmentAccounts,
        hasInEstateInvestmentProgram,
        hasInEstatePartiallyOwnedInvestmentAccounts,
        hasInEstatePersonalLiability,
        hasInEstateEquityCompensations,
        inEstateStandaloneAccounts,
        inEstatePersonalAssets,
        inEstateLifeInsurances,
        inEstateEquityCompensations,
        inEstateLegalAgreements,
        inEstateHeldAwayAccounts,
        hasInEstateInvestmentProgramHeldAA,
        inEstatePersonalLiabilities
    };
}

export function sortByOrdinalAndInEstateOrMarketValue(assetList: StandaloneAccountOrLegalAgreement[]) {
    const sortedAssetList = [...assetList]
    sortedAssetList.sort((a, b) => {
        const valueForA = returnMarketValueByAccountType(a);
        const valueForB = returnMarketValueByAccountType(b);

        if (a.ordinal == b.ordinal) return valueForA > valueForB ? -1 : 1

        return a.ordinal > b.ordinal ? -1 : 1;
    });

    const accountsLength = sortedAssetList.length;
    return sortedAssetList.map((account, index) => {
        return {...account, ordinal: accountsLength - 1 - index}
    });
}

const returnMarketValueByAccountType = (account: StandaloneAccountOrLegalAgreement): number => {
    if (account.hasOwnProperty('inEstateValue')) {
        return (account as StandaloneAccount).inEstateValue;
    } else if (account.hasOwnProperty('domesticTrustAccounts')) {
        return (account as LegalAgreement).marketValue;
    } else if (account.hasOwnProperty('baseMarketValue')) {
        return (account as HeldAwayAccountSummary).baseMarketValue;
    } else {
        return (account as PartiallyOwnedLegalAgreement).marketEstateValue.inEstateValue;
    }
}

export function sortByOrdinalAndPresentValue(assetList: (PersonalAsset | GeneralInflow | SocialSecurity)[]) {
    const sortedAssetList = [...assetList]
    sortedAssetList.sort((a, b) => {
        if (a.ordinal == b.ordinal) return a.presentValue > b.presentValue ? -1 : 1
        return a.ordinal > b.ordinal ? -1 : 1;
    });

    const length = sortedAssetList.length;
    return sortedAssetList.map((asset, index) => {
        return {...asset, ordinal: length - 1 - index}
    });
}

export function sortByOrdinalAndAfterTaxVestedValue(assetList: EquityCompensationFormData []) {
    const sortedAssetList = [...assetList]
    sortedAssetList.sort((a, b) => {
        const value1 = a.afterTaxVestedValue ? a.afterTaxVestedValue : 0;
        const value2 = b.afterTaxVestedValue ? b.afterTaxVestedValue : 0;
        return value1 > value2 ? -1 : 1
    });

    const length = sortedAssetList.length;
    return sortedAssetList.map((asset, index) => {
        return {...asset, ordinal: length - 1 - index}
    });
}

export function sortByOrdinalAndCashValue(assetList: LifeInsurance[]) {
    const sortedAssetList = [...assetList]
    sortedAssetList.sort((a, b) => {
        if (a.ordinal == b.ordinal) return calculateInEstateFormValueForLifeInsurance(a.cashValue, a.memberOwnerships) > calculateInEstateFormValueForLifeInsurance(b.cashValue, b.memberOwnerships) ? -1 : 1
        return a.ordinal > b.ordinal ? -1 : 1;
    });

    const length = sortedAssetList.length;
    return sortedAssetList.map((asset, index) => {
        return {...asset, ordinal: length - 1 - index}
    });
}

export function sortByOrdinalAndLoanBalance(assetList: PersonalLiabilitySummary[]) {
    const sortedAssetList = [...assetList]
    sortedAssetList.sort((a, b) => {
        if (a.ordinal == b.ordinal) return a.loanBalance > b.loanBalance ? -1 : 1
        return a.ordinal > b.ordinal ? -1 : 1;
    });

    const length = sortedAssetList.length;
    return sortedAssetList.map((asset, index) => {
        return {...asset, ordinal: length - 1 - index}
    });
}

export function getAllAccounts(assetsData: AssetsSummary) {

    let legalAgreements: LegalAgreement[] = [];
    let partiallyOwnedLegalAgreements: PartiallyOwnedLegalAgreement[] = [];
    let heldAwayAccounts: HeldAwayAccountSummary[] = [];

    if (assetsData.investmentProgram && assetsData.investmentProgram?.legalAgreements) {
        legalAgreements = assetsData.investmentProgram?.legalAgreements
    }
    if (assetsData.investmentProgram && assetsData.investmentProgram?.heldAwayAccounts) {
        heldAwayAccounts = assetsData.investmentProgram?.heldAwayAccounts
    }
    if (assetsData.partiallyOwnedLegalAgreements) {
        partiallyOwnedLegalAgreements = assetsData.partiallyOwnedLegalAgreements
    }
    const allAccounts = sortByOrdinalAndInEstateOrMarketValue([
        ...legalAgreements,
        ...heldAwayAccounts,
        ...partiallyOwnedLegalAgreements,
        ...assetListData(assetsData).inEstateStandaloneAccounts
    ]);
    return allAccounts;
}

