import React, {useEffect, useState} from "react";
import {EventHandler} from "../../constants/common";
import {Dropdown, DropdownItem} from "../Dropdown";
import {RedAsterisk} from "../Forms";

type StateOfResidencyInputProps = {
    listOfStates: string[]
    className?: string
    onSelected: EventHandler,
    panelWidth?: string,
    value?: string,
    disabled?: boolean,
    error?: string
    required?: boolean
}

export const StateOfResidencyInput: React.FC<StateOfResidencyInputProps> = ({
                                                                                listOfStates,
                                                                                className = "",
                                                                                onSelected,
                                                                                panelWidth = "300px",
                                                                                value = "",
                                                                                disabled = false,
                                                                                error = undefined,
                                                                                required = false
                                                                            }) => {
    const [filteredStates, setFilteredStates] = useState(listOfStates);
    const [filteredStateOfResidency, updateFilteredStateOfResidency] = useState("");

    useEffect(() => {
        if (filteredStateOfResidency !== "") {
            setFilteredStates(filteringStates(filteredStateOfResidency, listOfStates));
        } else {
            setFilteredStates(listOfStates);
        }
    }, [filteredStateOfResidency, listOfStates]);


    const filteringStates = (filteredValue: string, states: string[]): string[] => {
        const wholeStates = states.filter(state => {
            return state.toLowerCase().startsWith(filteredValue?.toLowerCase());
        });
        return wholeStates;
    }

    return (
        <div className={`h4 parent ${className}`}>
            {required ?
                <label className="h5 label stateOfResidencyLabel" data-testid="stateOfResidencyLabel" htmlFor="state_of_residency">
                    State of Residency<RedAsterisk/>
                </label>
            :
                <label className="h5 label stateOfResidencyLabel" data-testid="stateOfResidencyLabel" htmlFor="state_of_residency">
                    State of Residency
                </label>
            }
            <Dropdown
                className="state-of-residency-field"
                name="stateOfResidency"
                id="stateOfResidencyInput"
                aria-label="stateOfResidency"
                aria-labelledby="stateOfResidency-label"
                alignRight={false}
                buttonIcon="right"
                buttonKind="secondary"
                defaultPageSize={15}
                dropdownKind="select"
                dynamicDropAlign={true}
                dynamicDropDirection={true}
                iconNameClose="arrow_drop_up"
                iconNameOpen="arrow_drop_down"
                labelWidth={panelWidth}
                nativeOnMobile={false}
                panelHeight={232}
                panelWidth={panelWidth}
                searchable
                disabled={disabled}
                defaultText="Select..."
                onChange={(e: any) => {
                    // This is one condition when dropdown item is selected.
                    if (!e.target) {
                        updateFilteredStateOfResidency("");
                        onSelected(e);
                    } else {
                        //when dropdown is filtered.
                        updateFilteredStateOfResidency(e.target.value);
                    }
                }}
                size="medium"
                virtualScroll={false}
                value={value}
                error={error}
            >
                {filteredStates.map(state => (
                    <DropdownItem key={state} itemText={state} value={state}/>
                ))}
            </Dropdown>
        </div>
    )
}
