import {
    EstimatedImpact,
    GrowthRates,
    StateEstateTransferRates,
    StateOfResidencyStrategy,
    StrategyTimeFrame,
    WealthPOSummaryResponse
} from "./WealthPOTypes";
import {get, post, put, remove} from "../core/api"

const extractBody = (res: Response) => res.json();
const isSuccess = (res: Response) => res.status === 200 || res.status === 201;

const getStateEstateTransferRatesReferenceData = (profileId: string): Promise<StateEstateTransferRates> => {
    return get(`/holistic-advice/profiles/${profileId}/wealthpo/strategy/referenceData/stateEstateTransferRates`)
        .then(extractBody);
}

const getGrowthRatesReferenceData = (profileId: string): Promise<GrowthRates> => {
    return get(`/holistic-advice/profiles/${profileId}/wealthpo/strategy/referenceData/growthRates`)
        .then(extractBody);
}

const getEstimatedImpactForSOR = (profileId: string, selectedStateOfResidency: string, abortSignal?: AbortSignal): Promise<EstimatedImpact> => {
    return get(
        `/holistic-advice/profiles/${profileId}/wealthpo/strategy/stateOfResidency/estimatedImpact/${selectedStateOfResidency}`,
        {signal: abortSignal}
    ).then(extractBody);
}

const getEstimatedImpactForAnnualGifting = (profileId: string, requestBody: StrategyTimeFrame, abortSignal?: AbortSignal)
    : Promise<EstimatedImpact> => {
    return post(`/holistic-advice/profiles/${profileId}/wealthpo/strategy/annualGifting/estimatedImpact`,
        requestBody,
        {signal: abortSignal}
    ).then(extractBody);
}

const saveStateOfResidencyStrategy = (profileId: string, requestBody: StateOfResidencyStrategy) => {
    return post(`/holistic-advice/profiles/${profileId}/wealthpo/strategy/stateOfResidency`, requestBody)
        .then(isSuccess);
}

const editStateOfResidencyStrategy = (profileId: string, requestBody: StateOfResidencyStrategy) => {
    return put(`/holistic-advice/profiles/${profileId}/wealthpo/strategy/stateOfResidency`, requestBody)
        .then(isSuccess);
}

const deleteStateOfResidencyStrategy = (profileId: string, strategyId: string) => {
    return remove(`/holistic-advice/profiles/${profileId}/wealthpo/strategy/delete/${strategyId}`)
        .then(isSuccess)
}

const getWealthPOSummary = (profileId: string): Promise<WealthPOSummaryResponse> => {
    return get(`/holistic-advice/profiles/${profileId}/wealthpo/strategy/summary`)
        .then(extractBody);
}

export const wealthPOApiClient = {
    getStateEstateTransferRatesReferenceData,
    getGrowthRatesReferenceData,
    getEstimatedImpactForSOR,
    getEstimatedImpactForAnnualGifting,
    saveStateOfResidencyStrategy,
    editStateOfResidencyStrategy,
    deleteStateOfResidencyStrategy,
    getWealthPOSummary
}


