import React from "react";

export function StrategyListHeader() {
    return (
        <div className={"strategy-list-header"}>
            <span className={"span-1"}>Name</span>
            <span className={"span-2"}>Strategy</span>
            <span className={"span-3 textalign-right"}>Impact to Amount to Beneficiaries</span>
            <span className={"span-4 textalign-right"}>Impact to  Est. Estate Tax</span>
        </div>
    );
}