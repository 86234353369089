import React, {useContext, useEffect, useState} from 'react'
import {Icon} from "../../../components";
import {useHistory, useParams} from "react-router-dom";
import {assetsApiClient} from "../../AssetsApiClient";
import {RouteWithAssetId} from "../../../routes/types";
import {EstateValue, HoldingAssetSubclassDetails, HoldingWriteModel} from "../../models/Holding";
import {StandaloneAccount} from "../../models/StandaloneAccount";
import {toDisplayDateFormat} from "../../../utils/dateUtils";
import {useAppSelector} from "../../../store/hooks";
import {selectProfile} from "../../../ClientManagement/ClientProfile/activeProfileSlice";
import HoldingsInfo from "./HoldingsInfo";
import {clientManagementApiClient} from "../../../ClientManagement/ClientManagementApiClient";
import DataEntryHeader from "../../../components/DataEntry/DataEntryHeader";
import LoadingIndicator from "../../../pages/LoadingIndicator";
import {InvestorGroupType} from "../../../ClientManagement/models/InvestorGroupType";
import {EditSubclassHolding} from "./EditSubclassHolding";
import {HoldingSummaryAccordion} from "./HoldingSummaryAccordion";
import {AssetClassifications} from "../../models/AssetClassifications";
import {getHoldingsWithDefaultPlaceholders, mapHoldingAssetSubclassDetailsToHoldingWriteModel} from "./holdingUtils";
import {HoldingsScrollableContainer} from './HoldingsScrollableContainer';
import AssetsViewContext from "../../common/AssetsViewContext";
import {HistoryBlockDiscardModal} from "../../../components/HistoryBlockDiscardModal/HistoryBlockModal";
import deepEquals from "fast-deep-equal";

const EditSubclasses = () => {
    const history = useHistory();
    const viewType = useContext(AssetsViewContext);
    const {assetId} = useParams<RouteWithAssetId>();
    const profile = useAppSelector(selectProfile);
    const [account, setAccount] = useState<StandaloneAccount>();
    const [investorGroup, setInvestorGroup] = useState<InvestorGroupType>();
    const [holdings, setHoldings] = useState<HoldingAssetSubclassDetails[]>([]);
    const [initialHoldings, setInitialHoldings] = useState<HoldingAssetSubclassDetails[]>([]);
    const [classifications, setClassifications] = useState<AssetClassifications>();
    const [showNavigationModal, setShowNavigationModal] = useState(true);

    useEffect(() => {
        Promise.all([
            assetsApiClient.getAccount(profile.id, assetId),
            assetsApiClient.getAssetClassifications(),
            clientManagementApiClient.getInvestorGroup(profile.id)
        ]).then(([standaloneAccount, assetClassificationResponse, investorGroupResponse]) => {
            setAccount(standaloneAccount);
            setHoldings(getHoldingsWithDefaultPlaceholders(standaloneAccount.holdings.allHoldings, assetClassificationResponse));
            setInitialHoldings(getHoldingsWithDefaultPlaceholders(standaloneAccount.holdings.allHoldings, assetClassificationResponse));
            setClassifications(assetClassificationResponse);
            setInvestorGroup(investorGroupResponse);
        }).catch(error => console.error('Could not fetch asset details', error.message));
    }, []);

    function updateEditableHoldings(value: number | null | EstateValue,
                                    index: number,
                                    valueType: 'taxCost' | 'marketValue' | 'investableValue' | 'marketEstateValue') {
        setHoldings([
            ...holdings.slice(0, index),
            {
                ...holdings[index],
                [valueType]: value,
            },
            ...holdings.slice(index + 1, holdings.length),
        ]);
    }

    const formChanged = () => {
        const updated = {
            ...holdings,
        };
        const initial = {
            ...initialHoldings
        }
        return !deepEquals(initial, updated);
    }

    const onSave = async () => {
        setShowNavigationModal(false)
        /* eslint-disable @typescript-eslint/no-unused-vars */
        const updatedHoldings: HoldingWriteModel[] = holdings
            .filter(holding => {
                return holding.taxCost !== null || holding.marketValue !== null || holding.investableValue !== null;
            })
            .map(holding => mapHoldingAssetSubclassDetailsToHoldingWriteModel(holding));

        await assetsApiClient.postHoldings(profile.id, assetId, updatedHoldings);
        history.push(`/Profile/${profile.id}/ClientProfile/${viewType}/StandaloneAccount/${assetId}/Holdings`);
    };

    const onDiscardClick = () => {
        history.goBack();
    };

    const subtitle = () => {
        return <span className='holdings-subtitle'>{`As of ${toDisplayDateFormat(account?.asOfDate)}`}</span>;
    }

    if (!account || !investorGroup || !classifications) {
        return <LoadingIndicator/>;
    }

    const primaryMember = investorGroup.primaryMember;
    const partnerMember = investorGroup.partnerMember;
    const inEstateOwnershipPercentage = account.memberOwnerships
        .filter(mo => mo.isInEstateMember)
        .reduce((previous, ownership) => {
            return previous + ownership.percentage;
        }, 0);

    return <div className="holdings-container holdings-container__edit-subclass">
        <DataEntryHeader
            title={`Edit Subclasses - ${(account?.name)}`}
            primaryButtonText="Save"
            secondaryButtonText='Discard Changes'
            onPrimaryButtonClick={onSave}
            onSecondaryButtonClick={onDiscardClick}
            SubtitleComponent={subtitle}
        />
        <div>
            <HoldingsInfo account={account} primaryMember={primaryMember} partnerMember={partnerMember}
                          allHoldings={holdings}
                          classifications={classifications}
            />
            <div className="holding-grid holding-grid-container">
                <div className="grid-display-contents" role="row">
                    <span className="display-flex align-items-center paddingleft-xl" role="cell">
                        <Icon name="chevron_double_right"/>
                        <span className="condensed-subtitle paddingleft-md">Asset Class / Subclass</span>
                    </span>
                    <span className="condensed-subtitle value-label textalign-right" role="cell">
                            Tax Cost
                    </span>
                    <span className="condensed-subtitle value-label textalign-right" role="cell">
                            Market Value
                    </span>
                    <span className="condensed-subtitle value-label textalign-right" role="cell">
                            Investable Value
                    </span>
                    <span className="empty-grid-column"/>
                </div>
                <div className="empty-grid-row"/>
                <HoldingsScrollableContainer>
                    <HoldingSummaryAccordion
                        riskAssetClasses={classifications?.riskAssetClasses}
                        riskControlAssetClasses={classifications?.riskControlAssetClasses}
                        selectedHoldings={holdings}
                        assetSubclassRowRenderer={(assetSubclass, holdingsWithIndex, subclassIndex) => {
                            return <EditSubclassHolding
                                assetSubclass={assetSubclass}
                                holdingsWithIndex={holdingsWithIndex}
                                subclassIndex={subclassIndex}
                                updateEditableHoldings={updateEditableHoldings}
                                inEstateOwnershipPercentage={inEstateOwnershipPercentage}/>
                        }}
                    />
                </HoldingsScrollableContainer>
            </div>
        </div>
        <HistoryBlockDiscardModal
            when={formChanged() && showNavigationModal}
        />
    </div>
};
export default EditSubclasses;
