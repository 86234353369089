import {GroupedTableData, GroupedTableItem} from "../components/Table/GroupedTable";
import {Asset} from "../Assets/models/Asset";
import {OwnershipCategory} from "../Assets/models/Ownership";
import {AssetsSummary} from "../Assets/models/Assets";
import {
    ASSET_TITLING_BENEFICIARY_DESIGNATION,
    ASSET_TITLING_JOINT_TENANCY,
    ASSET_TITLING_REVOCABLE_TRUST,
    ASSET_TITLING_WILL
} from "../constants/colors";
import {StandaloneAccount} from "../Assets/models/StandaloneAccount";
import {HeldAwayAccountSummary, LegalAgreement} from "../Assets/models/InvestmentProgram";
import {PersonalAsset} from "../Assets/models/PersonalAsset";
import {LifeInsurance} from "../Assets/models/LifeInsurance";
import {PersonalLiability} from "../Assets/models/PersonalLiability";
import {EquityCompensation, EquityCompensationFormData} from "../Assets/models/EquityCompensation";
import {PartiallyOwnedLegalAgreement} from "../Assets/models/PartiallyOwnedInvestmentAccount";

export type BarChartGroup = {
    'Joint Tenancy': number,
    'Beneficiary Designation': number,
    'Will': number,
    'Revocable Trust': number,

}
type AssetTitlingGroup = {
    tableData: GroupedTableData[],
    totalValue: number,
    hasData: boolean
};

type AssetTitlingAccordionProperties = {
    defaultFilter?: (asset: Asset) => boolean,
    accountFilter?: (account: StandaloneAccount) => boolean,
    personalAssetFilter?: (personalAsset: PersonalAsset) => boolean,
    lifeInsuranceFilter?: (lifeInsurance: LifeInsurance) => boolean,
    legalAgreementFilter?: (legalAgreement: LegalAgreement) => boolean,
    heldAwayAccountFilter?: (heldAwayAccount: HeldAwayAccountSummary) => boolean,
    partiallyOwnedLegalAgreementFilter?: (partiallyOwnedLegalAgreement: PartiallyOwnedLegalAgreement) => boolean,
    equityCompensationFilter?: (equityCompensation: EquityCompensation) => boolean,
    personalLiabilityFilter?: (personalLiability: PersonalLiability) => boolean,
    id: string,
    primaryText: string,
    accentColor: string,
    displayOrder: number
};

export type AssetTitlingAccordionItem = AssetTitlingGroup & AssetTitlingAccordionProperties;

type AssetTitlingAssets = {
    accounts: StandaloneAccount[],
    personalAssets: PersonalAsset[],
    lifeInsurances: LifeInsurance[],
    legalAgreements: LegalAgreement[],
    heldAwayAccounts: HeldAwayAccountSummary[],
    partiallyOwnedLegalAgreement: PartiallyOwnedLegalAgreement[],
    personalLiabilities: PersonalLiability[],
    equityCompensations: EquityCompensation[]
};

const INCLUDE_ALL = () => true;
const EXCLUDE_ALL = () => false;

function spiltRevTrustStandaloneAccounts(assets: AssetTitlingAssets) {
    const accountsWithRevTrust = assets.accounts.filter(account => account.memberOwnerships.some(ownership => ownership.isRevocableTrust));

    accountsWithRevTrust.forEach(account => {
        let first_account: StandaloneAccount;
        let second_account: StandaloneAccount;

        if (account.memberOwnerships.length > 1 && !(account.memberOwnerships[0].isRevocableTrust && account.memberOwnerships[1].isRevocableTrust)) {
            first_account = {
                ...account,
                memberOwnerships: [account.memberOwnerships[0]],
                marketValue: {
                    ...account.marketValue,
                    inEstateValue: account.marketValue.totalValue * (account.memberOwnerships[0].percentage / 100)
                }
            };
            second_account = {
                ...account,
                memberOwnerships: [account.memberOwnerships[1]],
                marketValue: {
                    ...account.marketValue,
                    inEstateValue: account.marketValue.totalValue * (account.memberOwnerships[1].percentage / 100)
                }
            };

            assets.accounts = assets.accounts.filter(acc => acc.id !== account.id)
            assets.accounts.push(first_account, second_account);
        }
    })
}

function splitRevTrustPersonalAssets(assets: AssetTitlingAssets) {
    const personalAssetsWithRecTrust = assets.personalAssets.filter(PA => PA.memberOwnerships.some(ownership => ownership.isRevocableTrust));
    personalAssetsWithRecTrust.forEach(asset => {
        let first_asset: PersonalAsset;
        let second_asset: PersonalAsset;

        if (asset.memberOwnerships.length > 1 && !(asset.memberOwnerships[0].isRevocableTrust && asset.memberOwnerships[1].isRevocableTrust)) {
            first_asset = {
                ...asset,
                memberOwnerships: [asset.memberOwnerships[0]],
                presentEstateValue: {
                    ...asset.presentEstateValue,
                    inEstateValue: asset.presentEstateValue.totalValue * (asset.memberOwnerships[0].percentage / 100)
                }
            };
            second_asset = {
                ...asset,
                memberOwnerships: [asset.memberOwnerships[1]],
                presentEstateValue: {
                    ...asset.presentEstateValue,
                    inEstateValue: asset.presentEstateValue.totalValue * (asset.memberOwnerships[1].percentage / 100)
                }
            };

            assets.personalAssets = assets.personalAssets.filter(acc => acc.id !== asset.id)
            assets.personalAssets.push(first_asset, second_asset);
        }
    })
}

function splitRevTrustForLegalAgreements(assets: AssetTitlingAssets) {
    const legalAgreementsWithRecTrust = assets.legalAgreements.filter(PA => PA.memberOwnerships.some(ownership => ownership.isRevocableTrust));
    legalAgreementsWithRecTrust.forEach(asset => {
        let first_asset: LegalAgreement;
        let second_asset: LegalAgreement;

        if (asset.memberOwnerships.length > 1 && !(asset.memberOwnerships[0].isRevocableTrust && asset.memberOwnerships[1].isRevocableTrust)) {
            first_asset = {
                ...asset,
                memberOwnerships: [asset.memberOwnerships[0]],
                marketEstateValue: {
                    ...asset.marketEstateValue,
                    inEstateValue: asset.marketEstateValue.totalValue * (asset.memberOwnerships[0].percentage / 100)
                }
            };
            second_asset = {
                ...asset,
                memberOwnerships: [asset.memberOwnerships[1]],
                marketEstateValue: {
                    ...asset.marketEstateValue,
                    inEstateValue: asset.marketEstateValue.totalValue * (asset.memberOwnerships[1].percentage / 100)
                }
            };

            assets.legalAgreements = assets.legalAgreements.filter(acc => acc.id !== asset.id)
            assets.legalAgreements.push(first_asset, second_asset);
        }
    })
}

function splitRevTrustForHeldAwayAccounts(assets: AssetTitlingAssets) {
    const heldAwayAccountsWithRecTrust = assets.heldAwayAccounts.filter(PA => PA.memberOwnerships.some(ownership => ownership.isRevocableTrust));
    heldAwayAccountsWithRecTrust.forEach(asset => {
        let first_asset: HeldAwayAccountSummary;
        let second_asset: HeldAwayAccountSummary;

        if (asset.memberOwnerships.length > 1 && !(asset.memberOwnerships[0].isRevocableTrust && asset.memberOwnerships[1].isRevocableTrust)) {
            first_asset = {
                ...asset,
                memberOwnerships: [asset.memberOwnerships[0]],
                marketEstateValue: {
                    ...asset.marketEstateValue,
                    inEstateValue: asset.marketEstateValue.totalValue * (asset.memberOwnerships[0].percentage / 100)
                }
            };
            second_asset = {
                ...asset,
                memberOwnerships: [asset.memberOwnerships[1]],
                marketEstateValue: {
                    ...asset.marketEstateValue,
                    inEstateValue: asset.marketEstateValue.totalValue * (asset.memberOwnerships[1].percentage / 100)
                }
            };

            assets.heldAwayAccounts = assets.heldAwayAccounts.filter(acc => acc.id !== asset.id)
            assets.heldAwayAccounts.push(first_asset, second_asset);
        }
    })
}

function splitRevTrustForPoia(assets: AssetTitlingAssets) {
    const poiaWithRevTrust = assets.partiallyOwnedLegalAgreement.filter(PA => PA.memberOwnerships.some(ownership => ownership.isRevocableTrust));
    poiaWithRevTrust.forEach(asset => {
        let first_asset: PartiallyOwnedLegalAgreement;
        let second_asset: PartiallyOwnedLegalAgreement;

        if (asset.memberOwnerships.length > 1 && !(asset.memberOwnerships[0].isRevocableTrust && asset.memberOwnerships[1].isRevocableTrust)) {
            first_asset = {
                ...asset,
                memberOwnerships: [asset.memberOwnerships[0]],
                marketEstateValue: {
                    ...asset.marketEstateValue,
                    inEstateValue: asset.marketEstateValue.totalValue * (asset.memberOwnerships[0].percentage / 100)
                }
            };
            second_asset = {
                ...asset,
                memberOwnerships: [asset.memberOwnerships[1]],
                marketEstateValue: {
                    ...asset.marketEstateValue,
                    inEstateValue: asset.marketEstateValue.totalValue * (asset.memberOwnerships[1].percentage / 100)
                }
            };

            assets.partiallyOwnedLegalAgreement = assets.partiallyOwnedLegalAgreement.filter(acc => acc.id !== asset.id)
            assets.partiallyOwnedLegalAgreement.push(first_asset, second_asset);
        }
    })
}

function splitPersonalLiabilityForLegalAgreements(assets: AssetTitlingAssets) {
    const personalLiabilityWithRecTrust = assets.personalLiabilities.filter(liability => liability.memberOwnerships.some(ownership => ownership.isRevocableTrust));
    personalLiabilityWithRecTrust.forEach(asset => {
        let first_asset: PersonalLiability;
        let second_asset: PersonalLiability;

        if (asset.memberOwnerships.length > 1 && !(asset.memberOwnerships[0].isRevocableTrust && asset.memberOwnerships[1].isRevocableTrust)) {
            first_asset = {
                ...asset,
                memberOwnerships: [asset.memberOwnerships[0]],
                loanBalanceEstateValue: {
                    ...asset.loanBalanceEstateValue,
                    inEstateValue: asset.loanBalanceEstateValue.totalValue * (asset.memberOwnerships[0].percentage / 100)
                }
            };
            second_asset = {
                ...asset,
                memberOwnerships: [asset.memberOwnerships[1]],
                loanBalanceEstateValue: {
                    ...asset.loanBalanceEstateValue,
                    inEstateValue: asset.loanBalanceEstateValue.totalValue * (asset.memberOwnerships[1].percentage / 100)
                }
            };

            assets.personalLiabilities = assets.personalLiabilities.filter(acc => acc.id !== asset.id)
            assets.personalLiabilities.push(first_asset, second_asset);
        }
    })
}

export function mapAssetsToAssetTitlingAccordionItems(assetSummary: AssetsSummary): AssetTitlingAccordionItem[] {
    const JTWROS: OwnershipCategory = 'JTWROS (Joint Tenants with Rights of Survivorship)';

    function getRevocableTrustAsset(asset: Asset) {
        const assetRevTrust = asset.memberOwnerships.some(ownership => ownership.isRevocableTrust);
        const equityCompensation = assetSummary.equityCompensations.data.find(x => x.id === asset.id);
        const equityCompensationRevTrust = equityCompensation?.owner.endsWith('revTrust');

        return assetRevTrust || (equityCompensationRevTrust !== undefined && equityCompensationRevTrust);
    }

    function getAssetsForWill(asset: Asset){
        const assetForWill = asset.memberOwnerships.some(ownership => ownership.isInEstateMember && !ownership.isRevocableTrust);
        const equityCompensation = assetSummary.equityCompensations.data.find(x => x.id === asset.id);
        const equityCompensationWill = !equityCompensation?.owner.endsWith('revTrust');
        return assetForWill || equityCompensationWill ;
    }

    const accordionItemPropertiesInPriorityOrder: AssetTitlingAccordionProperties[] = [
        {
            id: 'beneficiary-designation',
            primaryText: 'Beneficiary Designation',
            accentColor: ASSET_TITLING_BENEFICIARY_DESIGNATION,
            accountFilter: asset => (asset.taxStatus === 'Deferred' || asset.taxStatus === 'Exempt')
                && asset.doesPermitBeneficiary === true,
            heldAwayAccountFilter: asset => (asset.taxStatus === 'Deferred' || asset.taxStatus === 'Exempt')
                && asset.doesPermitBeneficiary === true,
            lifeInsuranceFilter: asset=>!asset.isCashValueWillFundGoals ,
            legalAgreementFilter: asset => asset.permitBeneficiary === true,
            partiallyOwnedLegalAgreementFilter: asset => asset.permitBeneficiary === true,
            equityCompensationFilter: asset => asset.doesPermitBeneficiary,
            displayOrder: 20
        },
        {
            id: 'joint-tenancy',
            primaryText: 'Joint Tenancy',
            accentColor: ASSET_TITLING_JOINT_TENANCY,
            defaultFilter: asset => asset.ownershipCategory === JTWROS,
            displayOrder: 10
        },
        {
            id: 'revocable-trust',
            primaryText: 'Revocable Trust',
            accentColor: ASSET_TITLING_REVOCABLE_TRUST,
            defaultFilter: asset => getRevocableTrustAsset(asset),
            displayOrder: 40
        },
        {
            id: 'will',
            primaryText: 'Will',
            accentColor: ASSET_TITLING_WILL,
            defaultFilter: asset => getAssetsForWill(asset),
            displayOrder: 30
        }
    ];

    let assets: AssetTitlingAssets = {
        accounts: assetSummary.accounts.data,
        personalAssets: assetSummary.personalAssets.data,
        lifeInsurances: assetSummary.lifeInsurances.data,
        legalAgreements: assetSummary.investmentProgram?.legalAgreements ?? [],
        heldAwayAccounts: assetSummary.investmentProgram?.heldAwayAccounts ?? [],
        partiallyOwnedLegalAgreement: assetSummary.partiallyOwnedLegalAgreements ?? [],
        personalLiabilities: assetSummary.personalLiabilities,
        equityCompensations: mapEquityCompensation(assetSummary.equityCompensations.data)
    };

    const accordionItems: AssetTitlingAccordionItem[] = [];

    spiltRevTrustStandaloneAccounts(assets);

    splitRevTrustPersonalAssets(assets);

    splitRevTrustForLegalAgreements(assets);

    splitRevTrustForHeldAwayAccounts(assets);

    splitRevTrustForPoia(assets);

    splitPersonalLiabilityForLegalAgreements(assets);

    accordionItemPropertiesInPriorityOrder.forEach(item => {
        const {group, remainingAssets} = mapAssetSummaryToAssetTitlingGroup(assets, item);
        accordionItems.push({
            ...item,
            ...group
        });
        assets = remainingAssets;
    });

    return accordionItems.sort((a, b) => a.displayOrder - b.displayOrder);
}

const computeInEstateCashValue = (asset: LifeInsurance): number => {
    let inEstatePercentage = 0;

    asset.memberOwnerships.filter(member => member.isInEstateMember)
        .forEach(member => inEstatePercentage += member.percentage)

    return asset.cashValue * (inEstatePercentage / 100);
}

const computeInEstateDeathBenefitValue = (asset: LifeInsurance): number => {
    let inEstatePercentage = 0;

    asset.memberOwnerships.filter(member => member.isInEstateMember)
        .forEach(member => inEstatePercentage += member.percentage)

    return asset.deathBenefitValue * (inEstatePercentage / 100);
}

function mapAssetSummaryToAssetTitlingGroup(assets: AssetTitlingAssets, item: AssetTitlingAccordionProperties): {
    group: AssetTitlingGroup,
    remainingAssets: AssetTitlingAssets
} {

    const accountsResult = mapAssetsToGroupedTableData(
        assets.accounts,
        item.accountFilter ?? item.defaultFilter ?? EXCLUDE_ALL,
        asset => asset.name,
        asset => asset.marketValue.inEstateValue || 0
    );

    const personalAssetsResult = mapAssetsToGroupedTableData(
        assets.personalAssets,
        item.personalAssetFilter ?? item.defaultFilter ?? EXCLUDE_ALL,
        asset => asset.description,
        asset => asset.presentEstateValue.inEstateValue
    );

    const lifeInsurancesResult = mapAssetsToGroupedTableData(
        assets.lifeInsurances,
        item.lifeInsuranceFilter ?? item.defaultFilter ?? EXCLUDE_ALL,
        asset => asset.description,
        asset => asset.isCashValueWillFundGoals ? computeInEstateCashValue(asset) : computeInEstateDeathBenefitValue(asset)
    );

    const legalAgreementsResult = mapAssetsToGroupedTableData(
        assets.legalAgreements,
        item.legalAgreementFilter ?? item.defaultFilter ?? EXCLUDE_ALL,
        asset => asset.name,
        asset => asset.marketEstateValue.inEstateValue
    );

    const heldAwayAccountsResult = mapAssetsToGroupedTableData(
        assets.heldAwayAccounts,
        item.heldAwayAccountFilter ?? item.defaultFilter ?? EXCLUDE_ALL,
        asset => asset.financialAccountName,
        asset => asset.marketEstateValue?.inEstateValue ?? 0
    );

    const partiallyOwnedLegalAgreementResult = mapAssetsToGroupedTableData(
        assets.partiallyOwnedLegalAgreement,
        item.partiallyOwnedLegalAgreementFilter ?? item.defaultFilter ?? EXCLUDE_ALL,
        asset => asset.legalAgreementName,
        asset => asset.marketEstateValue.inEstateValue
    );


    const personalLiabilitiesResult = mapAssetsToGroupedTableData(
        assets.personalLiabilities,
        item.personalLiabilityFilter ?? item.defaultFilter ?? EXCLUDE_ALL,
        asset => asset.description,
        asset => asset.loanBalanceEstateValue.inEstateValue * -1
    );

    const equityCompensationResult = mapAssetsToGroupedTableData(
        assets.equityCompensations,
        item.equityCompensationFilter ?? item.defaultFilter ?? EXCLUDE_ALL,
        asset => asset.entityOrAccountName,
        asset => asset.afterTaxVestedValue ? asset.afterTaxVestedValue : 0
    );

    const items: GroupedTableItem[] = [
        ...accountsResult.tableData,
        ...personalAssetsResult.tableData,
        ...lifeInsurancesResult.tableData,
        ...legalAgreementsResult.tableData,
        ...partiallyOwnedLegalAgreementResult.tableData,
        ...personalLiabilitiesResult.tableData,
        ...equityCompensationResult.tableData,
        ...heldAwayAccountsResult.tableData
    ];
    const group: AssetTitlingGroup = {
        tableData: [],
        totalValue: 0,
        hasData: items.length > 0
    };
    if (group.hasData) {
        const totalValue = items.reduce((total, tableItem) => total + tableItem.itemValue, 0);
        group.totalValue = totalValue;
        const sortedItems = [...items].sort((a, b) => (a.itemValue > b.itemValue ? -1 : 1));
        group.tableData.push({
            groupId: '',
            groupName: '',
            groupValue: totalValue,
            items: sortedItems
        })
    }

    return {
        group,
        remainingAssets: {
            accounts: accountsResult.remainingAssets,
            personalAssets: personalAssetsResult.remainingAssets,
            lifeInsurances: lifeInsurancesResult.remainingAssets,
            legalAgreements: legalAgreementsResult.remainingAssets,
            heldAwayAccounts: heldAwayAccountsResult.remainingAssets,
            partiallyOwnedLegalAgreement: partiallyOwnedLegalAgreementResult.remainingAssets,
            personalLiabilities: personalLiabilitiesResult.remainingAssets,
            equityCompensations: equityCompensationResult.remainingAssets
        }
    };
}

function mapAssetsToGroupedTableData<T extends Asset>(
    assets: T[],
    filter: (asset: T) => boolean,
    getItemName: (asset: T) => string,
    getItemValue: (asset: T) => number): { tableData: GroupedTableItem[], remainingAssets: T[] } {

    const tableData = assets
        .filter(asset => filter(asset) && getItemValue(asset) !== 0)
        .map(asset => ({
            itemName: getItemName(asset),
            itemValue: getItemValue(asset)
        }))
    const remainingAssets = assets.filter(asset => !filter(asset));
    return {
        tableData,
        remainingAssets
    };
}

function mapEquityCompensation(equityCompensationFormDataList: EquityCompensationFormData[]): EquityCompensation[] {
    let equityCompensations: EquityCompensation[] = [];
    equityCompensationFormDataList.forEach(equity => {
        const equityCompensation: EquityCompensation = {
            id: equity.id,
            profileId: equity.profileId,
            ordinal: 0,
            entityOrAccountName: equity.entityOrAccountName,
            afterTaxVestedValue: equity.afterTaxVestedValue,
            owner: equity.owner,
            doesPermitBeneficiary: equity.doesPermitBeneficiary,
            ownershipCategory: setOwnershipType(equity.owner) as OwnershipCategory,
            memberOwnerships: [],
            legalEntityOwnerships: [],
        }
        equityCompensations.push(equityCompensation);
    })
    return equityCompensations;
}

function setOwnershipType(owner: String) {
    let ownership;
    switch (owner) {
        case 'joint':
            ownership = "JTWROS (Joint Tenants with Rights of Survivorship)"
            break;
        default:
            ownership = "Sole";
            break;
    }
    return ownership;
}