import CustomModal from "../../components/Modal/Custom/CustomModal";
import {formatCurrency} from "../../utils/format";
import React from "react";
import {
    PartiallyOwnedInvestmentAccountReadModel,
    PartiallyOwnedLegalAgreement
} from "../models/PartiallyOwnedInvestmentAccount";

interface DeleteModalForLegalAgreementProps {
    isOpen: boolean,
    partiallyOwnedInvestmentAccount: PartiallyOwnedLegalAgreement,
    onConfirm: (id: string) => void,
    onCancel: () => void,
}

const DeletePartiallyOwnedInvestmentAccountConfirmationModal = (
    {isOpen,partiallyOwnedInvestmentAccount,onConfirm,onCancel}: DeleteModalForLegalAgreementProps) => {

    return <CustomModal
        isOpen={isOpen}
        title={
            <h1 className="h3 display-inlineblock">
                {`Remove ${partiallyOwnedInvestmentAccount?.legalAgreementName} from this profile?`}
            </h1>
        }
        size="large"
        content={
        <div className='font-default'>
            {partiallyOwnedInvestmentAccount &&
                <div>
                    <div className="remove-partially-owned-investment-title" style={{marginBottom: "20px"}}>
                        <span className="fontweight-500" style={{marginBottom: "-10px"}}>Partially Owned Investment Account</span>
                        <span>{partiallyOwnedInvestmentAccount.legalAgreementName}</span>
                    </div>
                    <div className="remove-partially-owned-investment-grid">
                        <div className="remove-partially-owned-investment-content">
                            <span className="textalign-left fontweight-500" style={{marginBottom: "10px"}}>Legal Agreement #</span>
                            <span className="textalign-left">{partiallyOwnedInvestmentAccount.legalAgreementNumber}</span>
                        </div>

                        <div className="remove-partially-owned-investment-content">
                            <span className="textalign-left fontweight-500">Market Value</span>
                            <span className="textalign-left">{formatCurrency(partiallyOwnedInvestmentAccount.marketEstateValue.inEstateValue)}</span>
                        </div>
                    </div>
                </div>
            }
        </div>}
        onClickConfirm={() => onConfirm(partiallyOwnedInvestmentAccount.id)}
        onClickCancel={onCancel}
        confirmText='Remove Partially Owned Investment Account'
        cancelText='Cancel'
        confirmButtonDestructive
    />;
}

export default DeletePartiallyOwnedInvestmentAccountConfirmationModal;