import React, {useEffect, useState} from "react";
import WealthPlanOptimizerContent from "./WealthPlanOptimizerContent";
import {Route, Switch, useParams} from "react-router-dom";
import AddEditStateOfResidency from "./AddEditStateOfResidency";
import AddEditAnnualGifting from "./AddEditAnnualGifting";
import {useAppDispatch} from "../store/hooks";
import {
    resetWealthPlanOptimizerState,
    setWealthPlanOptimizerState,
    WealthPlanOptimizerState
} from "./WealthPlanOptimizerSlice";
import {wealthPOApiClient} from "./WealthPOApiClient";
import LoadingIndicator from "../pages/LoadingIndicator";
import {RouteWithId} from "../routes/types";
import GenericErrorModal, {
    genericEmptyErrorModalData,
    GenericErrorModalData
} from "../components/Modal/Error/GenericErrorModal";
import {EmptyStateContainer} from "../components";

const WealthPlanOptimizer = () => {
    const {id} = useParams<RouteWithId>();
    const dispatch = useAppDispatch();

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [genericError, setGenericError] = React.useState<GenericErrorModalData>(genericEmptyErrorModalData);
    const [showTempErrorMessage, setShowTempErrorMessage] = useState(false)

    const fetchStrategiesSummary = () => {
        setIsLoading(true);

        wealthPOApiClient.getWealthPOSummary(id).then(wealthPOSummaryResponse => {
            dispatch(setWealthPlanOptimizerState({
                wealthPOSummaryResponse,
                hasStrategies: true
            } as WealthPlanOptimizerState))

            setIsLoading(false);
        }).catch((error) => {
            if (error.status === 404) {
                dispatch(resetWealthPlanOptimizerState());
            } else {
                openErrorModal(error)
            }

            setIsLoading(false);
        });
    };

    const openErrorModal = (error: Error | any) => {
        setGenericError({
            isOpen: true,
            header: "Unable to Load This Page",
            message: (
                <>
                    <p>Check your VPN connection and retry.</p>
                    <p>If the problem persists, contact <a href="mailto:GPIITSupport@ntrs.com">GPS Technical
                        Support</a> at GPIITSupport@ntrs.com and provide the following details:</p>
                </>
            ),
            profileId: id,
            time: new Date(),
            errorDetail: `Failed to load Wealth Plan Optimizer (${error.status})`,
            operationId: error.headers.get('trace-id')
        });
    };

    const closeErrorModal = () => {
        setGenericError({...genericError, isOpen: false});
    };

    const handleRetryClickInCommunicationErrorModal = () => {
        closeErrorModal();
        fetchStrategiesSummary();
    }

    const handleCloseClickInCommunicationErrorModal = () => {
        closeErrorModal();
        setShowTempErrorMessage(true);
    }

    useEffect(() => {
        fetchStrategiesSummary();

        return () => {
            dispatch(resetWealthPlanOptimizerState());
        }
    }, [id])

    if (isLoading) {
        return <LoadingIndicator/>
    }

    if (genericError.isOpen) {
        return (
                <GenericErrorModal
                    errorModalData={genericError}
                    onClickButton={handleRetryClickInCommunicationErrorModal}
                    onRequestClose={handleCloseClickInCommunicationErrorModal}
                    buttonText="Retry"
                    buttonProps={
                        {
                            primary: true,
                            className: 'full-width center-align',
                            iconPosition: 'left',
                            iconName: 'refresh'
                        }
                    }
                    showAlertIcon={false}
                />
        );
    }

    if (showTempErrorMessage) {
        return (
                <EmptyStateContainer
                    className="no-wpo-summary-placeholder"
                    title="Unable to Load This Page"
                    size="large"
                    description="Try again later."
                />
        );
    }

    return (
        <Switch>
            <Route exact path="/Profile/:id/ClientProfile/WealthPlanOptimizer/Summary">
                   <WealthPlanOptimizerContent refreshStrategiesSummary={fetchStrategiesSummary}/>
            </Route>
            <Route path="/Profile/:id/ClientProfile/WealthPlanOptimizer/AddStateOfResidency">
                <AddEditStateOfResidency refreshStrategiesSummary={fetchStrategiesSummary}/>
            </Route>
            <Route path="/Profile/:id/ClientProfile/WealthPlanOptimizer/EditStateOfResidency/:strategyId">
                   <AddEditStateOfResidency refreshStrategiesSummary={fetchStrategiesSummary}/>
            </Route>
            <Route path="/Profile/:id/ClientProfile/WealthPlanOptimizer/AddAnnualGifting"
                   component={AddEditAnnualGifting}/>
        </Switch>
    )
}

export default WealthPlanOptimizer;
