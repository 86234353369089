import React from "react";
import {Col, Container, Row} from "react-grid-system";
import AccordionWithHeader from "../../components/Accordion/AccordionWithHeader";
import {StrategyListHeader} from "./StrategyListHeader";
import {useAppSelector} from "../../store/hooks";
import {selectWealthPlanOptimizerState} from "../WealthPlanOptimizerSlice";
import {COLOR_ENABLED_WPO_STRATEGY} from "../../constants/colors";
import {AccordionItemWithActionMenu, DropdownItem, Icon} from "../../components";
import {StrategyAccordionItemHeader} from "./StrategyAccordionItemHeader";
import {TableActionDropdownMenu} from "../../components/Table/TableActionDropdownMenu";
import {formatCurrency} from "../../utils/format";
import {useHistory, useParams} from "react-router-dom";
import {RouteWithId} from "../../routes/types";
import {wealthPOApiClient} from "../WealthPOApiClient";
import {StrategyType} from "../WealthPOTypes";

interface StrategyDetailsProps {
    refreshStrategiesSummary: () => void;
}

const StrategyDetailsSection: React.FC<StrategyDetailsProps> = ({
                                                                    refreshStrategiesSummary
                                                                }: StrategyDetailsProps) => {
    const {id} = useParams<RouteWithId>();
    const history = useHistory();

    const wealthPlanOptimizerState = useAppSelector(selectWealthPlanOptimizerState)
    const stateOfResidencyStrategy = wealthPlanOptimizerState.wealthPOSummaryResponse.stateOfResidencyStrategy;

    const editStrategy = (strategyId: string, strategyType: StrategyType) => {
        if (strategyType === StrategyType.STATE_OF_RESIDENCY) {
            history.push(`/Profile/${id}/ClientProfile/WealthPlanOptimizer/EditStateOfResidency/${strategyId}`);
        }
    }

    const deleteStrategy = (strategyId: string) => {
        wealthPOApiClient.deleteStateOfResidencyStrategy(id, strategyId)
            .then(() => refreshStrategiesSummary())
    }

    return (
        <section className={"strategy-summary-details-section"}>
            <Container className={"summary-details-card"}>
                <Row>
                    <Col>
                        <h4>Wealth Planning Strategies</h4>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className={"placeholder-card"}></div>
                    </Col>
                </Row>
                <Row data-testid='strategy-list' style={{margin: "0px"}}>
                    <Col style={{padding: 0}}>
                        <AccordionWithHeader
                            accordionId={'strategy-list-accordion'}
                            allItems={[]}
                            columnTitles={['Name', 'Strategy', 'Impact to Amount to Beneficiaries', 'Impact to  Est. Estate Tax']}
                            renderHeader={true}
                            HeaderComponent={StrategyListHeader}
                        >
                            {stateOfResidencyStrategy && stateOfResidencyStrategy.id &&
                                <AccordionItemWithActionMenu
                                    uuid={stateOfResidencyStrategy.id}
                                    accentColor={COLOR_ENABLED_WPO_STRATEGY}
                                    HeaderComponent={() => StrategyAccordionItemHeader({stateOfResidencyStrategy})}
                                    expandable={false}
                                    actionMenu={
                                        <div data-testid="state-of-residency-strategy-action-menu">
                                            <TableActionDropdownMenu
                                                ariaLabel={'State of Residency Strategy Action Menu'}
                                            >
                                                <DropdownItem
                                                    itemText="Edit Strategy"
                                                    value="Edit Strategy"
                                                    onClick={() => editStrategy(stateOfResidencyStrategy.id!, StrategyType.STATE_OF_RESIDENCY)}
                                                />
                                                <DropdownItem
                                                    itemText="Delete Strategy"
                                                    value="Delete Strategy"
                                                    onClick={() => deleteStrategy(stateOfResidencyStrategy.id!)}
                                                >
                                                    <Icon name="delete" className="delete-trash-icon"
                                                          data-testid="delete-trash-icon"/>
                                                </DropdownItem>
                                            </TableActionDropdownMenu>
                                        </div>
                                    }
                                />
                            }
                        </AccordionWithHeader>
                        <div data-testid={'strategy-list-totals'} className={"strategy-list-totals"}>
                            <span className={"span-1"}>Totals</span>
                            <span className={"span-2"}>$0</span>
                            <span
                                className={"span-3"}>{stateOfResidencyStrategy && formatCurrency(stateOfResidencyStrategy.estimatedImpact.impactToEstEstateTax)}</span>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default StrategyDetailsSection;

