import {AssetsSummary} from "../models/Assets";
import {hasAssets, hasLiabilities, hasOutOfEstateAssets, hasOutOfEstateLiabilities} from "./common/utils";
import {useOutOfEstateAssetSummary} from "../common/OutOfEstate/useOutOfEstateSummary";
import ScrollableContainer from "../../components/ScrollableContainer/ScrollableContainer";
import InEstateAssetsSummary from "./InEstate/InEstateAssetsSummary";
import LiabilitiesSummary from "./Liabilities/LiabilitiesSummary";
import OutOfEstateContainer from "../common/OutOfEstate/OutOfEstateContainer";
import {EmptyStateContainer} from "../../components";
import AddAssetButtonDropdown from "./common/AddAssetButtonDropdown";
import React from "react";
import {LegalEntityReadModel} from "../models/Ownership";
import {InvestorGroupType, MemberGroup} from "../../ClientManagement/models/InvestorGroupType";
import {DeleteModalProps} from "../common/AssetsView";
import useProfileEditableState from "../../hooks/useProfileEditableState";
import {GeneralInflow} from "../models/GeneralInflow";

interface AssetSummaryContentProps {
    clientAssets: AssetsSummary;
    legalEntities: LegalEntityReadModel[];
    investorGroup: InvestorGroupType;
    profileId: string;
    onDeleteAssetClick: (deleteModalProps: DeleteModalProps) => void;
    onRemoveInvestmentProgram: () => void;
    onDeleteLegalEntity: (legalEntityId: string) => void;
    onRefreshInvestmentProgramHoldings: () => void;
    onSaveReviewTrustDisclosure: (generalInflow: GeneralInflow) => void;
    memberGroup: MemberGroup;
    onArrangeAssets: () => void;
}

export const AssetSummaryContent = ({
                                        clientAssets,
                                        legalEntities,
                                        investorGroup,
                                        profileId,
                                        onDeleteAssetClick,
                                        onRemoveInvestmentProgram,
                                        onDeleteLegalEntity,
                                        onRefreshInvestmentProgramHoldings,
                                        onSaveReviewTrustDisclosure,
                                        memberGroup,
                                        onArrangeAssets
                                    }: AssetSummaryContentProps) => {
    const hasAssetsOrLiabilities = (assets: AssetsSummary) => hasAssets(assets) || hasLiabilities(assets);
    const {showOutOfEstateAssetSummary, setShowOutOfEstateAssetSummary} = useOutOfEstateAssetSummary();
    const {isProfileWithProposalsOrArchived} = useProfileEditableState();

    return <ScrollableContainer id={'asset-summary-scroll-container'}>
        <div data-testid="asset-summary-content">
            {hasAssetsOrLiabilities(clientAssets) ?
                <div className="asset-summary-content">
                    {hasAssets(clientAssets) &&
                        <InEstateAssetsSummary
                            assets={clientAssets}
                            investorGroup={investorGroup}
                            profileId={profileId}
                            onDeleteAssetClick={onDeleteAssetClick}
                            onRemoveInvestmentProgram={onRemoveInvestmentProgram}
                            onRefreshInvestmentProgramHoldings={onRefreshInvestmentProgramHoldings}
                            onSaveReviewTrustDisclosure={onSaveReviewTrustDisclosure}
                            memberGroup={memberGroup}
                            onArrangeAssets={onArrangeAssets}
                            shouldDisableArrangeAssets={isProfileWithProposalsOrArchived!}
                        />
                    }
                    {hasLiabilities(clientAssets) &&
                        <LiabilitiesSummary assets={clientAssets}
                                            investorGroup={investorGroup}
                                            onDeleteAssetClick={onDeleteAssetClick}
                                            memberGroup={memberGroup}
                        />
                    }
                    {(hasOutOfEstateAssets(clientAssets) || hasOutOfEstateLiabilities(clientAssets)) &&
                    <OutOfEstateContainer showOutOfEstate={showOutOfEstateAssetSummary}
                                          onClickShowOutOfEstate={setShowOutOfEstateAssetSummary}
                                          assets={clientAssets}
                                          legalEntities={legalEntities}
                                          onDeleteLegalEntity={onDeleteLegalEntity}
                    />
                    }
                </div> :
                <EmptyStateContainer
                    className="no-assets-placeholder"
                    title="Begin by entering your assets"
                    size="large"
                    description={
                        <AddAssetButtonDropdown
                            className="add-an-asset-button add-asset-dropdown margintop-lg"
                            assets={clientAssets}
                            investorGroup={investorGroup}
                            memberGroup={memberGroup}/>
                    }
                />
            }
        </div>
    </ScrollableContainer>
};
