import {AssetsSummary, CurrentNetWorthAsset} from "../../../Assets/models/Assets";
import {COLOR_ASSETS_ACCOUNTS} from "../../../constants/colors";
import {AccordionItemWithActionMenu} from "../../../components";
import React from "react";
import {
    calculateAllAccountsInEstateTotalMarketValue,
    calculateCNWAssetsJointlyOwnedTotalPresentValue,
    calculateCNWAssetsMemberOwnedTotalPresentValue,
    calculateJointlyOwnedValue,
    calculateMemberOwnedValue
} from "../../../Assets/AssetSummary/common/AssetSummaryCalculator"
import {NO_OP} from "../../../constants/common";
import {TableCell} from "../../../components/Table/TableCell";
import {InvestorGroupMember, InvestorGroupType} from "src/ClientManagement/models/InvestorGroupType";
import {HeldAwayAccountSummary, LegalAgreement} from "../../../Assets/models/InvestmentProgram";
import {StandaloneAccount} from "../../../Assets/models/StandaloneAccount";
import {getOwnershipDescription} from "../../../Assets/CurrentNetWorth/InEstate/ownershipUtils";
import {AssetAccordionHeader} from "../../../Assets/AssetSummary/common/AssetAccordionHeader";
import {AssetAccordionContentHeader} from "../../../Assets/AssetSummary/common/AssetAccordionContentHeader";
import {LegalAgreementRow} from "../../../Assets/AssetSummary/InEstate/LegalAgreementRow";
import {StandaloneAccountRow} from "../../../Assets/AssetSummary/InEstate/StandaloneAccountRow";
import {showOwnershipType} from "../../../Assets/Ownership/helpers";
import {assetListData} from "../../../Assets/AssetSummary/common/utils";
import {shortenName} from "../../../Assets/common/textUtils";
import {HeldAwayAccountRow} from "../../../Assets/AssetSummary/InEstate/HeldAwayAccountRow";
import {PartiallyOwnedLegalAgreement} from "../../../Assets/models/PartiallyOwnedInvestmentAccount";
import {
    PartiallyOwnedInvestmentAccountRow
} from "../../../Assets/AssetSummary/InEstate/PartiallyOwnedInvestmentAccountRow";

export interface InEstateAccountsProps {
    assetsDataForPage: AssetsSummary,
    investorGroup: InvestorGroupType,
    caption: string,
    allAssetsCNW: AssetsSummary
}


const AccountsCNWReport = ({assetsDataForPage, investorGroup, caption, allAssetsCNW}:
                               InEstateAccountsProps) => {
    const {
        getFormattedTotalPresentValue,
        getFormattedPresentValueForCurrentNetWorth,
        hasInEstateAccount,
        hasInEstateInvestmentProgram,
        hasInEstatePartiallyOwnedInvestmentAccounts,
        inEstateStandaloneAccounts,
        inEstateLegalAgreements,
        inEstatePartiallyOwnedInvestmentAccounts,
        inEstateHeldAwayAccounts,
        hasInEstateInvestmentProgramHeldAA
    } = assetListData(assetsDataForPage);

    const currentNetWorthStandaloneAccounts: CurrentNetWorthAsset[] = inEstateStandaloneAccounts.map(account => ({
        id: account.id,
        name: account.name,
        presentValue: account.marketValue.totalValue,
        assetType: "standaloneAccount",
        ownershipCategory: account.ownershipCategory,
        memberOwnerships: account.memberOwnerships
    }));

    const currentNetWorthLegalAgreements: CurrentNetWorthAsset[] = inEstateLegalAgreements.map(legalAgreement => ({
        id: legalAgreement.id,
        name: legalAgreement.name,
        presentValue: legalAgreement.marketValue,
        assetType: "investmentProgram",
        ownershipCategory: legalAgreement.ownershipCategory,
        memberOwnerships: legalAgreement.memberOwnerships
    }));

    const currentNetWorthHeldAwayAccounts: CurrentNetWorthAsset[] = inEstateHeldAwayAccounts.map(heldAwayAccount => ({
        id: heldAwayAccount.id,
        name: heldAwayAccount.financialAccountName,
        presentValue: heldAwayAccount.holdings.marketValue?.totalValue || 0,
        assetType: "investmentProgramHAA",
        ownershipCategory: heldAwayAccount.ownershipCategory,
        memberOwnerships: heldAwayAccount.memberOwnerships
    }));

    const currentNetWorthPartiallyOwnedInvestmentAccounts: CurrentNetWorthAsset[] = inEstatePartiallyOwnedInvestmentAccounts
        .map(legalAgreement => ({
            id: legalAgreement.id,
            name: legalAgreement.legalAgreementName,
            presentValue: legalAgreement.marketEstateValue.totalValue,
            assetType: "partiallyOwnedInvestmentAccount",
            ownershipCategory: legalAgreement.ownershipCategory,
            memberOwnerships: legalAgreement.memberOwnerships
        }));

    const primaryMember = investorGroup.primaryMember;
    const partnerMember = investorGroup.partnerMember;


    function generateAssetPopOverContent(account: StandaloneAccount) {
        const ownershipDescriptionCNW = getOwnershipDescription(account, primaryMember, partnerMember)
        return ownershipDescriptionCNW ? <>{ownershipDescriptionCNW}</> : undefined;
    }

    function calculateMemberOwnedAccountAndIPValue(investorGroupMember: InvestorGroupMember) {
        return calculateCNWAssetsMemberOwnedTotalPresentValue(investorGroupMember.id, currentNetWorthStandaloneAccounts)
            + calculateCNWAssetsMemberOwnedTotalPresentValue(investorGroupMember.id, currentNetWorthLegalAgreements)
            + calculateCNWAssetsMemberOwnedTotalPresentValue(investorGroupMember.id, currentNetWorthHeldAwayAccounts)
            + calculateCNWAssetsMemberOwnedTotalPresentValue(investorGroupMember.id, currentNetWorthPartiallyOwnedInvestmentAccounts);
    }

    function calculateJointlyOwnedAccountAndIPValue() {
        return calculateCNWAssetsJointlyOwnedTotalPresentValue(currentNetWorthStandaloneAccounts)
            + calculateCNWAssetsJointlyOwnedTotalPresentValue(currentNetWorthLegalAgreements)
            + calculateCNWAssetsJointlyOwnedTotalPresentValue(currentNetWorthHeldAwayAccounts)
            + calculateCNWAssetsJointlyOwnedTotalPresentValue(currentNetWorthPartiallyOwnedInvestmentAccounts);
    }

    return (
        <>
            {(hasInEstateAccount || hasInEstateInvestmentProgram || hasInEstateInvestmentProgramHeldAA || hasInEstatePartiallyOwnedInvestmentAccounts)
                && <AccordionItemWithActionMenu
                    uuid="AccountsCNWReport"
                    accentColor={COLOR_ASSETS_ACCOUNTS}
                    HeaderComponent={({expanded}) => {
                        const totalInEstateMarketValue = calculateAllAccountsInEstateTotalMarketValue(
                            allAssetsCNW.accounts.data,
                            allAssetsCNW.investmentProgram,
                            allAssetsCNW.partiallyOwnedLegalAgreements);

                        return <AssetAccordionHeader
                            caption={caption}
                            expanded={expanded}
                            title="Accounts"
                            formattedPrimaryMemberTotalPresentValue={investorGroup.partnerMember ? getFormattedTotalPresentValue(calculateMemberOwnedAccountAndIPValue(investorGroup.primaryMember)) : undefined}
                            formattedSecondaryMemberTotalPresentValue={investorGroup.partnerMember ? getFormattedTotalPresentValue(calculateMemberOwnedAccountAndIPValue(investorGroup.partnerMember)) : undefined}
                            formattedJointTotalPresentValue={investorGroup.partnerMember ? getFormattedTotalPresentValue(calculateJointlyOwnedAccountAndIPValue()) : undefined}
                            formattedTotalPresentValue={getFormattedTotalPresentValue(totalInEstateMarketValue)}
                            gridClassName={"current-net-worth-grid"}/>
                    }
                    }>

                    <div role="table" className="current-net-worth-grid-with-actionmenu assets-grid-table"
                         aria-label="accounts-table">
                        <AssetAccordionContentHeader investorGroup={investorGroup}/>

                        {assetsDataForPage.investmentProgram &&
                            <>
                                {inEstateLegalAgreements.map((legalAgreement: LegalAgreement) => {
                                    return <LegalAgreementRow legalAgreement={legalAgreement}
                                                              actionsDisabled={false}
                                                              onClickEdit={NO_OP}
                                                              key={legalAgreement.id}
                                                              gridClassName={"current-net-worth-grid-with-actionmenu"}
                                                              renderLegalAgreementDetails={(agreement: LegalAgreement) => {
                                                                  return <>
                                                                      <TableCell text={shortenName(agreement.name)}
                                                                                 className={`${investorGroup.partnerMember ? '' : 'grid-span-4'} textalign-left`}/>
                                                                      {investorGroup.partnerMember && <>
                                                                          <TableCell
                                                                              text={getFormattedPresentValueForCurrentNetWorth(
                                                                                  calculateMemberOwnedValue(
                                                                                      investorGroup.primaryMember.id,
                                                                                      agreement.memberOwnerships,
                                                                                      agreement.ownershipCategory,
                                                                                      agreement.marketValue))}
                                                                              className="textalign-right"/>
                                                                          <TableCell
                                                                              text={getFormattedPresentValueForCurrentNetWorth(
                                                                                  calculateMemberOwnedValue(
                                                                                      investorGroup.partnerMember.id,
                                                                                      agreement.memberOwnerships,
                                                                                      agreement.ownershipCategory,
                                                                                      agreement.marketValue))}
                                                                              className="textalign-right"/>
                                                                          <TableCell
                                                                              text={getFormattedPresentValueForCurrentNetWorth(
                                                                                  calculateJointlyOwnedValue(
                                                                                      agreement.ownershipCategory,
                                                                                      agreement.marketValue))}
                                                                              className="textalign-right"/>
                                                                      </>
                                                                      }
                                                                  </>
                                                              }} onClickViewHoldings={NO_OP}/>;

                                })}
                            </>
                        }

                        {assetsDataForPage.accounts.data.length > 0 &&
                            <>
                                {inEstateStandaloneAccounts.map((standaloneAccount: StandaloneAccount) => {
                                    return <StandaloneAccountRow standaloneAccount={standaloneAccount}
                                                                 actionsDisabled={false}
                                                                 onClickEdit={NO_OP}
                                                                 onClickDelete={NO_OP}
                                                                 onClickViewHoldings={NO_OP}
                                                                 key={standaloneAccount.id}
                                                                 gridClassName={"current-net-worth-grid-with-actionmenu"}
                                                                 renderStandaloneAccountDetails={(account: StandaloneAccount) => {
                                                                     return <>
                                                                         <TableCell text={account.name}
                                                                                    subtext={showOwnershipType(account.ownershipCategory)}
                                                                                    className={`${investorGroup.partnerMember ? '' : 'grid-span-4'} textalign-left`}
                                                                                    popoverContent={generateAssetPopOverContent(account)}
                                                                                    popoverWidth={"288px"}
                                                                         />
                                                                         {investorGroup.partnerMember && <>
                                                                             <TableCell
                                                                                 text={getFormattedPresentValueForCurrentNetWorth(
                                                                                     calculateMemberOwnedValue(
                                                                                         investorGroup.primaryMember.id,
                                                                                         account.memberOwnerships,
                                                                                         account.ownershipCategory,
                                                                                         account.marketValue.totalValue))}
                                                                                 className="textalign-right"/>
                                                                             <TableCell
                                                                                 text={getFormattedPresentValueForCurrentNetWorth(
                                                                                     calculateMemberOwnedValue(
                                                                                         investorGroup.partnerMember.id,
                                                                                         account.memberOwnerships,
                                                                                         account.ownershipCategory,
                                                                                         account.marketValue.totalValue))}
                                                                                 className="textalign-right"/>
                                                                             <TableCell
                                                                                 text={getFormattedPresentValueForCurrentNetWorth(
                                                                                     calculateJointlyOwnedValue(
                                                                                         account.ownershipCategory,
                                                                                         account.marketValue.totalValue))}
                                                                                 className="textalign-right"/>
                                                                         </>
                                                                         }
                                                                     </>
                                                                 }}/>;
                                })}
                            </>
                        }

                        {hasInEstateInvestmentProgramHeldAA &&
                            <>
                                {inEstateHeldAwayAccounts.map((heldAwayAccount: HeldAwayAccountSummary) => {
                                    return <HeldAwayAccountRow heldAwayAccount={heldAwayAccount}
                                                               actionsDisabled={false}
                                                               onClickEdit={NO_OP}
                                                               onClickViewHoldings={NO_OP}
                                                               key={heldAwayAccount?.id}
                                                               gridClassName={"current-net-worth-grid-with-actionmenu"}
                                                               renderHeldAwayAccountDetails={(heldAwayAccountsReport: HeldAwayAccountSummary) => {
                                                                   return <>
                                                                       <TableCell
                                                                           text={shortenName(heldAwayAccountsReport.financialAccountName)}
                                                                           subtext={showOwnershipType(heldAwayAccountsReport.ownershipCategory)}
                                                                           className={`${investorGroup.partnerMember ? '' : 'grid-span-4'} textalign-left`}
                                                                       />
                                                                       {investorGroup.partnerMember && <>
                                                                           <TableCell
                                                                               text={getFormattedPresentValueForCurrentNetWorth(
                                                                                   calculateMemberOwnedValue(
                                                                                       investorGroup.primaryMember.id,
                                                                                       heldAwayAccountsReport.memberOwnerships,
                                                                                       heldAwayAccountsReport.ownershipCategory,
                                                                                       heldAwayAccountsReport.holdings.marketValue?.totalValue || 0))}
                                                                               className="textalign-right"/>
                                                                           <TableCell
                                                                               text={getFormattedPresentValueForCurrentNetWorth(
                                                                                   calculateMemberOwnedValue(
                                                                                       investorGroup.partnerMember.id,
                                                                                       heldAwayAccountsReport.memberOwnerships,
                                                                                       heldAwayAccountsReport.ownershipCategory,
                                                                                       heldAwayAccountsReport.holdings.marketValue?.totalValue || 0))}
                                                                               className="textalign-right"/>
                                                                           <TableCell
                                                                               text={getFormattedPresentValueForCurrentNetWorth(
                                                                                   calculateJointlyOwnedValue(
                                                                                       heldAwayAccountsReport.ownershipCategory,
                                                                                       heldAwayAccountsReport.holdings.marketValue?.totalValue || 0))}
                                                                               className="textalign-right"/>
                                                                       </>
                                                                       }
                                                                   </>
                                                               }}/>;
                                })}
                            </>
                        }

                        {hasInEstatePartiallyOwnedInvestmentAccounts &&
                            <>
                                {inEstatePartiallyOwnedInvestmentAccounts.map((partiallyOwnedInvestmentAccount: PartiallyOwnedLegalAgreement) => {
                                    return (
                                        <PartiallyOwnedInvestmentAccountRow
                                            key={partiallyOwnedInvestmentAccount.id}
                                            partiallyOwnedInvestmentAccount={partiallyOwnedInvestmentAccount}
                                            actionsDisabled={false}
                                            onClickEdit={NO_OP}
                                            onClickDelete={NO_OP}
                                            onClickViewHoldings={NO_OP}
                                            gridClassName={"current-net-worth-grid-with-actionmenu"}
                                            renderPartiallyOwnedInvestmentAccountDetails={(partiallyOwnedInvestmentAccountReport: PartiallyOwnedLegalAgreement) => {
                                                return <>
                                                    <TableCell
                                                        text={shortenName(partiallyOwnedInvestmentAccountReport.legalAgreementName)}
                                                        subtext={showOwnershipType(partiallyOwnedInvestmentAccountReport.ownershipCategory)}
                                                        className={`${investorGroup.partnerMember ? '' : 'grid-span-4'} textalign-left`}
                                                    />
                                                    {investorGroup.partnerMember && <>
                                                        <TableCell
                                                            text={getFormattedPresentValueForCurrentNetWorth(
                                                                calculateMemberOwnedValue(
                                                                    investorGroup.primaryMember.id,
                                                                    partiallyOwnedInvestmentAccountReport.memberOwnerships,
                                                                    partiallyOwnedInvestmentAccountReport.ownershipCategory,
                                                                    partiallyOwnedInvestmentAccountReport.marketEstateValue?.totalValue || 0))}
                                                            className="textalign-right"/>
                                                        <TableCell text={
                                                            getFormattedPresentValueForCurrentNetWorth(
                                                                calculateMemberOwnedValue(
                                                                    investorGroup.partnerMember.id,
                                                                    partiallyOwnedInvestmentAccountReport.memberOwnerships,
                                                                    partiallyOwnedInvestmentAccountReport.ownershipCategory,
                                                                    partiallyOwnedInvestmentAccountReport.marketEstateValue?.totalValue || 0))}
                                                                   className="textalign-right"/>
                                                        <TableCell
                                                            text={getFormattedPresentValueForCurrentNetWorth(
                                                                calculateJointlyOwnedValue(
                                                                    partiallyOwnedInvestmentAccountReport.ownershipCategory,
                                                                    partiallyOwnedInvestmentAccountReport.marketEstateValue?.totalValue || 0))}
                                                            className="textalign-right"/>
                                                    </>
                                                    }
                                                </>
                                            }}/>
                                    );
                                })}
                            </>
                        }
                    </div>

                </AccordionItemWithActionMenu>}
        </>
    );
}

export default AccountsCNWReport;
