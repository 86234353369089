import {HeldAwayAccount, HeldAwayAccountSummary} from "../../models/InvestmentProgram";
import moment from "moment";
import {DISPLAY_DATE_FORMAT} from "../../../constants/common";
import {TableCell} from "../../../components/Table/TableCell";
import {formatCurrency} from "../../../utils/format";
import React from "react";
import {Icon} from "../../../components";
import {shortenName} from "../../common/textUtils";

export const AssetSummaryHeldAwayAccountDetails = ({heldAwayAccount}: { heldAwayAccount: HeldAwayAccountSummary }) => {
    const presentableAsOfDate = heldAwayAccount.holdings.asOfDate ? `
    As of ${moment(heldAwayAccount.holdings.asOfDate).format(DISPLAY_DATE_FORMAT)}` : '--';

    const shouldShowAccountNumber = !!heldAwayAccount.institution && !!heldAwayAccount.financialAccountNumber && heldAwayAccount.financialAccountNumber.length > 3;
    const presentableAccountNumber = `(...${heldAwayAccount.financialAccountNumber?.substring(heldAwayAccount.financialAccountNumber.length - 4)})`;
    const investableValue = heldAwayAccount.holdings.totalInvestableValue ? heldAwayAccount.holdings.totalInvestableValue : 0;
    const heldAwayAccountText = (
        <div role="cell" style={{paddingLeft: "0px"}}>
            <div className="cell-with-popover">
                <div className="label-15px cell-text-content paddingright-sm">
                    <span>{shortenName(heldAwayAccount.financialAccountName)}</span>
                </div>
            </div>
        </div>
    )
    const heldAwayAccountSubtext = (
        <>
            {presentableAsOfDate}
            <Icon name="bank_transfer" className='paddingleft-md' ariaHidden={false}
                  ariaLabel="Held Away Accounts are Updated"/>
        </>
    );
    return <>
        <TableCell text={heldAwayAccountText} subtext={heldAwayAccountSubtext}/>
        <TableCell text={heldAwayAccount.institution} subtext={shouldShowAccountNumber ? presentableAccountNumber : undefined} textSize="small"/>
        <TableCell text={heldAwayAccount.entityType} subtext={heldAwayAccount.taxStatus} textSize="small"/>
        <TableCell text={formatCurrency(investableValue)} className="textalign-right"/>
    </>;
};