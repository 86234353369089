import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../store/store";
import {
    initialEstimatedImpact,
    initialWealthPOSummaryResponse,
    EstimatedImpact,
    WealthPOSummaryResponse, initialStateOfResidencyStrategy,
} from "./WealthPOTypes";

export type WealthPlanOptimizerState = {
    wealthPOSummaryResponse: WealthPOSummaryResponse;
    hasStrategies: boolean;
}

export const initialWealthPlanOptimizerState: WealthPlanOptimizerState = {
    wealthPOSummaryResponse: initialWealthPOSummaryResponse,
    hasStrategies: false,
}

const wealthPlanOptimizerSlice = createSlice({
    name: "wealthPlanOptimizer",
    initialState: initialWealthPlanOptimizerState,
    reducers: {
        setStateOfResidencyEstimatedImpact: (state, action: PayloadAction<EstimatedImpact>) => {
            state.wealthPOSummaryResponse.stateOfResidencyStrategy.estimatedImpact = action.payload;
        },
        setWealthPlanOptimizerState: (_state, action: PayloadAction<WealthPlanOptimizerState>) => action.payload,
        resetStateOfResidencyStrategy: (state) => {
            state.wealthPOSummaryResponse.stateOfResidencyStrategy = initialStateOfResidencyStrategy;
        },
        resetWealthPlanOptimizerState: () => ({...initialWealthPlanOptimizerState}),
    }
});

export const {
    setWealthPlanOptimizerState,
    setStateOfResidencyEstimatedImpact,
    resetWealthPlanOptimizerState,
} = wealthPlanOptimizerSlice.actions

export const selectStateOfResidencyEstimatedImpact = (state: RootState) => {
    return state.client.wealthPlanOptimizer.wealthPOSummaryResponse.stateOfResidencyStrategy.estimatedImpact;
}

export const selectHasStrategies = (state: RootState) => state.client.wealthPlanOptimizer.hasStrategies

export const selectEstimatedImpactSummary = (state: RootState) => state.client.wealthPlanOptimizer.wealthPOSummaryResponse.estimatedImpactSummary
export const selectFutureEstateValueWithStrategies = (state: RootState) => state.client.wealthPlanOptimizer.wealthPOSummaryResponse.futureEstateValueWithStrategies
export const selectFutureEstateValueWithoutStrategies = (state: RootState) => state.client.wealthPlanOptimizer.wealthPOSummaryResponse.futureEstateValueWithoutStrategies

export const selectHasStateOfResidencyStrategy = (state: RootState): boolean => {
    return state.client.wealthPlanOptimizer.wealthPOSummaryResponse.stateOfResidencyStrategy.id
        !== initialWealthPOSummaryResponse.stateOfResidencyStrategy.id
}

export const selectStateOfResidencyStrategy = (state: RootState) => state.client.wealthPlanOptimizer.wealthPOSummaryResponse.stateOfResidencyStrategy;

export const selectWealthPlanOptimizerState = (state: RootState) => state.client.wealthPlanOptimizer;

export default wealthPlanOptimizerSlice.reducer;