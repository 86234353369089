import React from "react";
import {StackedBarChart} from "../StackedBarChart";
import ScrollableContainer from "../ScrollableContainer/ScrollableContainer";

export type StackedBarChartData = {
    className: string;
    label: string;
    color: string;
    total: number;
    key?: string;
}

interface BarChartSidebarProps {
    displayName: string;
    title: string;
    noDataText: string;
    children: React.ReactNode;
    data: StackedBarChartData[];
    subtitle?: string;
}

// ANY CHANGES TO THIS COMPONENT MAY IMPACT MEETING MANAGEMENT POINTER FUNCTIONALITY.
// PLEASE VALIDATE POINTER FUNCTIONALITY AFTER ANY CHANGES
const BarChartSidebar = (props: BarChartSidebarProps) => {
    return (
        <section data-theme="aqua" data-testid="data-summary-sidebar">
            <ScrollableContainer id={'side-bar-scroll-container'}>
                <div className="sidebar" id={'bar-chart-sidebar'}>
                    <header id={'bar-chart-sidebar-header'} className="marginbottom-xxxl">
                        <b className="eyebrow-label">{props.displayName}</b>
                        <h1 className="margintop-sm">{props.title}</h1>
                        {props.subtitle && <span className="bar-chart-subtitle">{props.subtitle}</span>}
                    </header>
                    <StackedBarChart
                        id={'bar-chart-sidebar-stacked-bar-chart'}
                        noDataText={props.noDataText}
                        data={props.data}
                        enforceMinimumSize={true}
                    />
                    <footer id={'bar-chart-sidebar-footer'}>
                        {props.children}
                    </footer>
                </div>
            </ScrollableContainer>
        </section>
    )
}

export default BarChartSidebar;

