import {formatCurrency} from "../../../utils/format";
import {Accordion, AccordionItem, AlertBanner, BarChartSidebar} from "../../../components";
import React, {useEffect, useState} from "react";
import {AccordionItemListHeader} from "../../../components/Accordion";
import {GroupedTable, GroupedTableData} from "../../../components/Table/GroupedTable";
import classNames from "classnames";
import {selectReleaseToggles} from "src/ReleaseToggles/releaseTogglesSlice";
import {AccordionItemPresentValueHeader} from "../../../components/Accordion/AccordionItemPresentValueHeader";
import {
    EstateSummary,
    EstateType,
    MemberBeneficiary,
    PhilanthropicBeneficiary
} from "../../../WealthTransfer/models/api";
import {COLOR_ESTATE_BENEFICIARIES} from "../../../constants/colors";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {
    calculateSpaceAfterTaxes,
    createIndividualData,
    createPhilanthropicData,
    hasSufficientSpace,
    hasSufficientSpaceForOutEstate, mergeBeneficiaryAndOutEstatePages, mergeOutEstateLegalEntitiesAndNonClientPages,
    mergePhilanthropicAndIndividualPagesToEstatesummary, MINIMUM_SPACE_REQUIRED_FOR_EMPTY_STATE_TAX,
    MINIMUM_SPACE_REQUIRED_FOR_EMPTY_STATE_TAX_AND_FULL_FED_TAX, MINIMUM_SPACE_REQUIRED_FOR_FED_AND_STATE_TAX,
    PAGE_CONSTANTS,
    splitBeneficiaryData,
    splitNonClientMemberSummaries,
    splitOutOfEstateLegalEntities,
    WealthTransferData,
    WealthTransferOutOfEstateAssets
} from "./WealthTransferUtils";
import PrintViewWrapper from "../PrintViewWrapper";
import {mapEstateSummaryToGroupedTableData} from "../../../WealthTransfer/models/mappers";
import {
    createLegalEntityTypeSummaries,
    createNonClientMemberSummaries,
    LegalEntityTypeSummary,
    NonClientMemberSummary
} from "../../../Assets/mappers";
import {calculateOutOfEstateTotalValue} from "../../../Assets/AssetSummary/common/AssetSummaryCalculator";
import OutOfEstateLegalEntityReportView from "../AssetSummaryDetailReport/OutOfEstateLegalEntityReportView";
import OutOfEstateNonClientSummariesView from "../AssetSummaryDetailReport/OutOfEstateNonClientSummariesView";
import {setAccordionPreferencesState} from "../../../Assets/common/accordionPreferencesSlice";
import {LegalEntityReadModel} from "../../../Assets/models/Ownership";
import {AssetsSummary} from "../../../Assets/models/Assets";
import EstimatedTaxReport from "./EstimatedTaxReport";
import WealthTransferReportHeader from "./WealthTransferReportHeader";
import {NO_OP} from "../../../constants/common";

type WealthTransferReportViewPlanningProps = {
    profileName: string,
    estateSummary: EstateSummary,
    estateType: EstateType,
    displayName: string,
    clientAssets: AssetsSummary,
    legalEntities: LegalEntityReadModel[]
}

const WealthTransferReportView = ({
                                      profileName,
                                      estateSummary,
                                      estateType,
                                      displayName,
                                      clientAssets,
                                      legalEntities
                                  }: WealthTransferReportViewPlanningProps) => {
    const dispatch = useAppDispatch();

    const releaseToggles = useAppSelector(selectReleaseToggles);

    const doBeneficiariesExist = estateSummary.memberBeneficiaries.length > 0 || estateSummary.philanthropicBeneficiaries.length > 0;
    const stateEstateTaxDeduction = estateSummary.stateTaxSummary.estimatedEstateTax > 0 ? [{
        label: "State Estate Tax Deduction", amount: estateSummary.stateTaxSummary.estimatedEstateTax
    }] : [];

    const [beneficiaryPages, setBeneficiaryPages] = useState<GroupedTableData[][]>([])

    const [availableSpaceInLastPage, setAvailableSpaceInLastPage] = useState<number>(0)
    const [firstOutEstatePageIndex, setFirstOutEstatePageIndex] = useState<number>();
    const [legalEntityTypeSummaries, setLegalEntityTypeSummaries] = useState<LegalEntityTypeSummary[]>([]);
    const [nonClientMemberSummaries, setNonClientMemberSummaries] = useState<NonClientMemberSummary[]>([]);
    const [allItems, setAllItems] = useState<any[]>([]);
    const [beneficiaryAndOutEstatePages, setBeneficiaryAndOutEstatePages] = useState<WealthTransferData[]>([])

    const isStateTaxZero = estateSummary.stateTaxSummary.estimatedEstateTax === 0;
    const isSunset = estateType == EstateType.SUNSET;

    function generateOutOfEstatePages(remainingSpaceAfterTaxes: number) {
        let allOutOfEstateAssetsPages: WealthTransferOutOfEstateAssets[] = [];

        if (clientAssets) {
            // legal entities
            let tempLegalEntityTypeSummaries = createLegalEntityTypeSummaries('AssetSummary', clientAssets, legalEntities);
            const {
                legalEntityTypeSummariesArray,
                remainingSpaceAfterLegalEntities
            } = splitOutOfEstateLegalEntities(tempLegalEntityTypeSummaries, hasSufficientSpace(remainingSpaceAfterTaxes) ? remainingSpaceAfterTaxes : PAGE_CONSTANTS.TOTAL_PAGE_HEIGHT);
            const legalEntitySummariesPages: WealthTransferOutOfEstateAssets[] = [];
            legalEntityTypeSummariesArray.forEach((summary) => {
                legalEntitySummariesPages.push({
                    legalEntitySummary: summary,
                    nonClientMemberSummary: undefined
                })
            });
            const legalEntityNames = [...tempLegalEntityTypeSummaries.map((summary: { entityType: any; }) => summary.entityType)];
            setLegalEntityTypeSummaries(tempLegalEntityTypeSummaries)
            // non client
            let tempNonClientMemberSummaries = createNonClientMemberSummaries('AssetSummary', clientAssets);
            const nonClientMemberSummariesArray: Array<NonClientMemberSummary[]> = splitNonClientMemberSummaries(tempNonClientMemberSummaries, remainingSpaceAfterLegalEntities);

            const nonClientMemberSummariesPages: WealthTransferOutOfEstateAssets[] = [];
            nonClientMemberSummariesArray.forEach((summary) => {
                nonClientMemberSummariesPages.push({
                    legalEntitySummary: undefined,
                    nonClientMemberSummary: summary
                })
            })

            const nonClientNames = [
                ...tempNonClientMemberSummaries.map((summary: { memberName: any; }) => `${summary.memberName}-assets-summary`)
            ];
            setAllItems([...legalEntityNames, ...nonClientNames]);
            setNonClientMemberSummaries(tempNonClientMemberSummaries);
            //finally put legal and nonclient pages in outEstate pages

            allOutOfEstateAssetsPages = [...legalEntitySummariesPages, ...nonClientMemberSummariesPages];
            if (legalEntitySummariesPages.length > 0 && nonClientMemberSummariesPages.length > 0) {
                mergeOutEstateLegalEntitiesAndNonClientPages(legalEntitySummariesPages, nonClientMemberSummariesPages, allOutOfEstateAssetsPages);
            }
            expandNonClientAccordions(nonClientNames);
        }
        return allOutOfEstateAssetsPages;
    }

    function generateBeneficiaryPages(beneficiaryGroupedPages: GroupedTableData[][], isSunsetEstate: boolean) {
        let availableSpaceAfterSplittingBeneficiaries = 0;
        //split beneficiary data - first philanthropic , then individual
        // split philanthropic
        let philanthropicArray: Array<PhilanthropicBeneficiary[]> = [];
        let philanthropicPageData = splitBeneficiaryData(estateSummary.philanthropicBeneficiaries, philanthropicArray, 0, isSunsetEstate);
        //split individual
        let individualBeneficiaryArray: Array<MemberBeneficiary[]> = [];
        let individualPageData = splitBeneficiaryData(estateSummary.memberBeneficiaries, individualBeneficiaryArray, philanthropicPageData.remainingSpace, isSunsetEstate);
        //merge philanthropic and individual
        if (individualPageData.beneficiaryArrayPages.length > 0 && philanthropicPageData.beneficiaryArrayPages.length > 0) {
            beneficiaryGroupedPages = mergePhilanthropicAndIndividualPagesToEstatesummary(individualPageData.beneficiaryArrayPages, philanthropicPageData.beneficiaryArrayPages);
        } else {
            if (individualPageData.beneficiaryArrayPages.length > 0 && philanthropicPageData.beneficiaryArrayPages.length === 0) {
                individualPageData.beneficiaryArrayPages.forEach((element) => {
                    const individualGroupTableData = createIndividualData(element);
                    beneficiaryGroupedPages.push([individualGroupTableData]);
                })
            }
            if (philanthropicPageData.beneficiaryArrayPages.length > 0 && individualPageData.beneficiaryArrayPages.length === 0) {

                philanthropicPageData.beneficiaryArrayPages.forEach(
                    (element) => {
                        const philanthropicGroupTableData = createPhilanthropicData(element);
                        beneficiaryGroupedPages.push([philanthropicGroupTableData]);
                    }
                )
            }
            if (philanthropicPageData.beneficiaryArrayPages.length === 0 && individualPageData.beneficiaryArrayPages.length === 0) {
                const groupedTableData = mapEstateSummaryToGroupedTableData(estateSummary);
                beneficiaryGroupedPages.push(groupedTableData);
            }
        }
        availableSpaceAfterSplittingBeneficiaries = individualPageData.remainingSpace;
        setAvailableSpaceInLastPage(availableSpaceAfterSplittingBeneficiaries);

        if (estateSummary.unallocatedAmountToBeneficiaries > 0 && (individualPageData.beneficiaryArrayPages.length > 0 || philanthropicPageData.beneficiaryArrayPages.length > 0)) {
            const remainingAmountTableData = {
                groupName: "Remaining Amount",
                groupId: '',
                items: [],
                groupValue: estateSummary.unallocatedAmountToBeneficiaries,
            }
            beneficiaryGroupedPages[beneficiaryGroupedPages.length - 1].push(remainingAmountTableData);
            availableSpaceAfterSplittingBeneficiaries = availableSpaceAfterSplittingBeneficiaries - PAGE_CONSTANTS.TABLE_ROW_HEIGHT;
        }
        setBeneficiaryPages([...beneficiaryGroupedPages]);
        return {beneficiaryGroupedPages, availableSpaceAfterSplittingBeneficiaries};
    }

    useEffect(() => {
        let beneficiaryGroupedPages: GroupedTableData[][] = [];
        const beneficiaryPagesData = generateBeneficiaryPages(beneficiaryGroupedPages, isSunset);
        beneficiaryGroupedPages = beneficiaryPagesData.beneficiaryGroupedPages;

        const availableSpaceAfterSplittingBeneficiaries = beneficiaryPagesData.availableSpaceAfterSplittingBeneficiaries;
        const remainingSpaceAfterTaxes: number = calculateSpaceAfterTaxes(availableSpaceAfterSplittingBeneficiaries, isStateTaxZero);
        let allOutOfEstateAssetsPages: WealthTransferOutOfEstateAssets[];

        if (estateType === EstateType.CURRENT) {
            allOutOfEstateAssetsPages = generateOutOfEstatePages(remainingSpaceAfterTaxes);
        } else {
            allOutOfEstateAssetsPages = [];
        }

        let allPages: WealthTransferData[] = [];
        //loop and fill beneficiaryPages first
        beneficiaryGroupedPages.forEach(pg => {
            allPages.push({
                beneficiaryPageData: pg,
                outOfEstatePages: undefined
            })
        })
        // loop and fill out estate pages
        allOutOfEstateAssetsPages.forEach(pg => {
            allPages.push({
                beneficiaryPageData: undefined,
                outOfEstatePages: pg
            })
        })
        if (allOutOfEstateAssetsPages.length > 0 && beneficiaryGroupedPages.length > 0 && isStateTaxZero && hasSufficientSpaceForOutEstate(availableSpaceAfterSplittingBeneficiaries - MINIMUM_SPACE_REQUIRED_FOR_EMPTY_STATE_TAX_AND_FULL_FED_TAX)) {
            mergeBeneficiaryAndOutEstatePages(remainingSpaceAfterTaxes, allOutOfEstateAssetsPages, beneficiaryGroupedPages, allPages);
            setFirstOutEstatePageIndex(beneficiaryGroupedPages.length - 1)
        } else setFirstOutEstatePageIndex(beneficiaryGroupedPages.length)
        if (estateType === EstateType.FUTURE || estateType === EstateType.SUNSET) {
            if ((isStateTaxZero && availableSpaceAfterSplittingBeneficiaries < MINIMUM_SPACE_REQUIRED_FOR_EMPTY_STATE_TAX_AND_FULL_FED_TAX) ||
                (!isStateTaxZero && availableSpaceAfterSplittingBeneficiaries < MINIMUM_SPACE_REQUIRED_FOR_FED_AND_STATE_TAX)) {
                allPages.push({
                    beneficiaryPageData: undefined,
                    outOfEstatePages: {legalEntitySummary: undefined, nonClientMemberSummary: undefined}
                })
            }
        }
        setBeneficiaryAndOutEstatePages(allPages);
    }, []);

    const expandNonClientAccordions = (nonClientNamesToExpand: string[]) => {
        const nonClientAccordionNames = nonClientNamesToExpand.map((accordionName) => accordionName + "-report");
        dispatch(setAccordionPreferencesState({
            accordionId: "WealthTransferOutOfEstateNonClientAccordionReport",
            state: {
                expandedItems: [...nonClientAccordionNames]
            }
        }));
    }


    return <>
        {beneficiaryAndOutEstatePages.map((page, pageIndex) => (
            <PrintViewWrapper pageNumber={pageIndex} displayName={displayName} key={pageIndex}>
                <article className="wealth-summary-page layout-split-left">
                    <BarChartSidebar
                        data={[{
                            label: 'Beneficiaries',
                            className: '',
                            color: '#66AA93',
                            total: estateSummary.beneficiaryTotalValue,
                        }, {
                            label: 'Estimated Estate Tax',
                            className: '',
                            color: '#FFDF77',
                            total: estateSummary.estimatedEstateTax,
                        }]}
                        noDataText=""
                        displayName={profileName}
                        title={`Wealth Transfer${pageIndex > 0 ? ' (continued)' : ''}`}
                        subtitle='Explore opportunities to transfer your wealth, fulfill goals, and reduce your estate tax exposure.'>
                        <div>
                <span
                    className="condensed-subtitle margintop-xxxl">{estateType === EstateType.CURRENT ? "CURRENT ESTATE VALUE" : "FUTURE ESTATE VALUE"}</span>
                            <div className="h1 margintop-sm marginbottom-xxxl"
                                 aria-label={'Current Estate Value'}>{formatCurrency(estateSummary.estateValue)}</div>
                        </div>
                    </BarChartSidebar>
                    <section>
                        <div className="summary-page-content">
                            {pageIndex === 0 && <div className="section-header">
                                <WealthTransferReportHeader
                                    atAge={estateSummary.atAge}
                                    estateType={estateType}
                                    estateValue={estateSummary.estateValue}
                                    beneficiaryTotalValue={estateSummary.beneficiaryTotalValue}
                                    estimatedEstateTax={estateSummary.estimatedEstateTax}
                                    enableWealthTransferMenu={releaseToggles?.enableWealthTransferMenu ?? false}/>
                            </div>}
                            {estateType === EstateType.SUNSET && pageIndex === 0 && <AlertBanner
                                icon="info"
                                type="info"
                                showAlert={true}
                                message={"The exemption amount shown has been reduced by approximately 50% in accordance with the 2026 sunset exemption."}
                            />}
                            {page.beneficiaryPageData && <>
                                <AccordionItemListHeader
                                    testId={'wealth-transfer-accordion-header'}
                                    firstColumnTitle="Transferred To"
                                    lastColumnTitle={estateType === EstateType.CURRENT ? "Present Value" : "Future Value"}
                                    className={classNames('estate-summary-grid', 'estate-grid-table')}
                                    onExpandedChanged={NO_OP}/>

                                <Accordion expanded={['estate-summary-beneficiaries']}>
                                    <AccordionItem
                                        uuid="estate-summary-beneficiaries"
                                        primaryText={pageIndex > 0 ? "Beneficiaries (continued)" : "Beneficiaries"}
                                        accentColor={COLOR_ESTATE_BENEFICIARIES}
                                        rightAlignedContent={<AccordionItemPresentValueHeader
                                            value={estateSummary.beneficiaryTotalValue}/>}
                                        expandable={doBeneficiariesExist}>
                                        {doBeneficiariesExist && <GroupedTable
                                            columnHeaders={["Name", "Present Value"]}
                                            tableData={page.beneficiaryPageData!}/>}
                                    </AccordionItem>
                                </Accordion>
                            </>}

                            {(pageIndex === beneficiaryPages.length - 1) &&
                                <EstimatedTaxReport value={estateSummary.estimatedEstateTax}
                                                    showStateTax={availableSpaceInLastPage > MINIMUM_SPACE_REQUIRED_FOR_EMPTY_STATE_TAX}
                                                    showFedTax={availableSpaceInLastPage > MINIMUM_SPACE_REQUIRED_FOR_EMPTY_STATE_TAX_AND_FULL_FED_TAX}
                                                    estateSummary={estateSummary}
                                                    stateTaxSummary={estateSummary.stateTaxSummary}
                                                    federalTaxSummary={estateSummary.federalTaxSummary}
                                                    stateEstateTaxDeduction={stateEstateTaxDeduction}
                                                    showContinuedLabel={false}
                                />}

                            {(pageIndex === beneficiaryPages.length) &&
                                <EstimatedTaxReport value={estateSummary.estimatedEstateTax}
                                                    showStateTax={availableSpaceInLastPage < MINIMUM_SPACE_REQUIRED_FOR_EMPTY_STATE_TAX}
                                                    showFedTax={availableSpaceInLastPage < MINIMUM_SPACE_REQUIRED_FOR_EMPTY_STATE_TAX_AND_FULL_FED_TAX}
                                                    estateSummary={estateSummary}
                                                    stateTaxSummary={estateSummary.stateTaxSummary}
                                                    federalTaxSummary={estateSummary.federalTaxSummary}
                                                    stateEstateTaxDeduction={stateEstateTaxDeduction}
                                                    showContinuedLabel={true}/>
                            }
                            {page.outOfEstatePages &&
                                <div className="holistic-advice-summary-page">
                                    {

                                        (pageIndex === firstOutEstatePageIndex && (page.outOfEstatePages.legalEntitySummary || page.outOfEstatePages.nonClientMemberSummary)) &&
                                        <div className="section-header">
                                            <h3>
                                                Out of estate assets
                                                total <b>{formatCurrency(calculateOutOfEstateTotalValue(clientAssets)).trim()}</b>:
                                            </h3>
                                        </div>

                                    }
                                    {
                                        page.outOfEstatePages!.legalEntitySummary && <>
                                            <OutOfEstateLegalEntityReportView
                                                allItems={allItems}
                                                legalEntitiesSummariesForPage={page.outOfEstatePages!.legalEntitySummary}
                                                allLegalEntityTypeSummaries={legalEntityTypeSummaries}
                                                accordionIdForOutOfEstate={"WealthTransferOutOfEstateLegalDetailedReport"}
                                            />
                                        </>
                                    }
                                    {
                                        page.outOfEstatePages!.nonClientMemberSummary &&
                                        <OutOfEstateNonClientSummariesView
                                            allItems={allItems}
                                            nonClientMemberSummariesForPage={page.outOfEstatePages!.nonClientMemberSummary!}
                                            allNonClientMemberSummaries={nonClientMemberSummaries}
                                            accordionIdForView={"WealthTransferOutOfEstateNonClientAccordionReport"}
                                        />
                                    }
                                </div>}

                        </div>
                    </section>
                </article>
            </PrintViewWrapper>)
        )}
    </>

}

export default WealthTransferReportView;