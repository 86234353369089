import React, {useEffect, useMemo, useRef, useState} from "react"
import {wealthPOApiClient} from "../WealthPOApiClient";
import ScrollableContainer from "../../components/ScrollableContainer/ScrollableContainer";
import DataEntryHeader from "../../components/DataEntry/DataEntryHeader";
import {CurrencyInput, Icon, Input, RedAsterisk, RequiredFieldsSubheader, UnderlinedHeader} from "../../components";
import DataEntrySummary, {DataEntrySummaryItem} from "../../components/DataEntry/DataEntrySummary";
import {YearsInput} from "../../components/YearsInput/YearsInput";
import RangeInputs, {RangeInputProp} from "../../components/RangeInputs/RangeInputs";
import {InvestorGroupType} from "../../ClientManagement/models/InvestorGroupType";
import {clientManagementApiClient} from "../../ClientManagement/ClientManagementApiClient";
import {EstimatedImpact, GrowthRates, initialEstimatedImpact, StrategyTimeFrame, StrategyType} from "../WealthPOTypes";
import {formatCurrency} from "../../utils/format";
import {
    DateAndAgeValues,
    getDateAndAgeValues,
    PlanningPeriodValues,
    processInvestorGroupResponse,
    validateEndAge,
    validateEndYear,
    validateStartAge,
    validateStartYear,
    validateYearsOfFlow,
    validateYearsUntilFlow
} from "../WealthPOUtils";

export interface StrategyWithTimeFrameProps {
    profileId: string,
    header: string,
    strategyDetailsDescription: string
    getEstimatedImpact: (requestBody: StrategyTimeFrame, signal?: AbortSignal) => Promise<EstimatedImpact>
}

const enum InputTypes {
    YEARS_UNTIL_FLOW = "yearsUntilFlow",
    YEARS_OF_FLOW = "yearsOfFlow",
    STARTING_STRATEGY_YEAR = "startingStrategyYear",
    ENDING_STRATEGY_YEAR = "endingStrategyYear",
    STARTING_AGE = "startingClientAge",
    ENDING_AGE = "endingClientAge"
}

const StrategyWithTimeFrame: React.FC<StrategyWithTimeFrameProps> = ({
                                                                         profileId,
                                                                         header,
                                                                         strategyDetailsDescription,
                                                                         getEstimatedImpact
                                                                     }) => {
    const [annualFlow, setAnnualFlow] = useState<number>(0);
    const [annualFlowDisplayValue, setAnnualFlowDisplayValue] = useState<number>(0);
    const [riskAssetGrowthRate, setRiskAssetGrowthRate] = useState<string>("");
    const [yearsUntilFlow, setYearsUntilFlow] = useState<number>(0);
    const [yearsUntilFlowDisplayValue, setYearsUntilFlowDisplayValue] = useState<number>(0)
    const [yearsOfFlow, setYearsOfFlow] = useState<number>(1);
    const [yearsOfFlowDisplayValue, setYearsOfFlowDisplayValue] = useState<number>(1)
    const [startDate, setStartDate] = useState<string>("")
    const [endDate, setEndDate] = useState<string>("")
    const [startYearDisplayValue, setStartYearDisplayValue] = useState<number | string>("")
    const [endYearDisplayValue, setEndYearDisplayValue] = useState<number | string>("")
    const [startAgeDisplayValue, setStartAgeDisplayValue] = useState<number | string>("")
    const [endAgeDisplayValue, setEndAgeDisplayValue] = useState<number | string>("")
    const [planningPeriodValues, setPlanningPeriodValues] = useState<PlanningPeriodValues>({
        birthdate: "",
        firstName: "",
        planningPeriod: undefined
    })
    const [dateAndAgeValues, setDateAndAgeValues] = useState<DateAndAgeValues>({
        startDate: "",
        endDate: "",
        startYear: "",
        endYear: "",
        startAge: "",
        endAge: ""
    })
    const [estimatedImpact, setEstimatedImpact] = useState<EstimatedImpact>(initialEstimatedImpact)
    const [initialFormUpdated, setInitialFormUpdated] = useState<boolean>(false);
    const [errorFieldToRecalculateTimeframe, setErrorFieldToRecalculateTimeframe] = useState<InputTypes | undefined>(undefined);

    const estimatedImpactAbortControllerRef = useRef<AbortController | undefined>(undefined)

    const validateAndRecomputeValues = (changedInputField: InputTypes, inputValue: any, originalValue: number | string, setDisplayValue: (value: number) => void) => {
        setErrorFieldToRecalculateTimeframe(undefined)

        if (isNaN(parseInt(inputValue))) {
            setDisplayValue(Number(originalValue));
            return;
        }

        let newValue: number = Number(inputValue);

        let validationResponse = {
            errorPresent: false,
            newYearsUntilFlow: yearsUntilFlow,
            newYearsOfFlow: yearsOfFlow
        }

        switch (changedInputField) {
            case InputTypes.YEARS_UNTIL_FLOW:
                validationResponse = validateYearsUntilFlow(newValue, yearsUntilFlow, yearsOfFlow, planningPeriodValues);
                break;
            case InputTypes.YEARS_OF_FLOW:
                validationResponse = validateYearsOfFlow(newValue, yearsUntilFlow, yearsOfFlow, planningPeriodValues)
                break;
            case InputTypes.STARTING_STRATEGY_YEAR:
                validationResponse = validateStartYear(newValue, yearsUntilFlow, yearsOfFlow, planningPeriodValues, dateAndAgeValues)
                break;
            case InputTypes.ENDING_STRATEGY_YEAR:
                validationResponse = validateEndYear(newValue, yearsUntilFlow, yearsOfFlow, planningPeriodValues, dateAndAgeValues)
                break;
            case InputTypes.STARTING_AGE:
                validationResponse = validateStartAge(newValue, yearsUntilFlow, yearsOfFlow, planningPeriodValues, dateAndAgeValues)
                break;
            case InputTypes.ENDING_AGE:
                validationResponse = validateEndAge(newValue, yearsUntilFlow, yearsOfFlow, planningPeriodValues, dateAndAgeValues)
                break;
            default:
                setErrorFieldToRecalculateTimeframe(undefined)
                break;
        }

        if (validationResponse.errorPresent) {
            setErrorFieldToRecalculateTimeframe(changedInputField)

            if (changedInputField === InputTypes.YEARS_UNTIL_FLOW) {
                setDisplayValue(yearsUntilFlow);
            } else if (changedInputField === InputTypes.YEARS_OF_FLOW) {
                setDisplayValue(yearsOfFlow);
            } else {
                setDisplayAndRecomputedValues(dateAndAgeValues);
            }
        }

        if (validationResponse.newYearsUntilFlow !== yearsUntilFlow) {
            setYearsUntilFlowDisplayValue(validationResponse.newYearsUntilFlow)
            setYearsUntilFlow(validationResponse.newYearsUntilFlow)
            return;
        }

        if (validationResponse.newYearsOfFlow !== yearsOfFlow) {
            setYearsOfFlowDisplayValue(validationResponse.newYearsOfFlow)
            setYearsOfFlow(validationResponse.newYearsOfFlow)
            return;
        }
    }

    const setDisplayAndRecomputedValues = (dateAndAgeComputedValues: DateAndAgeValues) => {
        setStartDate(dateAndAgeComputedValues.startDate)
        setEndDate(dateAndAgeComputedValues.endDate)
        setStartYearDisplayValue(dateAndAgeComputedValues.startYear)
        setEndYearDisplayValue(dateAndAgeComputedValues.endYear)
        setStartAgeDisplayValue(dateAndAgeComputedValues.startAge)
        setEndAgeDisplayValue(dateAndAgeComputedValues.endAge)
        setDateAndAgeValues(dateAndAgeComputedValues);
    }

    const fetchReferenceDataAndInvestorGroup = async () => {
        const apiRequests: [Promise<GrowthRates>, Promise<InvestorGroupType>] = [
            wealthPOApiClient.getGrowthRatesReferenceData(profileId),
            clientManagementApiClient.getInvestorGroup(profileId)
        ]

        try {
            let [growthRates, investorGroup] = await Promise.all(apiRequests);

            const riskAssetRateDto = growthRates.rateDtos.find(rateDto => rateDto.id === "RiskAsset");
            if (riskAssetRateDto) {
                const growthRate = riskAssetRateDto.value * 100;
                const s = growthRate.toPrecision(3);
                setRiskAssetGrowthRate(s)
            }

            const {planningPeriodComputedValues, dateAndAgeComputedValues} = processInvestorGroupResponse(investorGroup, yearsUntilFlow, yearsOfFlow);

            setPlanningPeriodValues(planningPeriodComputedValues);
            setDisplayAndRecomputedValues(dateAndAgeComputedValues);
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        fetchReferenceDataAndInvestorGroup().then();
    }, [profileId])

    useEffect(() => {
        let dateAndAgeComputedValues: DateAndAgeValues = getDateAndAgeValues(planningPeriodValues.birthdate, yearsUntilFlow, yearsOfFlow);
        setDisplayAndRecomputedValues(dateAndAgeComputedValues);
    }, [yearsUntilFlow, yearsOfFlow])

    useEffect(() => {
        estimatedImpactAbortControllerRef.current = undefined;

        if (initialFormUpdated) {
            estimatedImpactAbortControllerRef.current = new AbortController();
            const {signal} = estimatedImpactAbortControllerRef.current;

            getEstimatedImpact({
                strategyId: undefined,
                strategyType: StrategyType.ANNUAL_GIFTING,
                annualFlow,
                yearsUntilFlow,
                yearsOfFlow
            }, signal)
                .then(estimatedImpactResponse => {
                    setEstimatedImpact(estimatedImpactResponse);
                });
        }

        return () => {
            if (estimatedImpactAbortControllerRef.current) {
                estimatedImpactAbortControllerRef.current.abort();
            }
        }
    }, [annualFlow, yearsOfFlow, yearsUntilFlow, initialFormUpdated])

    useEffect(() => {
        const timeout = setTimeout(() => {
            setErrorFieldToRecalculateTimeframe(undefined);
        }, 6000);
        return () => {
            clearTimeout(timeout);
        }
    }, [errorFieldToRecalculateTimeframe]);

    const memoizedErrorMessage = useMemo(() => {
        return errorFieldToRecalculateTimeframe && (
            <>
                <div></div>
                <div data-testid={`error-message-${errorFieldToRecalculateTimeframe}`} className="strategy-time-frame-error-msg">
                    Strategy Time Frame was adjusted to match Planning Period.
                </div>
            </>
        )
    }, [errorFieldToRecalculateTimeframe])

    const memoizedEstimatedImpact: DataEntrySummaryItem[] = useMemo(() => {
        return [
            {
                label: "Amount to Beneficiaries",
                value: (estimatedImpact.amountToBeneficiaries !== undefined) ? formatCurrency(estimatedImpact.amountToBeneficiaries) : undefined,
                testId: "amountToBeneficiaries",
            } as DataEntrySummaryItem,
            {
                label: "Est. Estate Tax",
                value: (estimatedImpact.impactToEstEstateTax !== undefined) ? formatCurrency(estimatedImpact.impactToEstEstateTax) : undefined,
                testId: "impactToEstEstateTax",
            } as DataEntrySummaryItem,
        ]
    }, [estimatedImpact]);

    const memoizedStrategySummary: DataEntrySummaryItem[] = useMemo(() => {
        return [
            {
                label: "Annual Flow",
                value: formatCurrency(annualFlow),
                testId: "strategySummaryAnnualFlow"
            } as DataEntrySummaryItem,
            {
                label: "Years until Flow",
                value: yearsUntilFlow,
                testId: "strategySummaryYearsUntilFlow"
            } as DataEntrySummaryItem, {
                label: "Years of Flow",
                value: yearsOfFlow,
                testId: "strategySummaryYearsOfFlow"
            } as DataEntrySummaryItem,

        ]
    }, [annualFlow, yearsUntilFlow, yearsOfFlow])

    return (
        <ScrollableContainer id={"add-edit-annual-gifting-scroll-container"} className="wealth-plan-optimizer">
            <DataEntryHeader title={header}
                             primaryButtonText={"SAVE"}
                             secondaryButtonText={"CANCEL"}
            />

            <div className="wealthpo-strategy__form">
                <article>
                    <section aria-label="Strategy Details">
                        <UnderlinedHeader
                            className={"wealthpo-strategy__underlined-header"}
                            primaryText="Strategy Details"
                            rightAlignedContent={<RequiredFieldsSubheader/>}
                        />
                        <div className="layout-data-entry-form__field">
                            <label className={"h5"} data-testid={'description'}>Description<RedAsterisk/></label>
                            <Input
                                name="descriptionField"
                                aria-label="description"
                                aria-labelledby="descriptionFieldInput-label"
                                id="descriptionFieldInput"
                                removeMarginTop
                                size="medium"
                                type="text"
                                value={strategyDetailsDescription}
                            />
                        </div>
                        <div className="layout-data-entry-form__field" style={{alignItems: "start"}}>
                            <label className={"h5"} data-testid={'annualFlow'}>Annual Flow</label>
                            <div className="annual-flow-input-field">
                                <CurrencyInput
                                    name="annualFlowField"
                                    aria-label="annualFlow"
                                    id="annualFlowFieldInput"
                                    size="medium"
                                    value={annualFlowDisplayValue}
                                    textAlign='left'
                                    onChangeValue={e => isNaN(parseInt(e.target.value)) ? setAnnualFlowDisplayValue(0) : setAnnualFlowDisplayValue(parseInt(e.target.value))}
                                    onBlur={() => {
                                        setAnnualFlow(annualFlowDisplayValue);
                                        if (!initialFormUpdated) {
                                            setInitialFormUpdated(true);
                                        }
                                    }}
                                />
                                <span
                                    className={"annual-flow-subheader"}>Maximum $18,000 per year per beneficiary</span>
                            </div>
                        </div>
                        <div className="layout-data-entry-form__field">
                            <label className={"h5"}>Growth Rate</label>
                            <div className="growth-rate-value">
                                <span aria-label={'growthRate'}>Risk Asset ({riskAssetGrowthRate}%)</span>
                            </div>
                        </div>
                    </section>

                    <section aria-label="Strategy Time Frame">
                        <UnderlinedHeader
                            className={"wealthpo-strategy__underlined-header"}
                            primaryText="Strategy Time Frame"
                            secondaryContent={
                                <div data-testid="strategyTimeFrameInfoIcon">
                                    <Icon className="info-icon popover-trigger popoverContainer" ariaHidden={false}
                                          name="status_info_outline" type="info"/>
                                </div>
                            }
                        />
                        <div className="layout-data-entry-form__field">
                            <label className={"h5"} data-testid={"yearsUntilFlow"}>Years until Flow</label>
                            <div className="display-flex" data-testid="strategyTimeFrameYearsUntilFlow">
                                <YearsInput style={{width: "134px"}}
                                            name={"yearsUntilFlow"}
                                            aria-label={"yearsUntilFlow"}
                                            className={"StrategyWithTimeFrameYearsUntilFlow"}
                                            value={yearsUntilFlowDisplayValue}
                                            size={"medium"}
                                            onBlur={() => {
                                                validateAndRecomputeValues(InputTypes.YEARS_UNTIL_FLOW, yearsUntilFlowDisplayValue, yearsUntilFlow, setYearsUntilFlowDisplayValue);
                                                if (!initialFormUpdated) {
                                                    setInitialFormUpdated(true);
                                                }
                                            }}
                                            readOnly={false}
                                            disabled={false}
                                            onChangeValue={(_e, value) => {
                                                setYearsUntilFlowDisplayValue(value)
                                            }}
                                />
                                <div className="strategyTimeFrameDate"
                                     data-testid="strategyTimeFrameYearsUntilFlowStartDate">
                                    <label>
                                        <b>Start Date</b>
                                    </label>
                                    <span role="startDate"
                                          aria-label="start-date">{startDate}</span>
                                </div>
                            </div>
                        </div>
                        {(errorFieldToRecalculateTimeframe && errorFieldToRecalculateTimeframe === InputTypes.YEARS_UNTIL_FLOW) && memoizedErrorMessage}
                        <div className="layout-data-entry-form__field">
                            <label className={"h5"} data-testid={"yearsOfFlow"}>Years of Flow</label>
                            <div className="display-flex" data-testid="strategyTimeFrameYearsOfFlow">
                                <YearsInput style={{width: "134px"}}
                                            name={"yearsOfFlow"}
                                            aria-label={"yearsOfFlow"}
                                            className={"StrategyWithTimeFrameYearsOfFlow"}
                                            value={yearsOfFlowDisplayValue}
                                            size={"medium"}
                                            onBlur={() => {
                                                validateAndRecomputeValues(InputTypes.YEARS_OF_FLOW, yearsOfFlowDisplayValue, yearsOfFlow, setYearsOfFlowDisplayValue);
                                                if (!initialFormUpdated) {
                                                    setInitialFormUpdated(true);
                                                }
                                            }}
                                            readOnly={false}
                                            disabled={false}
                                            onChangeValue={(_e, value) => {
                                                setYearsOfFlowDisplayValue(value)
                                            }}
                                />
                                <div className="strategyTimeFrameDate"
                                     data-testid="strategyTimeFrameYearsOfFlowEndDate">
                                    <label>
                                        <b>End Date</b>
                                    </label>
                                    <span role="endDate"
                                          aria-label="end-date">{endDate}</span>
                                </div>
                            </div>
                        </div>
                        {(errorFieldToRecalculateTimeframe && errorFieldToRecalculateTimeframe === InputTypes.YEARS_OF_FLOW) && memoizedErrorMessage}
                        <div className="layout-data-entry-form__field" data-testid="strategyTimeFrameStrategyYears">
                            <label className={"h5"}>Strategy Years</label>
                            <RangeInputs
                                left={{
                                    value: startYearDisplayValue,
                                    ariaLabel: "startYear",
                                    onChange: (e => setStartYearDisplayValue(isNaN(Number(e.target.value)) ? "" : Number(e.target.value))),
                                    readOnly: false,
                                    onBlur: (() => validateAndRecomputeValues(InputTypes.STARTING_STRATEGY_YEAR, startYearDisplayValue, dateAndAgeValues.startYear, setStartYearDisplayValue))
                                } as RangeInputProp}
                                right={{
                                    value: endYearDisplayValue,
                                    ariaLabel: "endYear",
                                    onChange: (e => setEndYearDisplayValue(isNaN(Number(e.target.value)) ? "" : Number(e.target.value))),
                                    readOnly: false,
                                    onBlur: (() => validateAndRecomputeValues(InputTypes.ENDING_STRATEGY_YEAR, endYearDisplayValue, dateAndAgeValues.endYear, setEndYearDisplayValue))
                                } as RangeInputProp}
                            />
                        </div>
                        {(errorFieldToRecalculateTimeframe && (errorFieldToRecalculateTimeframe === InputTypes.STARTING_STRATEGY_YEAR || errorFieldToRecalculateTimeframe === InputTypes.ENDING_STRATEGY_YEAR)) && memoizedErrorMessage}
                        <div className="layout-data-entry-form__field" data-testid="strategyTimeFrameAgeRange">
                            <label className={"h5"}>Client's Age Range</label>
                            <RangeInputs
                                left={{
                                    value: startAgeDisplayValue,
                                    ariaLabel: "startAge",
                                    onChange: (e => setStartAgeDisplayValue(isNaN(Number(e.target.value)) ? "" : Number(e.target.value))),
                                    readOnly: false,
                                    onBlur: (() => validateAndRecomputeValues(InputTypes.STARTING_AGE, startAgeDisplayValue, dateAndAgeValues.startAge, setStartAgeDisplayValue))
                                } as RangeInputProp}
                                right={{
                                    value: endAgeDisplayValue,
                                    ariaLabel: "endAge",
                                    onChange: (e => setEndAgeDisplayValue(isNaN(Number(e.target.value)) ? "" : Number(e.target.value))),
                                    readOnly: false,
                                    onBlur: (() => validateAndRecomputeValues(InputTypes.ENDING_AGE, endAgeDisplayValue, dateAndAgeValues.endAge, setEndAgeDisplayValue)
                                    )
                                } as RangeInputProp}
                            />
                        </div>
                        {(errorFieldToRecalculateTimeframe && (errorFieldToRecalculateTimeframe === InputTypes.STARTING_AGE || errorFieldToRecalculateTimeframe === InputTypes.ENDING_AGE)) && memoizedErrorMessage}
                        <div className="layout-data-entry-form__field" data-testid="strategyTimeFramePlanningPeriod">
                            <label className={"h5"}>Planning Period</label>
                            <span
                                className={errorFieldToRecalculateTimeframe && 'error-color'}
                                role="planningPeriod"
                                aria-label="planningPeriod">{`${planningPeriodValues.planningPeriod?.numberOfYears} years (${planningPeriodValues.firstName})`}</span>
                        </div>
                    </section>
                </article>

                <aside>
                    <div id="strategyWithTimeFrameStrategySummary">
                        <DataEntrySummary
                            title="Strategy Summary"
                            items={memoizedStrategySummary}
                        />
                    </div>
                    <div id="strategyWithTimeFrameEstimatedImpact" data-testid="estimatedImpactContainer">
                        <DataEntrySummary
                            title="Estimated Impact"
                            items={memoizedEstimatedImpact}
                        >
                            <div id="estimatedInfoTooltip">
                                <Icon name="status_info_outline" type="info" className='paddingright-sm'
                                      data-testid="estimatedImpactInfoIcon"/>
                                <div style={{fontStyle: "italic"}}>These values are estimates.</div>
                            </div>
                        </DataEntrySummary>
                    </div>
                </aside>
            </div>
        </ScrollableContainer>
    )
}

export default StrategyWithTimeFrame;