import React, {useContext, useEffect, useRef, useState} from "react";
import {LegalAgreement, LegalAgreementFormData} from "../models/InvestmentProgram";
import {assetsApiClient} from "../AssetsApiClient";
import {useHistory, useParams} from "react-router-dom";
import {RouteWithAssetId} from "../../routes/types";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {selectProfile} from "../../ClientManagement/ClientProfile/activeProfileSlice";
import LoadingIndicator from "../../pages/LoadingIndicator";
import {RequiredFieldsBanner} from '../../components';
import DataEntryHeader from "../../components/DataEntry/DataEntryHeader";
import {TaxDetailsType} from "../models/TaxDetails";
import {HoldingSummary} from "../models/Holding";
import {AccountSummary} from "../AccountsCommon/AccountSummary";
import DiscardAssetModal from "../DiscardAssetModal";
import deepEquals from "fast-deep-equal";
import {AssetClassifications} from "../models/AssetClassifications";
import {selectClientAssets, setActiveFormAsset} from "../clientAssetsSlice";
import AssetsViewContext from "../common/AssetsViewContext";
import {LegalAgreementForm} from "./LegalAgreementForm";
import {MemberGroup} from "../../ClientManagement/models/InvestorGroupType";
import {LegalEntityFormData, OwnershipDetailsFormData} from "../models/Ownership";
import {clientManagementApiClient} from "../../ClientManagement/ClientManagementApiClient";
import {isOwnershipPercentageNotEqual100} from "../Ownership/validation";
import {
    extractOwnershipDetailsFormData,
    mapToOwnershipDetailsFormData,
    mapToOwnershipWriteModel
} from "../Ownership/mappers";
import {HistoryBlockModal} from "../../components/HistoryBlockModal/HistoryBlockModal";
import {
    getTaxableLiabilityPaidByPortfolioForDeferredAccounts,
    getTaxableLiabilityPaidByPortfolioForTaxableAccounts
} from "../formHelpers";

export default function EditLegalAgreement(props: { investmentProgramName: string | null }) {
    const history = useHistory();
    const viewType = useContext(AssetsViewContext);
    const profile = useAppSelector(selectProfile);
    const [isSaveButtonDisabled, updateSaveButtonDisabled] = useState(false);
    const [legalAgreement, setLegalAgreement] = useState<LegalAgreement>();
    const [initialLegalAgreement, setInitialLegalAgreement] = useState<LegalAgreement>();
    const [domesticTrustHoldings, setDomesticTrustHoldings] = useState<HoldingSummary>();
    const [taxDetails, setTaxDetails] = useState<TaxDetailsType>();
    const [initialTaxDetails, setInitialTaxDetails] = useState<TaxDetailsType>();
    const {assetId: legalAgreementId} = useParams<RouteWithAssetId>();
    const [unrealizedCapitalGainsTax, updateUnrealizedCapitalGainsTax] = useState<number | null>(null);
    const [deferredTaxLiability, updateDeferredTaxLiability] = useState<number | null>(null);
    const [totalInvestablePresentValue, updateTotalInvestablePresentValue] = useState<number | null | undefined>(null);
    const [showDiscardChangesModal, setShowDiscardChangesModal] = useState(false);
    const [isRequiredFieldsBannerShown, setRequiredFieldsBannerShown] = useState(false);
    const [memberGroup, setMemberGroup] = useState<MemberGroup>();
    const [legalEntities, updateLegalEntities] = useState<LegalEntityFormData[]>();
    const [ownershipDetailsFormData, updateOwnershipDetailsFormData] = useState<OwnershipDetailsFormData>();
    const [initialOwnershipDetailsFormData, setInitialOwnershipDetailsFormData] = useState<OwnershipDetailsFormData>();
    const [isOwnershipPercentageErrorBannerShown, setOwnershipPercentageErrorBannerShown] = useState(false);
    const [showNavigationModal, setShowNavigationModal] = useState(true);
    const [classifications, setClassifications] = useState<AssetClassifications>();
    const {accounts, partiallyOwnedLegalAgreements, investmentProgram} = useAppSelector(selectClientAssets)!;
    const taxableLiabilityPaidByPortfolio = getTaxableLiabilityPaidByPortfolioForTaxableAccounts(accounts, investmentProgram, partiallyOwnedLegalAgreements)!;
    const deferredLiabilityPaidByPortfolio = getTaxableLiabilityPaidByPortfolioForDeferredAccounts(accounts, investmentProgram, partiallyOwnedLegalAgreements)!;

    const dispatch = useAppDispatch();
    const mounted = useRef(false);

    useEffect(() => {
        Promise.all([
            assetsApiClient.getDomesticTrustHoldings(profile.id, legalAgreementId, "IP"),
            assetsApiClient.getLegalAgreement(profile.id, legalAgreementId),
            clientManagementApiClient.getMemberGroup(profile.id),
            assetsApiClient.getLegalEntities(profile.id)
        ]).then(([holdingsResponse, legalAgreementResponse, memberGroupResponse, legalEntitiesResponse]) => {
            setDomesticTrustHoldings(holdingsResponse);
            updateUnrealizedCapitalGainsTax(holdingsResponse.unrealizedCapitalGainsTax);
            updateDeferredTaxLiability(holdingsResponse.deferredTaxLiability);
            updateTotalInvestablePresentValue(holdingsResponse.totalInvestablePresentValue);
            setLegalAgreement(legalAgreementResponse);
            setInitialLegalAgreement(legalAgreementResponse);
            updateOwnershipDetailsFormData(extractOwnershipDetailsFormData(
                mapToFormData(legalAgreementResponse)));
            setInitialOwnershipDetailsFormData(extractOwnershipDetailsFormData(
                mapToFormData(legalAgreementResponse)));
            setMemberGroup(memberGroupResponse);
            updateLegalEntities(legalEntitiesResponse)
            var liabilityPaidByPortfolio = null;
            if (legalAgreementResponse.taxStatus === 'Taxable') {
                liabilityPaidByPortfolio = taxableLiabilityPaidByPortfolio;
            } else if (legalAgreementResponse.taxStatus === 'Deferred') {
                liabilityPaidByPortfolio = deferredLiabilityPaidByPortfolio;
            }
            setTaxDetails({
                isEntityCapitalGains: legalAgreementResponse.isEntityCapitalGains,
                isLiabilityPaidByPortfolio: liabilityPaidByPortfolio,
            });
            setInitialTaxDetails({
                isEntityCapitalGains: legalAgreementResponse.isEntityCapitalGains,
                isLiabilityPaidByPortfolio: liabilityPaidByPortfolio,
            });
            dispatch(setActiveFormAsset({
                assetType: 'investmentProgram',
                id: legalAgreementResponse.id || undefined,
                inEstateValue: legalAgreementResponse.marketValue || 0,
                description: legalAgreementResponse.name,
                hasInEstateOwnership: true,
            }));
        }).catch(error => console.error('Could not fetch holding data', error.message));
    }, [profile.id, legalAgreementId]);

    useEffect(() => {
        setRequiredFieldsBannerShown(isRequiredFieldsBannerShown && isAnyRequiredFieldEmpty());
    }, [taxDetails, legalAgreement?.name, ownershipDetailsFormData?.legalEntityOwnerships]);

    useEffect(() => {
        setOwnershipPercentageErrorBannerShown(isOwnershipPercentageErrorBannerShown &&
            isOwnershipPercentageNotEqual100(ownershipDetailsFormData!));
    }, [ownershipDetailsFormData?.legalEntityOwnerships, ownershipDetailsFormData?.memberOwnerships]);

    useEffect(() => {
        assetsApiClient.getAssetClassifications().then(
            (assetClassificationResponse) => {
                setClassifications(assetClassificationResponse);
            }
        )
    }, [])

    useEffect(() => {
        mounted.current = true;
        return () => {
            mounted.current = false;
            dispatch(setActiveFormAsset(null));
        }
    }, []);

    const isAnyRequiredFieldEmpty = () => {
        const isNameBlank = !legalAgreement?.name.trim();
        const isLiabilityQuestionVisibleForTaxable = legalAgreement!.taxStatus === "Taxable" && !!taxDetails!.isEntityCapitalGains;
        const isLiabilityQuestionVisibleForDeferred = legalAgreement!.taxStatus === "Deferred";
        const isLiabilityQuestionVisible = isLiabilityQuestionVisibleForTaxable || isLiabilityQuestionVisibleForDeferred;
        const isLiabilityQuestionBlank = taxDetails!.isLiabilityPaidByPortfolio === null;

        const isOwnershipDataMissing = ownershipDetailsFormData?.legalEntityOwnerships.some((ownership) => {
            return !ownership.name.trim() || !ownership.type;
        });

        return isNameBlank || isOwnershipDataMissing || (isLiabilityQuestionBlank && isLiabilityQuestionVisible);
    }

    const onOwnershipFormChange = (ownershipFormData: OwnershipDetailsFormData) => {
        updateOwnershipDetailsFormData(ownershipFormData);
    }

    const handleSave = async () => {
        const {isValid} = validateForm();
        if (isValid) {
            updateSaveButtonDisabled(true);
            setShowNavigationModal(false);
            const response = await assetsApiClient.putLegalAgreement(profile.id, legalAgreementId, {
                name: legalAgreement!.name,
                isEntityCapitalGains: taxDetails!.isEntityCapitalGains,
                isLiabilityPaidByPortfolio: taxDetails!.isLiabilityPaidByPortfolio,
                ...mapToOwnershipWriteModel(ownershipDetailsFormData!)
            });
            if (response.status === 200) {
                navigateToAssetsView();
            }
            return true;
        }
        return false;
    }


    const navigateToAssetsView = () => {
        history.push(`/Profile/${profile.id}/ClientProfile/${viewType}`);
    }

    const isFormChanged = () => {
        const updated = {
            ...legalAgreement,
            ...taxDetails,
            ...ownershipDetailsFormData
        };
        const initial = {
            ...initialLegalAgreement,
            ...initialTaxDetails,
            ...initialOwnershipDetailsFormData
        }
        return !deepEquals(initial, updated);
    }

    const handleCancel = () => {
        if (isFormChanged()) {
            setShowDiscardChangesModal(true);
            setShowNavigationModal(false);
            return;
        }
        navigateToAssetsView();
    }

    const handleUnrealizedCapitalGainsTaxChange = async (isEntityCapitalGains: boolean) => {
        updateUnrealizedCapitalGainsTax(await assetsApiClient.getUnrealizedCapitalGainsTaxLegalAgreement(
            profile.id,
            legalAgreementId,
            isEntityCapitalGains
        ));
    }

    const handleClickViewHoldings = async () => {
        const {isValid} = validateForm();
        if (isValid) {
            setShowNavigationModal(false);
            const response = await assetsApiClient.putLegalAgreement(profile.id, legalAgreementId, {
                name: legalAgreement!.name,
                isEntityCapitalGains: taxDetails!.isEntityCapitalGains,
                isLiabilityPaidByPortfolio: taxDetails!.isLiabilityPaidByPortfolio,
                ...mapToOwnershipWriteModel(ownershipDetailsFormData!)
            });

            if (response.status === 200) {
                history.push(`/Profile/${profile.id}/ClientProfile/${viewType}/LegalAgreementHoldings/${legalAgreementId}`);
            }
        }
    };

    const validateForm = () => {
        const isRequiredFieldEmpty = isAnyRequiredFieldEmpty();
        if (isRequiredFieldEmpty) {
            setRequiredFieldsBannerShown(true);
        }

        const isOwnershipPercentageInvalid = isOwnershipPercentageNotEqual100(ownershipDetailsFormData!);
        if (isOwnershipPercentageInvalid) {
            setOwnershipPercentageErrorBannerShown(true);
        }

        return {
            isValid: !isRequiredFieldEmpty && !isOwnershipPercentageInvalid
        };
    };

    const getInvestablePresentValue = async (liabilityPaidByPortfolio: boolean) => {
        if (liabilityPaidByPortfolio && legalAgreement?.id) {
            let totalInvestablePresentValueResponse = await assetsApiClient.getInvestablePresentValueForLegalAgreements(
                profile.id, legalAgreement.id);
            if (mounted.current) updateTotalInvestablePresentValue(totalInvestablePresentValueResponse);
        } else {
            updateTotalInvestablePresentValue(null);
        }
    }

    if (!(domesticTrustHoldings
        && legalAgreement
        && ownershipDetailsFormData
        && taxDetails
        && classifications
        && memberGroup
        && legalEntities)) {
        return <LoadingIndicator/>;
    }

    return (
        <div className="legal-agreement asset-form">
            <div className="layout-data-entry-form">
                <HistoryBlockModal
                    when={isFormChanged() && showNavigationModal}
                    itemType={'page'}
                    onSave={handleSave}
                />
                <DataEntryHeader
                    className='dataEntryHeader'
                    title={`Edit ${legalAgreement.name}`}
                    onPrimaryButtonClick={handleSave}
                    onSecondaryButtonClick={handleCancel}
                    disablePrimaryButton={isSaveButtonDisabled}
                    primaryButtonText="Save"
                    secondaryButtonText="Cancel"
                />
                <RequiredFieldsBanner showAlert={isRequiredFieldsBannerShown} itemType="legal agreement"/>
                <div className="legal-agreement__form layout-data-entry-form">
                    <LegalAgreementForm
                        legalAgreement={legalAgreement}
                        ownershipDetailsFormData={ownershipDetailsFormData}
                        isRequiredFieldsBannerShown={isRequiredFieldsBannerShown}
                        isOwnershipPercentageErrorBannerShown={isOwnershipPercentageErrorBannerShown}
                        handleLegalAgreementChange={setLegalAgreement}
                        investmentProgramName={props.investmentProgramName}
                        taxDetails={taxDetails}
                        handleTaxDetailsChange={setTaxDetails}
                        unrealizedCapitalGainsTax={unrealizedCapitalGainsTax}
                        deferredTaxLiability={deferredTaxLiability}
                        totalInvestablePresentValue={totalInvestablePresentValue}
                        getInvestablePresentValue={getInvestablePresentValue}
                        handleUnrealizedCapitalGainsTaxChange={handleUnrealizedCapitalGainsTaxChange}
                        memberGroup={memberGroup}
                        legalEntities={legalEntities}
                        handleLegalEntitiesChange={updateLegalEntities}
                        onOwnershipFormChange={onOwnershipFormChange}
                    />
                    <AccountSummary
                        assetType={'investmentProgram'}
                        holdings={domesticTrustHoldings}
                        unrealizedCapitalGainsTax={unrealizedCapitalGainsTax}
                        deferredTaxLiability={deferredTaxLiability}
                        onClick={handleClickViewHoldings}
                        classifications={classifications}
                    />
                </div>
            </div>
            <DiscardAssetModal
                isOpen={showDiscardChangesModal}
                title={`Discard changes to this Asset?`}
                content={`Any data entered for this asset will not be saved.`}
                onClickKeepEditing={() => setShowDiscardChangesModal(false)}
                onClickDiscardChanges={navigateToAssetsView}
            />
        </div>
    );
}


function mapToFormData(legalAgreement: LegalAgreement): LegalAgreementFormData {
    return {
        ...legalAgreement,
        ...mapToOwnershipDetailsFormData(legalAgreement),
    };
}
