import React, {useEffect, useState} from 'react';
import {useHistory, useLocation, useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {goalsApiClient} from "../GoalsApiClient";
import {
    emptyLifestyleSpendingPeriod,
    LifestyleSpendingGoal,
    LifestyleSpendingGoalResponse,
    LifestyleSpendingPeriodInputs,
} from '../models/LifestyleSpendingGoal';
import {AlertBanner} from "../../components"
import {HistoryBlockModal} from "../../components/HistoryBlockModal/HistoryBlockModal";
import DiscardModal from "../../components/DiscardModal/DiscardModal";
import LifestyleFormBarChartSideBar from "../BarChartSidebar/LifestyleFormBarChartSideBar";
import {selectGoalModel, updateLifestyleSpendingGoal} from "../controller/GoalsModelSlice";
import {GoalModelType} from "../models/GoalModelType";
import LifestyleSpendingLivePreview from "./LifestyleSpendingLivePreview";
import DataEntryHeader from "../../components/DataEntry/DataEntryHeader";
import LifestyleSpendingReserveInformation from "./LifestyleSpendingReserveInformation";
import {LifestyleGoalFunding} from "./LifestyleGoalFunding";
import {selectReleaseToggles} from 'src/ReleaseToggles/releaseTogglesSlice';
import LifestylePlanningPeriod from "../../components/PlanningPeriod/LifestylePlanningPeriod";
import {isInvestablySufficient} from "../../Portfolio/portfolioUtils";
import {RouteWithId} from "../../routes/types";
import {emptyPortfolioReserveResponse, PortfolioReserveResponse} from "../models/PortfolioReserve";
import LoadingIndicator from "../../pages/LoadingIndicator";
import RefreshContingencyModal from "../components/RefreshContingencyModal";
import {LifestyleSpendingPeriods} from "./LifestyleSpendingPeriods";
import useProfileEditableState from "../../hooks/useProfileEditableState";
import ScrollableContainer from 'src/components/ScrollableContainer/ScrollableContainer';

const LifestyleSpendingForm = ({isEditing = false}: { isEditing?: boolean }) => {
    const history = useHistory();
    const location = useLocation();
    const viewType = location.state == undefined ? "Summary" : location.state;
    const dispatch = useAppDispatch();
    const {id: proposalId} = useParams<RouteWithId>();
    const goalModel = useAppSelector<GoalModelType>(selectGoalModel);
    const {
        proposal,
        lifestyleSpendingGoal: savedLifestyleGoal,
        investorGroup,
        discountRateSelection,
        assets
    } = goalModel;

    const releaseToggles = useAppSelector(selectReleaseToggles);
    const {enableMultipleSpendingPeriods} = releaseToggles!;
    const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
    const [showFundedByNonInvestableModal, setShowFundedByNonInvestableModal] = useState<boolean>(false);
    const [originalGoalState] = useState(savedLifestyleGoal);
    const [lifestyleSpendingGoal, setLifestyleSpendingGoal] = useState<LifestyleSpendingGoal>(savedLifestyleGoal)
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

    const {isProfileWithProposalsOrArchived} = useProfileEditableState();

    const isSufficient = isInvestablySufficient({
        ...goalModel,
        totalInvestableValue: assets.totalInvestableValue,
        lifestylePresentValue: lifestyleSpendingGoal.calculatedFields
    });
    const reserveTargetIsSet = (proposal.portfolioReserveTargetLength !== undefined && proposal.portfolioReserveTargetLength !== null)
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [portfolioReserveResponse, setPortfolioReserveResponse] = useState<PortfolioReserveResponse>(emptyPortfolioReserveResponse)
    let ifNoInvAssetsButInflowsAreAlignedToLS = !assets.totalInvestableValue && lifestyleSpendingGoal.calculatedFields.totalAlignedInflowPresentValue > 0;
    useEffect(() => {
        //load PR into page
        fetchPortfolioReserve().then(responsePR => {
            setPortfolioReserveResponse(responsePR)
            setIsLoading(false);
        })
    }, [])

    const fetchPortfolioReserve = async (): Promise<PortfolioReserveResponse> => {
        let portfolioReserve: PortfolioReserveResponse = emptyPortfolioReserveResponse;
        if ((assets.totalInvestableValue > 0 || ifNoInvAssetsButInflowsAreAlignedToLS) && reserveTargetIsSet) {
            portfolioReserve = await goalsApiClient.getPortfolioReserve(proposalId,
                {
                    selectedReserveTarget: String(proposal.portfolioReserveTargetLength)
                }
            );
        }
        return portfolioReserve;
    }

    const handleModalDiscardButton = () => {
        setShowCancelModal(false);
        dispatch(updateLifestyleSpendingGoal(originalGoalState));
        history.push(`/Profile/${proposalId}/ClientProfile/Goals/${viewType}`, {forceNavigate: true});
    };

    const updatePresentValue = async (lifestyleGoal: LifestyleSpendingGoal): Promise<LifestyleSpendingGoal> => {
        const lifestylePresentValueResponse = await goalsApiClient.postLifestylePresentValueWithMultipleLSPeriods(proposalId, lifestyleGoal.userInputs)
        return {
            ...lifestyleGoal,
            userInputs: {
                ...lifestyleGoal.userInputs,
                totalFundedByNonInvestableAssetsAmount: lifestylePresentValueResponse.presentValueForInsufficientYears
            },
            calculatedFields: lifestylePresentValueResponse
        }
    }

    const saveLifestyleGoal = (lifestyleGoal: LifestyleSpendingGoal): Promise<LifestyleSpendingGoalResponse> => {
        return goalsApiClient.postLifeStyleGoal(
            proposalId,
            {
                id: lifestyleGoal.id,
                userInputs: {...lifestyleGoal.userInputs},
                lengthOfPlanningPeriod: investorGroup.planningPeriod.numberOfYears
            },
        )
    }

    const handleSaveChanges = async () => {
        const savedGoalWithId = await saveLifestyleGoal(lifestyleSpendingGoal)
        dispatch(updateLifestyleSpendingGoal(savedGoalWithId))
    };

    const handleSaveButtonClick = async () => {
        if (lifestyleSpendingGoal != savedLifestyleGoal && proposal.isActivePortfolioReserve && lifestyleSpendingGoal.userInputs.isFundedByNonInvestableAssets) {
            setShowFundedByNonInvestableModal(true)
        } else if (lifestyleSpendingGoal != savedLifestyleGoal) {
            await handleSaveChanges()
            history.push(`/Profile/${proposalId}/ClientProfile/Goals/${viewType}`, {forceNavigate: true})
        } else {
            history.push(`/Profile/${proposalId}/ClientProfile/Goals/${viewType}`, {forceNavigate: true})
        }

    };

    const handleCancelButtonClick = () => {
        if (!hasUnsavedChanges || isProfileWithProposalsOrArchived) {
            history.push(`/Profile/${proposalId}/ClientProfile/Goals/${viewType}`, {forceNavigate: true});
        } else {
            setShowCancelModal(true);
        }
    }

    const toggleFundedByNonInvestables = async (newValue: boolean) => {
        const newGoal: LifestyleSpendingGoal = {
            ...lifestyleSpendingGoal,
            userInputs: {
                ...lifestyleSpendingGoal.userInputs,
                isFundedByNonInvestableAssets: newValue,
                totalFundedByNonInvestableAssetsAmount: null
            }
        }
        const response: LifestyleSpendingGoal = (await updatePresentValue(newGoal))
        response.userInputs.totalFundedByNonInvestableAssetsAmount =
            (proposal.isActivePortfolioReserve && response.userInputs.isFundedByNonInvestableAssets) ?
                response.calculatedFields.presentValueForInsufficientYears : null
        setLifestyleSpendingGoal(response);
    }

    const handleAnnualSpendBlur = async () => {
        if (lifestyleSpendingGoal.userInputs.lifestyleSpendingPeriods[0].annualSpend >= 0 && !hasPlanningPeriodError) {
            setLifestyleSpendingGoal(await updatePresentValue({
                ...lifestyleSpendingGoal,
                userInputs: {...lifestyleSpendingGoal.userInputs, totalFundedByNonInvestableAssetsAmount: null}
            }));
            setHasUnsavedChanges(true);
        }
    }

    const handleLengthOfSpendBlur = async () => {
        if (!hasPlanningPeriodError) {
            setLifestyleSpendingGoal(await updatePresentValue({
                ...lifestyleSpendingGoal,
                userInputs: {...lifestyleSpendingGoal.userInputs, totalFundedByNonInvestableAssetsAmount: null}
            }));
            setHasUnsavedChanges(true);
        }
    }


    const handleRefreshContingency = async () => {
        await handleSaveChanges()
        setShowFundedByNonInvestableModal(false)
        history.push(`/Profile/${proposalId}/ClientProfile/Goals/${viewType}`, {forceNavigate: true})
    }

    const handleTurningOffContingency = async () => {
        const newGoal: LifestyleSpendingGoal = {
            ...lifestyleSpendingGoal,
            userInputs: {
                ...lifestyleSpendingGoal.userInputs,
                isFundedByNonInvestableAssets: false,
                totalFundedByNonInvestableAssetsAmount: null
            }
        }
        setLifestyleSpendingGoal(newGoal)
        const savedGoalWithId = await saveLifestyleGoal(newGoal)
        dispatch(updateLifestyleSpendingGoal(savedGoalWithId))
        history.push(`/Profile/${proposalId}/ClientProfile/Goals/${viewType}`, {forceNavigate: true})
    }

    const getTotalLifestyleSpendingYears = () => {
        if (lifestyleSpendingGoal.userInputs.lifestyleSpendingPeriods.length > 1) {
            return lifestyleSpendingGoal
                .userInputs.lifestyleSpendingPeriods
                .map((period) => period.endYear - period.startYear)
                .reduce((previousValue, currentValue) => previousValue + currentValue, 0);
        }

        return investorGroup.planningPeriod.numberOfYears;
    }
    const totalLifestyleSpendingYears = getTotalLifestyleSpendingYears();

    const hasPlanningPeriodError = totalLifestyleSpendingYears !== investorGroup.planningPeriod.numberOfYears;

    const handleUpdateLifestyleSpendingPeriods = async (newSpendingPeriods: LifestyleSpendingPeriodInputs[]) => {
        const updatedGoal = {
            ...lifestyleSpendingGoal,
            userInputs: {
                ...lifestyleSpendingGoal.userInputs,
                lifestyleSpendingPeriods: newSpendingPeriods,
            }
        }
        setLifestyleSpendingGoal(updatedGoal);
        setHasUnsavedChanges(true);
    }

    const showGoalFundingSection = !isSufficient;

    const addNewSpendingPeriod = async () => {
        const numberOfSpendingPeriods = lifestyleSpendingGoal.userInputs.lifestyleSpendingPeriods.length
        const planningPeriodEndYear = investorGroup.planningPeriod.numberOfYears + investorGroup.planningPeriod.startYear
        const newSpendingPeriod = {
            ...emptyLifestyleSpendingPeriod(planningPeriodEndYear, planningPeriodEndYear),
            description: "Lifestyle Spending Period " + (numberOfSpendingPeriods + 1),
            startYear: lifestyleSpendingGoal.userInputs.lifestyleSpendingPeriods[numberOfSpendingPeriods - 1].endYear,
            endYear: lifestyleSpendingGoal.userInputs.lifestyleSpendingPeriods[numberOfSpendingPeriods - 1].endYear
        }
        let singleSpend = lifestyleSpendingGoal.userInputs.lifestyleSpendingPeriods.map(
            e => e.description == "Lifestyle Spending Period" ?
                {...e, description: "Lifestyle Spending Period " + numberOfSpendingPeriods}
                : e)
        await handleUpdateLifestyleSpendingPeriods([...singleSpend,
            newSpendingPeriod])
    }

    if (isLoading) {
        return <LoadingIndicator/>
    }

    return (
        <div className="layout-split-sidebar">
            <DiscardModal
                itemType={"Goal"}
                isOpen={showCancelModal}
                onClickKeepEditing={() => setShowCancelModal(false)}
                onClickDiscardChanges={handleModalDiscardButton}
                isEditing={isEditing}/>
            <HistoryBlockModal
                when={
                    JSON.stringify(originalGoalState) !== JSON.stringify(lifestyleSpendingGoal)
                }
                itemType="goal"
                onSave={handleSaveChanges}
                saveEnabled={!hasPlanningPeriodError}
            />
            <RefreshContingencyModal
                isOpen={showFundedByNonInvestableModal}
                onRefreshContingency={handleRefreshContingency}
                onTurningOffContingency={handleTurningOffContingency}
                closeHandler={() => setShowFundedByNonInvestableModal(false)}
            />
            <LifestyleFormBarChartSideBar lifestyleSpendingGoal={lifestyleSpendingGoal}/>
            <ScrollableContainer id={'add-edit-lifestyle-spending-scroll-container'}>
                <div className='lifestyle-spending layout-data-entry-form'>
                    <DataEntryHeader
                        className='data-entry-header'
                        title={isEditing ? 'Edit Lifestyle Spending' : 'Add Lifestyle Spending'}
                        SubtitleComponent={() => <LifestylePlanningPeriod/>}
                        onPrimaryButtonClick={handleSaveButtonClick}
                        primaryButtonText='Save'
                        onSecondaryButtonClick={handleCancelButtonClick}
                        secondaryButtonText='Cancel'
                        disablePrimaryButton={hasPlanningPeriodError}
                        isProfileWithProposalsOrArchived={isProfileWithProposalsOrArchived}
                    />

                    <div className="marginbottom-md present-value-banner-container" data-testid="unsavedPRBanner">
                        {!reserveTargetIsSet &&
                            <AlertBanner
                                fullWidth={false}
                                icon="security"
                                showAlert={true}
                                showCloseBtn={false}
                                type="info"
                            >

                        <span className="banner-text">
                            Present value is based on default Portfolio Reserve until Portfolio Reserve is set
                        </span>
                            </AlertBanner>
                        }
                    </div>
                    <div className='lifestyle-spending__form'>
                        <article>
                            <LifestyleSpendingPeriods
                                lifestyleSpendingPeriods={lifestyleSpendingGoal.userInputs.lifestyleSpendingPeriods}
                                onUpdate={handleUpdateLifestyleSpendingPeriods}
                                onBlurAnnualSpend={handleAnnualSpendBlur}
                                onBlurLengthOfSpend={handleLengthOfSpendBlur}
                                planningPeriod={investorGroup.planningPeriod}
                                hasError={hasPlanningPeriodError}
                                enableMultipleSpendingPeriods={enableMultipleSpendingPeriods}
                                onAddSpendingPeriod={addNewSpendingPeriod}
                                totalLifestyleSpendingYears={totalLifestyleSpendingYears}
                                isProfileWithProposalsOrArchived={isProfileWithProposalsOrArchived}
                            />
                            {showGoalFundingSection &&
                                <LifestyleGoalFunding
                                    isOn={lifestyleSpendingGoal.userInputs.isFundedByNonInvestableAssets}
                                    onToggle={() => toggleFundedByNonInvestables(!lifestyleSpendingGoal.userInputs.isFundedByNonInvestableAssets)}
                                    investorGroup={investorGroup}
                                    sufficientYears={lifestyleSpendingGoal.calculatedFields.sufficientYears}
                                    isProfileWithProposalsOrArchived={isProfileWithProposalsOrArchived}
                                />
                            }
                        </article>
                        <div style={{flexDirection: "column"}}>
                            <LifestyleSpendingLivePreview
                                lifestyleSpendingGoal={lifestyleSpendingGoal}
                                investorGroup={investorGroup}
                                discountRateSelection={discountRateSelection}
                                profile={proposal}
                                isSufficient={isSufficient}
                                hasError={hasPlanningPeriodError}
                                reserveTargetIsSet={reserveTargetIsSet}
                            />
                            {proposal.portfolioReserveTargetLength != null &&
                                proposal.portfolioReserveTargetLength != undefined &&
                                <LifestyleSpendingReserveInformation
                                    lifestyleSpendingGoal={lifestyleSpendingGoal}
                                    profile={proposal}
                                    portfolioReserve={portfolioReserveResponse}
                                    hasError={hasPlanningPeriodError}
                                    rateType={goalModel.discountRateSelection}
                                />
                            }
                        </div>
                    </div>
                </div>
            </ScrollableContainer>
        </div>
    );
};

export default LifestyleSpendingForm;