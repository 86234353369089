import React, { ChangeEvent, useEffect, useState } from 'react';
import useProfileAndProposals from "../../hooks/useProfileAndProposals";
import {Link, useParams} from "react-router-dom";
import {RouteWithId} from "../../routes/types";
import FundingHeader from "./FundingHeader";
import Highcharts from "highcharts";
import {CreateFundingGraphOptions} from "./FundingGraphUtils";
import HighchartsReact from "highcharts-react-official";
import LoadingIndicator from 'src/pages/LoadingIndicator';
import { FundingDisplayOptions, GoalFundingResponse } from './models/Funding';
import FundingSummary from './FundingSummary';
import {DataDisplayView} from "../../ClientManagement/AssetAllocation/ReviseAssetAllocation";
import FundingAgeInput from './FundingAgeInput';
import { InvestorGroupMemberType, InvestorGroupType, emptyInvestorGroup } from 'src/ClientManagement/models/InvestorGroupType';
import { clientManagementApiClient } from 'src/ClientManagement/ClientManagementApiClient';
import ScrollableContainer from "../../components/ScrollableContainer/ScrollableContainer";
import FundingService from "./FundingService";
import {AlertBanner, Button, EmptyStateContainer, Icon} from "../../components";
import {emptyProfileResponse, ProfileResponse} from "../../ClientManagement/models/ProfileResponse";

const Funding = () => {
    const {id} = useParams<RouteWithId>();
    const { approvedProfile } = useProfileAndProposals(id);
    const { displayName } = approvedProfile;
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [fundingInfo, setFundingInfo] = useState<GoalFundingResponse>();
    const [fundingAge, setFundingAge] = useState<number>(0);
    const [investorGroup, setInvestorGroup] = useState<InvestorGroupType>(emptyInvestorGroup);
    const [profile, setProfile] = useState<ProfileResponse>(emptyProfileResponse);
    const [displayView, setDisplayView] = useState<DataDisplayView>(DataDisplayView.PERCENTAGE_VIEW);
    const [isFundingLoadedFirstTime, setIsFundingLoadedFirstTime] = useState<boolean>(true);
    const [displayOptions, setDisplayOptions] = useState<FundingDisplayOptions>(FundingDisplayOptions.ONLY_PORTFOLIO);
    const [alertVisible, setAlertVisible] = useState<boolean>(true);

    const startAge = investorGroup.planningPeriod.ageFrom;
    const personalPlanningHorizon = investorGroup.planningPeriod.numberOfYears + investorGroup.planningPeriod.ageFrom;
    const clientDisplayName = investorGroup.planningPeriod.memberType === InvestorGroupMemberType.PARTNER
        ? investorGroup.partnerMember?.name!
        : investorGroup.primaryMember?.name!;
    const areGoalsAvailable: boolean = fundingInfo ? !!(fundingInfo.discretionary.goals.length ||
        fundingInfo.family.goalSummary.length ||
        fundingInfo.lifestyle.goals.length ||
        fundingInfo.philanthropic.goals.length)
        : false
    const isExcessShortfall: boolean = fundingInfo ? (fundingInfo.investableExcess.presentValueAtYear[0] <= 0 ||
        fundingInfo.investableExcess.presentValueAtYear.length == 0) : false;

    function loadGoalsFunding() {
        setIsLoading(true);
        Promise.all([
            FundingService.getGoalFundingWithFamilyDetails(id),
            clientManagementApiClient.getInvestorGroup(id),
            clientManagementApiClient.getProfile(id)
        ]).then(([
            fundingResponse, investorGroupResponse, profileResponse ]) => {
            setFundingInfo(fundingResponse);
            setInvestorGroup(investorGroupResponse);
            setProfile(profileResponse);
            setIsLoading(false);
        }).catch(error => console.error('Could not fetch funding details', error.message));
    }

    useEffect(() => {
        loadGoalsFunding();
    }, [id]);

    useEffect(() => {
        setFundingAge(startAge);
    }, [investorGroup]);

    if (isLoading || !fundingInfo) {
        return <LoadingIndicator/>
    }

    const handleUpdateDataDisplay = (e: ChangeEvent<HTMLInputElement>) => {
        const {value} = e.target;
        setDisplayView(value as DataDisplayView);
        setIsFundingLoadedFirstTime(true);
    }

    const EmptyGoalFunding = () => {
        return <EmptyStateContainer
            className="no-goal-funding-placeholder"
            data-testid="no-goal-funding-container"
            title="Begin by entering your goals on the goals summary page."
            hideBorder={true}
            description={
                <Link to={`/Profile/${id}/ClientProfile/Goals/Summary`}>
                    <Button
                        icon="left"
                        iconName="add"
                        kind="primary"
                        size="medium"
                        rounded
                    >Add Goal</Button>
                </Link>}
        />
    }


    const graphOptions: Highcharts.Options = CreateFundingGraphOptions({
        displayName: displayName,
        fundingAge:fundingAge,
        startAge: startAge,
        maxAge:personalPlanningHorizon,
        fundingInfo: fundingInfo,
        handleClickPointer: setFundingAge,
        isEmpty: !areGoalsAvailable,
        isShortfall: isExcessShortfall
    });

    return (
        <ScrollableContainer id={'funding-page-scroll-container'} className="funding-page">
            <FundingHeader
                handleChangeDisplayView={handleUpdateDataDisplay}
                isFundingLoadedFirstTime={isFundingLoadedFirstTime}
                displayView={displayView}
                setDisplayOptions={setDisplayOptions}
            />
            <AlertBanner
                showAlert={profile.isActivePortfolioReserve && alertVisible}
                icon="info"
                type="warning"
                fullWidth={false}
                className="alert-pr-activated"
                showCloseBtn={true}
                onClose={() => setAlertVisible(false)}
                message={"The Portfolio Reserve is currently turned on. Lifestyle goal calculations do not reflect this drawdown to Risk Control."}
            />
            <div className={`finding-charts-section`}>
                <HighchartsReact highcharts={Highcharts} options={graphOptions}/>
                { isExcessShortfall && areGoalsAvailable ? <div className="excess-shortfall-label">
                    <Icon name={`info`} color={`#BF5E00`} className={`info-icon`}/>
                    <span className={`subhead`}>Investable shortfall exists. Asset allocation may not be achievable.</span>
                </div> : <div></div> }
            </div>
            {areGoalsAvailable ? <>
                <FundingAgeInput
                    fundingAtAge={fundingAge}
                    setFundingAtAge={setFundingAge}
                    clientDisplayName={clientDisplayName}
                    minAge={startAge}
                    maxAge={personalPlanningHorizon}
                />
                <FundingSummary
                    ageIndex={fundingAge - startAge}
                    startAge={startAge}
                    fundingInfo={fundingInfo}
                    displayOptions={displayOptions}
                    displayView={displayView}
                    isShortfall = {isExcessShortfall}
                />
            </> : <EmptyGoalFunding />}
        </ScrollableContainer>
    )
}

export default Funding