import React from "react";
import {StateOfResidencyStrategy} from "../WealthPOTypes";
import {formatCurrency} from "../../utils/format";

type StrategyAccordionHeaderProps = {
    stateOfResidencyStrategy: StateOfResidencyStrategy
};

export function StrategyAccordionItemHeader({stateOfResidencyStrategy}: StrategyAccordionHeaderProps) {
    return (
        <div>
            {stateOfResidencyStrategy &&
                <div className={"strategy-accordion-item"}>
                    <span className={"span-1"}>{stateOfResidencyStrategy.description}</span>
                    <span className={"span-2"}>State of Residency</span>
                    <span className={"span-3 textalign-right"}>––</span>
                    <span
                        className={"span-4 textalign-right"}>{formatCurrency(stateOfResidencyStrategy.estimatedImpact.impactToEstEstateTax)}
                    </span>
                </div>
            }
        </div>
    );
}