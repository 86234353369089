import React, {FC} from "react";
import StackedBarChartItem, {StackedBarChartItemType} from "./StackedBarChartItem";
import {formatCurrency} from "../../utils/format";
import {clamp} from "../../utils/Clamp";
import classNames from "classnames";
import {enforceMinimumDisplaySize, sumBarChartTotals} from "./StackedBarChartUtils";

export type StackedBarChartProps = {
    id?: string,
    className?: string,
    data?: StackedBarChartItemType[],
    chartWidth?: number,
    noDataText?: string,
    noDataColor?: string,
    onSelectItem?: (key?: string) => void,
    showLabels?: boolean,
    enforceMinimumSize?: boolean,
}

const StackedBarChart: FC<StackedBarChartProps> = ({
                                                       id= 'stacked-bar-chart',
                                                       className,
                                                       data = [],
                                                       chartWidth = 140,
                                                       noDataText = 'No data has been captured yet',
                                                       noDataColor = '#959595',
                                                       enforceMinimumSize = false,
                                                       onSelectItem,
                                                       showLabels = true,
                                                   }) => {

    const isNotEmpty = data.length > 0;
    const items = getItemsFromData(data, enforceMinimumSize, noDataColor, noDataText)
    const overallTotal = sumBarChartTotals(items);
    const positiveValues = items.filter(item => item.total > 0);
    const negativeValues = items.filter(item => item.total < 0);


    return <div id={id}
        className={classNames(
            'stacked-bar-chart',
            {'stacked-bar-chart--selectable': Boolean(onSelectItem && isNotEmpty)},
            className
        )}
    >
        <div
            className="stacked-bar-chart__items"
            style={{
                width: `${chartWidth}px`,
                minWidth: `${chartWidth}px`
            }}
        >
            {positiveValues.map((item) => (
                <StackedBarChartItem
                    item={item}
                    key={item.key || item.label || item.color}
                    onSelectItem={isNotEmpty ? onSelectItem : undefined}
                    overallTotal={overallTotal}
                />
            ))}
            {negativeValues.length > 0 && (
                <>
                    <div className="stacked-bar-chart__divider" style={{width: chartWidth}}/>
                    {negativeValues.map((item) => (
                        <StackedBarChartItem
                            item={item}
                            key={item.key || item.label || item.color}
                            onSelectItem={isNotEmpty ? onSelectItem : undefined}
                            overallTotal={overallTotal}
                        />
                    ))}
                </>
            )}
        </div>
        {showLabels && (
            <div className="stacked-bar-chart__labels" style={{left: `${chartWidth}px`}}>
                {positiveValues.concat(negativeValues).map((item) => {
                    const totalForLabel = (item.combinedLabelTotal !== undefined && item.combinedLabelTotal >= 0)
                        ? item.combinedLabelTotal
                        : item.total

                    const height = `${clamp(Math.abs(totalForLabel) / overallTotal, 0, 1) * 100}%`;
                    const minHeight = '14px';

                    return (
                        <div
                            className={`
                                stacked-bar-chart__label-group
                                ${item.hideLabel && 'stacked-bar-chart__label-group--hidden'}
                                ${item.inactive && 'stacked-bar-chart__label-group--inactive'}
                            `}
                            key={item.label || item.color}
                            style={{
                                height,
                                minHeight
                            }}
                        >
                            {item.label && <b className="stacked-bar-chart__label">{item.label}</b>}
                            {item.showTotal &&
                                <span className="stacked-bar-chart__total">{formatCurrency(item.total)}</span>}
                        </div>
                    );
                })}
            </div>
        )}
    </div>;

}

function getItemsFromData(data: StackedBarChartItemType[], enforceMinimumSize: boolean, noDataColor: string, noDataText: string):StackedBarChartItemType[] {
    const emptyData = [
        {
            className: 'no-data',
            label: noDataText,
            color: noDataColor,
            total: 1,
        },
    ];
    if(data.length === 0){
        return emptyData
    } else if(enforceMinimumSize){
        return enforceMinimumDisplaySize(data)
    } else{
        return data
    }
}

export default StackedBarChart;
