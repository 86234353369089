import {OwnershipDetailsFormData, OwnershipReadModel, OwnershipWriteModel} from "./Ownership";
import {Asset, EstateValue} from "./Asset";
import {TaxDetailsType, TaxStatusOptions} from "./TaxDetails";
import {LiabilityDetails} from "./PersonalLiability";
import {Optional} from "../../utils/customTypes";
import {AccountBase, LegalAgreementBase} from "./Assets";
import {HoldingSummary} from "./Holding";

/* data types */
export type PartiallyOwnedInvestmentAccountWriteModel = Asset & TaxDetailsType & {
    legalAgreementName:string,
    legalAgreementNumber:string,
    legalAgreementTypeCode:string,
    taxStatus: string,
    institution: string,
    entityType: string | null,
    partiallyOwnedAccounts: PartiallyOwnedAccount[],
    permitBeneficiary: boolean
};

export type PartiallyOwnedAccount = AccountBase;

export type PartiallyOwnedLegalAgreementReadModel = PartiallyOwnedLegalAgreement &{
    holdings: HoldingSummary
}

export type PartiallyOwnedLegalAgreement = LegalAgreementBase & {
    legalAgreementName: string,
    partiallyOwnedAccounts: PartiallyOwnedAccount[]
}

export type PartiallyOwnedInvestmentAccountReadModel = Asset & TaxDetailsType & {
    legalAgreementName: string,
    legalAgreementNumber:string,
    typeCode:string,
    taxStatus:TaxStatusOptions,
    institution:string,
    entityType:string | null,
    holdings: null,
    partiallyOwnedAccounts: PartiallyOwnedAccount[],
    marketEstateValue: EstateValue,
    asOfDate: string
}

export type UpdatePartiallyOwnedInvestmentAccountRequest =
    Omit<PartiallyOwnedInvestmentAccountWriteModel, 'profileId' | 'ordinal' | keyof OwnershipReadModel>
    & OwnershipWriteModel;

export type CreatePartiallyOwnedInvestmentAccountRequest = Omit<UpdatePartiallyOwnedInvestmentAccountRequest, 'id'>

export const initialPartiallyOwnedAccount : PartiallyOwnedInvestmentAccountWriteModel = {
    id: '',
    profileId: '',
    ordinal: -1,
    legalAgreementName: '',
    legalAgreementNumber: '',
    legalAgreementTypeCode: '',
    taxStatus: 'Taxable',
    institution: '',
    entityType: '',
    isEntityCapitalGains: true,
    isLiabilityPaidByPortfolio: false,
    ownershipCategory: 'Sole',
    partiallyOwnedAccounts: [],
    memberOwnerships: [],
    legalEntityOwnerships: [],
    permitBeneficiary: false
}

type PartiallyOwnedLegalAgreementWithMaybeId = Optional<PartiallyOwnedLegalAgreement, 'id'>

type NonFormDataFields = 'profileId' | 'personalLiabilities' | 'inEstateValue' | 'marketValue' | keyof OwnershipReadModel;

export type PartiallyOwnedLegalAgreementFormData =
    Omit<PartiallyOwnedLegalAgreementWithMaybeId, NonFormDataFields>
    & OwnershipDetailsFormData;