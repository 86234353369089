import React from "react";
import SectionHeader from "../SectionHeader";
import {StandaloneAccount} from "../../../models/StandaloneAccount";
import {HeldAwayAccount, HeldAwayAccountSummary, LegalAgreement} from "../../../models/InvestmentProgram";
import Accounts from "./Accounts";
import PersonalAssets from "./PersonalAssets";
import {PersonalAsset} from "../../../models/PersonalAsset";
import {Accordion} from "../../../../components";
import {LifeInsurance} from "../../../models/LifeInsurance";
import {InvestorGroupType, MemberGroup} from "../../../../ClientManagement/models/InvestorGroupType";
import LifeInsurances from "./LifeInsurances";
import {AccordionItemIds} from "../constants";
import FutureInflows from "./FutureInflows";
import {GeneralInflows, SocialSecurities} from "../../../models/Assets";
import {DropResult} from "react-beautiful-dnd";
import {NO_OP} from "../../../../constants/common";
import {
    PartiallyOwnedInvestmentAccountReadModel,
    PartiallyOwnedLegalAgreement
} from "../../../models/PartiallyOwnedInvestmentAccount";

type AssetsProps = {
    profileId: string
    accounts: StandaloneAccount[],
    legalAgreements: LegalAgreement[],
    heldAwayAccounts: HeldAwayAccountSummary[],
    partiallyOwnedInvestmentAccounts: PartiallyOwnedLegalAgreement[],
    personalAssets: PersonalAsset[],
    generalInflows: GeneralInflows,
    socialSecurities: SocialSecurities,
    lifeInsurances: LifeInsurance[],
    investorGroup: InvestorGroupType,
    memberGroup: MemberGroup
    captureAccountsOrder?: ({source, destination}: DropResult) => void
    capturePersonalAssetsOrder?: ({source, destination}: DropResult) => void
    captureGeneralInflowsOrder?: ({source, destination}: DropResult) => void
    captureSocialSecuritiesOrder?: ({source, destination}: DropResult) => void
    captureLifeInsurancesOrder?: ({source, destination}: DropResult) => void
}

const columns = ["Asset Type", "Investable Value", "Present Value"];
const expandedItems = [
    AccordionItemIds.accounts,
    AccordionItemIds.personalAssets,
    AccordionItemIds.futureInflows,
    AccordionItemIds.lifeInsurances
];

const Assets = ({
                    profileId,
                    accounts,
                    legalAgreements,
                    heldAwayAccounts,
                    partiallyOwnedInvestmentAccounts,
                    personalAssets,
                    generalInflows,
                    socialSecurities,
                    lifeInsurances,
                    investorGroup,
                    memberGroup,
                    captureAccountsOrder = NO_OP,
                    capturePersonalAssetsOrder = NO_OP,
                    captureGeneralInflowsOrder = NO_OP,
                    captureSocialSecuritiesOrder = NO_OP,
                    captureLifeInsurancesOrder = NO_OP
                }: AssetsProps) => {

    const hasAssets = accounts.length > 0
        || legalAgreements.length > 0
        || heldAwayAccounts.length > 0
        || partiallyOwnedInvestmentAccounts.length > 0
        || personalAssets.length > 0
        || lifeInsurances.length > 0
        || generalInflows.data.length > 0
        || socialSecurities.data.length > 0;

    if (!hasAssets) {
        return <></>
    }

    return (
        <div className="asset-list">
            <div className="accordion-tables">
                <SectionHeader columns={columns}/>
                <Accordion
                    expanded={expandedItems}
                >
                    <Accounts
                        profileId={profileId}
                        accounts={accounts}
                        legalAgreements={legalAgreements}
                        heldAwayAccounts={heldAwayAccounts}
                        partiallyOwnedInvestmentAccounts={partiallyOwnedInvestmentAccounts}
                        captureAccountsOrder={captureAccountsOrder}/>
                    <PersonalAssets
                        profileId={profileId}
                        personalAssets={personalAssets}
                        capturePersonalAssetsOrder={capturePersonalAssetsOrder}/>
                    <FutureInflows
                        generalInflows={generalInflows}
                        socialSecurities={socialSecurities}
                        investorGroup={investorGroup}
                        captureGeneralInflowsOrder={captureGeneralInflowsOrder}
                        captureSocialSecuritiesOrder={captureSocialSecuritiesOrder}/>
                    <LifeInsurances
                        lifeInsurances={lifeInsurances}
                        memberGroup={memberGroup}
                        captureLifeInsurancesOrder={captureLifeInsurancesOrder}
                    />
                </Accordion>
            </div>
        </div>
    )
}
export default Assets;