import React from "react";
import {StackedBarChart} from "../../components";
import {
    COLOR_AMT_TO_BENEFICIARIES_DECREASE,
    COLOR_AMT_TO_BENEFICIARIES_INCREASE,
    COLOR_ENABLED_WPO_STRATEGY,
    COLOR_EST_ESTATE_TAX_DECREASE,
    COLOR_EST_ESTATE_TAX_INCREASE,
    COLOR_TAXES
} from "../../constants/colors";
import {StackedBarChartItemType} from "../../components/StackedBarChart/StackedBarChartItem";
import {truncateCurrency} from "../../utils/format";

export interface AmountToBeneficiariesWithoutStrategiesChartItems {
    data: StackedBarChartItemType[];
}

export interface EstEstateTaxWithoutStrategiesChartItems {
    data: StackedBarChartItemType[];
}

export interface AmountToBeneficiariesWithStrategiesChartItems {
    data: StackedBarChartItemType[];
}

export interface EstEstateTaxWithStrategiesChartItems {
    data: StackedBarChartItemType[];
}

export type FutureEstateValueBarChartsProps = {
    amountToBeneficiariesWithoutStrategies: number,
    estEstateTaxWithoutStrategies: number,
    amountToBeneficiariesWithStrategies: number,
    estEstateTaxWithStrategies: number,
}

const FutureEstateValueBarCharts: React.FC<FutureEstateValueBarChartsProps> = ({
                                                                                   amountToBeneficiariesWithoutStrategies,
                                                                                   estEstateTaxWithoutStrategies,
                                                                                   amountToBeneficiariesWithStrategies,
                                                                                   estEstateTaxWithStrategies
                                                                               }: FutureEstateValueBarChartsProps) => {
    const {
        amountToBeneficiariesWithoutStrategiesChartItems,
        estEstateTaxWithoutStrategiesData,
        amountToBeneficiariesWithStrategiesChartItems,
        estEstateTaxWithStrategiesData
    } = getChartItemsData(amountToBeneficiariesWithoutStrategies, estEstateTaxWithoutStrategies, amountToBeneficiariesWithStrategies, estEstateTaxWithStrategies);

    return (
        <div className={"future-estate-value-bar-charts"}>
            <div className={"strategies"}>
                <StackedBarChart
                    className={"amount-to-beneficiaries-bar-chart"}
                    showLabels={true}
                    data={amountToBeneficiariesWithoutStrategiesChartItems.data}
                />
                <StackedBarChart
                    className={"est-estate-tax-bar-chart"}
                    showLabels={true}
                    data={estEstateTaxWithoutStrategiesData.data}
                />
                <StackedBarChart
                    className={"amount-to-beneficiaries-bar-chart"}
                    showLabels={true}
                    data={amountToBeneficiariesWithStrategiesChartItems.data}
                />
                <StackedBarChart
                    className={"est-estate-tax-bar-chart"}
                    showLabels={true}
                    data={estEstateTaxWithStrategiesData.data}
                />
            </div>
        </div>
    )
};

export const getChartItemsData = (amountToBeneficiariesWithoutStrategies: number,
                                  estEstateTaxWithoutStrategies: number,
                                  amountToBeneficiariesWithStrategies: number,
                                  estEstateTaxWithStrategies: number):
    {
        amountToBeneficiariesWithoutStrategiesChartItems: AmountToBeneficiariesWithoutStrategiesChartItems,
        estEstateTaxWithoutStrategiesData: AmountToBeneficiariesWithStrategiesChartItems,
        amountToBeneficiariesWithStrategiesChartItems: EstEstateTaxWithoutStrategiesChartItems,
        estEstateTaxWithStrategiesData: EstEstateTaxWithStrategiesChartItems
    } => {

    let maxValue = Math.max(amountToBeneficiariesWithoutStrategies, estEstateTaxWithoutStrategies, amountToBeneficiariesWithStrategies, estEstateTaxWithStrategies);
    maxValue = (maxValue === 0) ? 152 : maxValue; // required to show minimum height for bar charts when all amounts are 0

    const isTaxDecreasing = estEstateTaxWithStrategies < estEstateTaxWithoutStrategies;
    const isBeneficiaryAmountDecreasing = amountToBeneficiariesWithStrategies < amountToBeneficiariesWithoutStrategies;

    const {
        withoutStrategiesData: amtToBeneficiariesWithoutStrategiesData,
        withStrategiesData: amtToBeneficiariesWithStrategiesData
    } = isBeneficiaryAmountDecreasing
        ? getChartItemsForDecreasedFutureEstateValue(
            amountToBeneficiariesWithoutStrategies,
            amountToBeneficiariesWithStrategies,
            COLOR_ENABLED_WPO_STRATEGY,
            COLOR_AMT_TO_BENEFICIARIES_DECREASE,
            maxValue)
        : getChartItemsForIncreasedFutureEstateValue(
            amountToBeneficiariesWithoutStrategies,
            amountToBeneficiariesWithStrategies,
            COLOR_ENABLED_WPO_STRATEGY,
            COLOR_AMT_TO_BENEFICIARIES_INCREASE,
            maxValue)

    const {
        withoutStrategiesData: estEstateTaxWithoutStrategiesData,
        withStrategiesData: estEstateTaxWithStrategiesData
    } = isTaxDecreasing
        ? getChartItemsForDecreasedFutureEstateValue(
            estEstateTaxWithoutStrategies,
            estEstateTaxWithStrategies,
            COLOR_TAXES,
            COLOR_EST_ESTATE_TAX_DECREASE,
            maxValue)
        : getChartItemsForIncreasedFutureEstateValue(
            estEstateTaxWithoutStrategies,
            estEstateTaxWithStrategies,
            COLOR_TAXES,
            COLOR_EST_ESTATE_TAX_INCREASE,
            maxValue)

    return {
        amountToBeneficiariesWithoutStrategiesChartItems: {
            data: amtToBeneficiariesWithoutStrategiesData
        } as AmountToBeneficiariesWithoutStrategiesChartItems,

        estEstateTaxWithoutStrategiesData: {
            data: estEstateTaxWithoutStrategiesData
        } as EstEstateTaxWithoutStrategiesChartItems,

        amountToBeneficiariesWithStrategiesChartItems: {
            data: amtToBeneficiariesWithStrategiesData
        } as AmountToBeneficiariesWithStrategiesChartItems,

        estEstateTaxWithStrategiesData: {
            data: estEstateTaxWithStrategiesData
        } as EstEstateTaxWithStrategiesChartItems
    }
}

const getChartItemsForDecreasedFutureEstateValue = (amountWithoutStrategies: number,
                                                    amountWithStrategies: number,
                                                    chartItemColor: string,
                                                    highlightColor: string,
                                                    maxValue: number) => {
    const strategyAmountDifference = Math.abs(amountWithoutStrategies - amountWithStrategies);
    const totalForWhiteSpace = maxValue - amountWithoutStrategies;

    const withoutStrategiesData = [{
        total: totalForWhiteSpace,
        color: "none",
    }, {
        total: strategyAmountDifference,
        color: chartItemColor,
        combinedLabelTotal: 0,
    }, {
        label: amountWithoutStrategies > 0 ? truncateCurrency(amountWithoutStrategies) : "$0",
        total: getTotalAmountWithMinHeight(amountWithStrategies, maxValue),
        color: chartItemColor,
        combinedLabelTotal: amountWithoutStrategies,
    }] as StackedBarChartItemType[];

    const withStrategiesData = [{
        total: totalForWhiteSpace,
        color: "none",
    }, {
        total: strategyAmountDifference,
        color: highlightColor,
        highlight: strategyAmountDifference,
        combinedLabelTotal: undefined,
    }, {
        label: amountWithStrategies > 0 ? truncateCurrency(amountWithStrategies) : "$0",
        total: getTotalAmountWithMinHeight(amountWithStrategies, maxValue),
        color: chartItemColor,
        combinedLabelTotal: undefined,
    }] as StackedBarChartItemType[];

    return {withoutStrategiesData, withStrategiesData};
};

const getChartItemsForIncreasedFutureEstateValue = (amountWithoutStrategies: number,
                                                    amountWithStrategies: number,
                                                    chartItemColor: string,
                                                    highlightColor: string,
                                                    maxValue: number) => {
    const strategyAmountDifference = Math.abs(amountWithoutStrategies - amountWithStrategies);
    const totalForWhiteSpace = maxValue - amountWithStrategies;

    const withoutStrategiesData = [{
        total: totalForWhiteSpace,
        color: "none",
    }, {
        total: strategyAmountDifference,
        color: "none",
        combinedLabelTotal: undefined,
    }, {
        label: amountWithoutStrategies > 0 ? truncateCurrency(amountWithoutStrategies) : "$0",
        total: getTotalAmountWithMinHeight(amountWithoutStrategies, maxValue),
        color: chartItemColor,
        combinedLabelTotal: undefined,
    }] as StackedBarChartItemType[];

    const withStrategiesData = [{
        total: totalForWhiteSpace,
        color: "none",
    }, {
        total: strategyAmountDifference,
        color: highlightColor,
        highlight: strategyAmountDifference,
        combinedLabelTotal: 0,
    }, {
        label: amountWithStrategies > 0 ? truncateCurrency(amountWithStrategies) : "$0",
        total: getTotalAmountWithMinHeight(amountWithoutStrategies, maxValue),
        color: chartItemColor,
        combinedLabelTotal: amountWithStrategies,
    }] as StackedBarChartItemType[];

    return {withoutStrategiesData, withStrategiesData};
}

// If the amount is less than or equal to 2.65% of maxValue, this function will return the amount to show minimum height on bar chart
const getTotalAmountWithMinHeight = (amount: number, maxValue: number) => {
    return (amount <= (maxValue * 0.0265)) ? (maxValue * 0.0265) : amount;
};

export default FutureEstateValueBarCharts;
