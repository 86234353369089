import {HoldingAssetSubclassDetails} from "../models/Holding";
import React from "react";
import {formatNumber} from "../../utils/format";
import {Checkbox} from "xps-react";

type Props = {
    assetSubclassDetail: HoldingAssetSubclassDetails,
    index: number,
    handleLockHolding: (index: number, checked: boolean) => void,
    handleConcentrateHolding: (index: number, checked: boolean) => void,
    disableCheckboxes?: boolean;
};

export function IPAccountsHoldingSummarySubclassDetailsRow(
    {assetSubclassDetail, index, handleLockHolding, handleConcentrateHolding, disableCheckboxes=false} : Props) {
    const noValue = <span className="no-value">––</span>;
    const subclassDetailTaxCost = assetSubclassDetail.taxCost !== null ? `${formatNumber(assetSubclassDetail.taxCost)}` : noValue;
    const subclassDetailMarketValue = assetSubclassDetail.marketValue !== null ? `${formatNumber(assetSubclassDetail.marketValue)}` : noValue;
    const subclassDetailInvestableValue = assetSubclassDetail.investableValue !== null ? `${formatNumber(assetSubclassDetail.investableValue)}` : noValue;
    const presentableAccountNumber = `*${assetSubclassDetail.accountNumber?.substring(assetSubclassDetail.accountNumber.length - 4)}`;

    return (
        <div role="row"
             aria-label={`${assetSubclassDetail.assetSubclassDetailsName} - Tax Cost ${subclassDetailTaxCost} - Market Value ${subclassDetailMarketValue} - investable value ${subclassDetailInvestableValue}`}
             className="holding-grid grid-display-contents holding-subclass-detail-row"
             key={`asset-subclass-details-${assetSubclassDetail.id}-${assetSubclassDetail.productName}`}>
            <span role="cell"
                  aria-label={`${assetSubclassDetail.assetSubclassDetailsName}`}
                  className="holding-subclass-detail-name">{assetSubclassDetail.assetSubclassDetailsName}</span>
            <span role="cell"
                  className="product-name"
                  aria-label={`Product - ${assetSubclassDetail.productName}`}>{assetSubclassDetail.productName}</span>
            <span role="cell"
                  className="textalign-right"
                  aria-label={`Account - ${presentableAccountNumber}`}>{presentableAccountNumber}</span>
            <span role="cell"
                  aria-label={`Tax Cost - ${subclassDetailTaxCost}`}
                  className="textalign-right">{subclassDetailTaxCost}</span>
            <span role="cell"
                  aria-label={`Market Value - ${subclassDetailMarketValue}`}
                  className="textalign-right">{subclassDetailMarketValue}</span>
            <span role="cell"
                  aria-label={`Investable Value - ${subclassDetailInvestableValue}`}
                  className="textalign-right">{subclassDetailInvestableValue}</span>
            <Checkbox
                aria-label="locked"
                name="locked"
                defaultChecked={assetSubclassDetail.locked}
                value={assetSubclassDetail.locked}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    handleLockHolding(index, event.target.checked)
                }}
                disabled={disableCheckboxes}
            />
            <Checkbox
                aria-label="concentrated"
                name="concentrated"
                defaultChecked={assetSubclassDetail.concentrated}
                value={assetSubclassDetail.concentrated}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    handleConcentrateHolding(index, event.target.checked)
                }}
                disabled={disableCheckboxes}
            />
        </div>
    );
}
