import React, {ChangeEvent, useEffect, useMemo, useRef, useState} from "react";
import DataEntryHeader from "../components/DataEntry/DataEntryHeader";
import ScrollableContainer from "../components/ScrollableContainer/ScrollableContainer";
import {
    EmptyStateContainer,
    Icon,
    Input,
    RedAsterisk,
    RequiredFieldsSubheader,
    StateOfResidencyInput,
    UnderlinedHeader
} from "../components";
import DataEntrySummary, {DataEntrySummaryItem} from "../components/DataEntry/DataEntrySummary";
import {wealthPOApiClient} from "./WealthPOApiClient";
import {useHistory, useParams} from "react-router-dom";
import {RouteWithIdAndStrategyId} from "../routes/types";
import {EstimatedImpact, FailedAPICall, StateInputDto, StateOfResidencyStrategy} from "./WealthPOTypes";
import {useAppDispatch, useAppSelector} from "../store/hooks";
import {
    selectStateOfResidencyEstimatedImpact,
    selectStateOfResidencyStrategy,
    setStateOfResidencyEstimatedImpact
} from "./WealthPlanOptimizerSlice";
import {formatCurrency} from "../utils/format";
import AlertBanner from "../components/AlertBanner/AlertBanner";
import CustomModal from "../components/Modal/Custom/CustomModal";
import {
    getStateAbbreviationFromSelectedStateValue,
    getStateNameForStateOfResidencyId,
    getStateNamesForStateOfResidencyDropdown,
    navigateToWPOSummaryPage
} from "./WealthPOUtils";
import GenericErrorModal, {
    genericEmptyErrorModalData,
    GenericErrorModalData
} from "../components/Modal/Error/GenericErrorModal";

type ValidationErrors = {
    description: string | undefined,
    selectedState: string | undefined
};

interface AddEditStateOfResidencyProps {
    refreshStrategiesSummary: () => void;
}

const AddEditStateOfResidency: React.FC<AddEditStateOfResidencyProps> = ({
                                                                             refreshStrategiesSummary
                                                                         }: AddEditStateOfResidencyProps) => {

    const {id, strategyId} = useParams<RouteWithIdAndStrategyId>();
    const dispatch = useAppDispatch();
    const history = useHistory();

    const estimatedImpact = useAppSelector<EstimatedImpact>(selectStateOfResidencyEstimatedImpact);
    const stateOfResidencyStrategy = useAppSelector<StateOfResidencyStrategy>(selectStateOfResidencyStrategy);

    const [stateOfResidencyDropdownInputInitialValue, setStateOfResidencyDropdownInputInitialValue] = useState<string | undefined>(undefined);
    const [listOfStates, setListOfStates] = useState([] as StateInputDto[]);
    const [description, setDescription] = useState(stateOfResidencyStrategy.description);
    const [selectedState, setSelectedState] = useState<string | undefined>(undefined);
    const [validationErrors, setValidationErrors] = useState<ValidationErrors>({
        description: undefined,
        selectedState: undefined
    })
    const [showAlertBanner, setShowAlertBanner] = useState(false);
    const [showDiscardModal, setShowDiscardModal] = useState(false);
    const [genericError, setGenericError] = React.useState<GenericErrorModalData>(genericEmptyErrorModalData);
    const [showTempErrorMessage, setShowTempErrorMessage] = useState(false);
    const [failedApiCall, setFailedApiCall] = useState<FailedAPICall>();

    const estimatedImpactAbortControllerRef = useRef<AbortController | undefined>(undefined)

    const setPrepopulatedDropdownValue = (stateInputDTOs: StateInputDto[], selectedStateOfResidency: string) => {
        const dropdownDefaultSelectedState = getStateNameForStateOfResidencyId(
            stateInputDTOs,
            selectedStateOfResidency);

        if (dropdownDefaultSelectedState) {
            setStateOfResidencyDropdownInputInitialValue(dropdownDefaultSelectedState);
            setSelectedState(dropdownDefaultSelectedState);
        }
    }

    const fetchReferenceData = () => {
        wealthPOApiClient.getStateEstateTransferRatesReferenceData(id)
            .then((stateEstateTransferRates) => {
                setListOfStates(stateEstateTransferRates.stateInputsDTOs);

                setPrepopulatedDropdownValue(
                    stateEstateTransferRates.stateInputsDTOs,
                    stateOfResidencyStrategy.selectedStateOfResidency
                    )
            })
            .catch(err => {
                openErrorModal(err, FailedAPICall.REFERENCE_DATA)
            });
    }

    const fetchEstimatedImpact = () => {
        if (selectedState) {
            const selectedStateName = selectedState.split(" (")[0];
            const selectedStateDTO = listOfStates.find((stateInputDto) => stateInputDto.name === selectedStateName);
            const selectedStateOfResidency = selectedStateDTO?.id.split("-")[1];

            if (selectedStateOfResidency) {
                estimatedImpactAbortControllerRef.current = new AbortController();
                const {signal} = estimatedImpactAbortControllerRef.current;

                wealthPOApiClient.getEstimatedImpactForSOR(id, selectedStateOfResidency, signal)
                    .then((estimatedImpactResponse) => {
                        if (typeof estimatedImpactResponse !== "boolean") {
                            dispatch(setStateOfResidencyEstimatedImpact({
                                impactToEstEstateTax: estimatedImpactResponse.impactToEstEstateTax,
                                amountToBeneficiaries: undefined
                            }));
                        }

                        setPrepopulatedDropdownValue(
                            listOfStates,
                            selectedStateOfResidency
                        )
                    })
                    .catch((err) => {
                        openErrorModal(err, FailedAPICall.ESTIMATED_IMPACT);
                    });
            }
        }
    }

    const openErrorModal = (error: Error | any, failedAPI: FailedAPICall) => {
        setFailedApiCall(failedAPI);

        setGenericError({
            isOpen: true,
            header: "Unable to Load This Page",
            message: (
                <>
                    <p>Check your VPN connection and retry.</p>
                    <p>If the problem persists, contact <a href="mailto:GPIITSupport@ntrs.com">GPS Technical
                        Support</a> at GPIITSupport@ntrs.com and provide the following details:</p>
                </>
            ),
            profileId: id,
            time: new Date(),
            errorDetail: `Failed to load ${failedAPI} (${error.status})`,
            operationId: error.headers.get('trace-id')
        });
    };

    const closeErrorModal = () => {
        setGenericError({...genericError, isOpen: false});
    };

    const handleRetryClickInCommunicationErrorModal = () => {
        closeErrorModal();

        if (failedApiCall === FailedAPICall.REFERENCE_DATA) {
            fetchReferenceData();
        } else if (failedApiCall === FailedAPICall.ESTIMATED_IMPACT) {
            estimatedImpactAbortControllerRef.current = undefined;
            fetchEstimatedImpact();
        }
    }

    const handleCloseClickInCommunicationErrorModal = () => {
        closeErrorModal();
        setShowTempErrorMessage(true);
    }

    const validate = (): boolean => {
        setShowAlertBanner(false);
        let isValid = true;

        let newValidationErrors: ValidationErrors = {
            description: undefined,
            selectedState: undefined
        };

        if (description === undefined || description === "") {
            newValidationErrors.description = "Description is required."
            isValid = false;
        }

        if (!selectedState) {
            newValidationErrors.selectedState = "State of Residency is required."
            isValid = false;
        }

        setValidationErrors(newValidationErrors);
        return isValid;
    }

    const handleSaveClick = () => {
        if (validate()) {
            const selectedStateOfResidency = getStateAbbreviationFromSelectedStateValue(listOfStates, selectedState!);

            let requestBody = {
                description,
                selectedStateOfResidency
            } as StateOfResidencyStrategy;

            if (strategyId) {
                requestBody = {
                    ...requestBody,
                    id: strategyId,
                };

                wealthPOApiClient.editStateOfResidencyStrategy(id, requestBody)
                    .then(() => {
                        refreshStrategiesSummary();
                        history.push(`/Profile/${id}/ClientProfile/WealthPlanOptimizer/Summary`)
                    });
            } else {
                wealthPOApiClient.saveStateOfResidencyStrategy(id, requestBody)
                    .then(() => {
                        refreshStrategiesSummary();
                        history.push(`/Profile/${id}/ClientProfile/WealthPlanOptimizer/Summary`)
                    });
            }
        } else {
            setShowAlertBanner(true);
        }
    };

    const handleCancelClick = () => {
        const isFormChanged = description !== stateOfResidencyStrategy.description
            || getStateAbbreviationFromSelectedStateValue(listOfStates, selectedState) !== stateOfResidencyStrategy.selectedStateOfResidency;

        if (isFormChanged) {
            setShowDiscardModal(true);
        } else {
            navigateToWPOSummaryPage(history, id);
        }
    }

    const estimatedImpactSummary: DataEntrySummaryItem[] = useMemo(() => {
        return [
            {
                label: "Amount to Beneficiaries",
                value: "",
                testId: "amountToBeneficiaries",
            } as DataEntrySummaryItem,
            {
                label: "Est. Estate Tax",
                value: (estimatedImpact.impactToEstEstateTax !== undefined) ? formatCurrency(estimatedImpact.impactToEstEstateTax) : undefined,
                testId: "impactToEstEstateTax",
            } as DataEntrySummaryItem,
        ]
    }, [estimatedImpact]);

    useEffect(() => {
        fetchReferenceData();
    }, [id]);

    useEffect(() => {
        estimatedImpactAbortControllerRef.current = undefined;

        fetchEstimatedImpact();

        return () => {
            if (estimatedImpactAbortControllerRef.current) {
                estimatedImpactAbortControllerRef.current.abort();
            }
        }
    }, [id, selectedState]);

    const memoizedStateOfResidencyInput = useMemo(() => {
        return <StateOfResidencyInput className="layout-data-entry-form__field"
                                      listOfStates={getStateNamesForStateOfResidencyDropdown(listOfStates)}
                                      onSelected={event => setSelectedState(event.itemText)}
                                      error={validationErrors.selectedState}
                                      required={true}
                                      value={stateOfResidencyDropdownInputInitialValue}
        />;
    }, [listOfStates, stateOfResidencyDropdownInputInitialValue, validationErrors.selectedState]);

    if (genericError.isOpen) {
        return (
            <GenericErrorModal
                errorModalData={genericError}
                onClickButton={handleRetryClickInCommunicationErrorModal}
                onRequestClose={handleCloseClickInCommunicationErrorModal}
                buttonText="Retry"
                buttonProps={
                    {
                        primary: true,
                        className: 'full-width center-align',
                        iconPosition: 'left',
                        iconName: 'refresh'
                    }
                }
                showAlertIcon={false}
            />
        );
    }

    if (showTempErrorMessage) {
        return (
            <EmptyStateContainer
                className="no-wpo-summary-placeholder"
                title="Unable to Load This Page"
                size="large"
                description="Try again later."
            />
        );
    }

    return (
        <ScrollableContainer id={"add-edit-residency-state-scroll-container"} className="wealth-plan-optimizer">
            <DataEntryHeader title={`${strategyId === undefined ? "Add" : "Edit"} State of Residency Strategy`}
                             primaryButtonText={"SAVE"}
                             secondaryButtonText={"CANCEL"}
                             onPrimaryButtonClick={handleSaveClick}
                             onSecondaryButtonClick={handleCancelClick}
            />

            <div className="marginbottom-md __alert-banner-container">
                <AlertBanner showAlert={showAlertBanner}>
                    Required fields missing. All required fields must be entered to save a strategy.
                </AlertBanner>
            </div>

            <div className="wealthpo-strategy__form">
                <article>
                    <UnderlinedHeader
                        className={"wealthpo-strategy__underlined-header"}
                        primaryText="Strategy Details"
                        rightAlignedContent={<RequiredFieldsSubheader/>}
                    />
                    <div className="layout-data-entry-form__field">
                        <label data-testid={"descriptionLabel"} className={"h5"}>Description<RedAsterisk/></label>
                        <Input
                            name="descriptionField"
                            aria-label="description"
                            aria-labelledby="descriptionFieldInput-label"
                            id="descriptionFieldInput"
                            removeMarginTop
                            size="medium"
                            type="text"
                            value={description}
                            error={validationErrors.description}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => setDescription(e.target.value)}
                        />
                    </div>
                    {memoizedStateOfResidencyInput}
                </article>
                <aside>
                    <div id="estimatedImpactContainer" data-testid="estimatedImpactContainer">
                        <DataEntrySummary
                            title="Estimated Impact"
                            items={estimatedImpactSummary}
                        >
                            <div id="estimatedInfoTooltip">
                                <Icon name="status_info_outline" type="info" className='paddingright-sm'
                                      data-testid="estimatedImpactInfoIcon"/>
                                <div style={{fontStyle: "italic"}}>These values are estimates.</div>
                            </div>
                        </DataEntrySummary>
                    </div>
                </aside>
            </div>
            <CustomModal isOpen={showDiscardModal}
                         title={"Discard changes to this strategy?"}
                         content={"Any data entered for this strategy will not be saved."}
                         onClickCancel={() => setShowDiscardModal(false)}
                         onClickConfirm={() => navigateToWPOSummaryPage(history, id)}
                         cancelText={"KEEP EDITING"}
                         confirmText={"DISCARD CHANGES"}
            />
        </ScrollableContainer>
    )
}

export default AddEditStateOfResidency;