import React from "react";
import {useParams} from "react-router-dom";
import {RouteWithId} from "../routes/types";
import StrategyWithTimeFrame from "./components/StrategyWithTimeFrame";
import {EstimatedImpact, StrategyTimeFrame} from "./WealthPOTypes";
import {wealthPOApiClient} from "./WealthPOApiClient";

const AddEditAnnualGifting = () => {
    const {id} = useParams<RouteWithId>();

    const getEstimatedImpact = (requestBody: StrategyTimeFrame, signal?: AbortSignal): Promise<EstimatedImpact> => {
        return wealthPOApiClient.getEstimatedImpactForAnnualGifting(id, requestBody, signal);
    }

    return (
        <StrategyWithTimeFrame profileId={id} header={"Add Annual Gifting Strategy"}
                               strategyDetailsDescription={"Annual Gifting"}
                               getEstimatedImpact={getEstimatedImpact}/>
    )
}

export default AddEditAnnualGifting;